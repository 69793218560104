import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  Box,
  LinearProgress,
  SnackbarCloseReason,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import NsnApi from '../../../api/nsn/nsnAPI';
import {
  ResponseDto,
  NsnDetailDto,
  IdentificationDto,
  ReferencePartDto,
  ItemStandardizationDto,
  SolicitationDto,
  RoleWithRoleResourcesDto,
} from '../../../api/types/types';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import './details.scss';
import {
  NsnDetailHeader,
  NsnDetailIdDataGrid,
  NsnDetailRefPnDataGrid,
  NsnDetailStandardizationDataGrid,
  NsnDetailMoeDataGrid,
  NsnDetailManagementDataGrid,
  NsnDetailPhraseDataGrid,
  NsnDetailFreightDataGrid,
  NsnDetailSolicitationsDataGrid,
  NsnDetailCharacteristicsDataGrid,
  NsnDetailDibbsProjections,
  // @ts-ignore: Implicit any module
} from '../../../components/nsn';
import { ValidationSnackbar } from '../../../components/form/index';
import html2canvas from 'html2canvas';
import DownloadIcon from '@mui/icons-material/Download';
import { parse, format, addMonths } from 'date-fns';
import { AppNames, AppResourceNames, PermissionsType } from '../../../api/types/custom-types';
import { doesHavePermission } from '../../../utils/permissions-helpers';
import { useSelector } from 'react-redux';
import CannotViewPage from '../../../components/no-permission-content/CannotViewPage';

declare module 'jspdf-autotable' {
  export default function autoTable(doc: any, options: any): void;
}

interface ExtendedItemStandardizationDto extends ItemStandardizationDto {
  CAGECD?: string; // Añadir CAGECD como opcional
}
export interface ExtendedSolicitationDto extends SolicitationDto {
  SBSETASIDEIND?: string; // Añadir la propiedad faltante
  SBSETASIDEPCT?: string; // Añadir la propiedad faltante
}

// Extend the jsPDF type to include lastAutoTable
interface jsPDFWithAutoTable extends jsPDF {
  lastAutoTable: { finalY: number };
}

const initalData: NsnDetailDto | null = null;

export default function NsnDetailsPage() {
  const location = useLocation();
  const locState = location.state;
  var nsn = locState?.nsn as string;

  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has('nsn')) {
    nsn = searchParams.get('nsn') as string;
  }

  // Config states
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: initalData,
  });

  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
  });

  const roles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canViewPage = doesHavePermission(
    roles,
    AppNames.NsnQuery,
    AppResourceNames.Search,
    isSuperAdmin,
    PermissionsType.View
  );

  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState({ open: false, message: '' });
  };

  /**
   * Displays error message
   * @param message
   * @param error
   */
  const showError = (message: string, error?: any) => {
    setPageState((oldState) => ({
      ...oldState,
      isLoading: false,
    }));

    if (error) {
      console.error(message, error);
    }
    setSnackBarState({
      open: true,
      message: message,
    });
  };

  useEffect(() => {
    async function fetchData() {
      setPageState((oldState) => ({ ...oldState, isLoading: true }));

      try {
        // Get paginated data from the server.
        const response: ResponseDto<NsnDetailDto> = await NsnApi.getNsnDetails(nsn);

        if (response.isSuccess === true) {
          setPageState((oldState) => ({
            ...oldState,
            isLoading: false,
            data: response.result,
          }));
        } else {
          showError(response.message);
        }
      } catch (error) {
        showError('Error sending query!', error);
      }
    }

    fetchData();
  }, [nsn]);

  const generatePDF = () => {
    const pdf = new jsPDF('l', 'mm', 'a3');
    let yOffset = 10;

    pdf.setFontSize(16);
    pdf.text('NSN Detail Report', 10, yOffset);
    yOffset += 10;

    const addSectionToPDF = (title: string, headers: string[], data: any[][]) => {
      pdf.setFillColor(0, 102, 204);
      pdf.setTextColor(255, 255, 255);
      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'bold');
      pdf.rect(10, yOffset, pdf.internal.pageSize.getWidth() - 20, 10, 'F');
      pdf.text(title, 12, yOffset + 7);
      yOffset += 5;

      autoTable(pdf, {
        startY: yOffset + 10,
        startX: 12,
        head: [headers],
        body: data.length > 0 ? data : [Array(headers.length).fill('')],
        styles: { fontSize: 10, cellPadding: 2, lineWidth: 0.05, lineColor: [128, 128, 128] },
        headStyles: { fillColor: null, textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center' },
        theme: 'grid',
        tableLineWidth: 0.05,
        tableLineColor: [128, 128, 128],

        didDrawPage: (data: any) => {
          yOffset = data.cursor.y + 10;
        },
      });
    };

    if (pageState.data) {
      addSectionToPDF(
        'NSN Detail Header',
        ['Label', 'Content'],
        [
          ['NSN', nsn],
          ['Item Name', pageState.data.itemName || ''],
          ['NSN Status', pageState.data.niinStatus.statusDesc || ''],
          [
            'Replacement NSN',
            `${pageState.data.niinStatus.replFsc || ''}${pageState.data.niinStatus.replNiin || ''}`,
          ],
          ['Cancel Source', pageState.data.niinStatus.cancelSource || ''],
          ['NSN Status Effective Date', pageState.data.niinStatus.effDate || ''],
          ['FSC', pageState.data.niinStatus.fsc || ''],
          ['FSC Description', pageState.data.niinStatus.fscDesc || ''],
          ['Schedule B', pageState.data.niinStatus.scheduleb || ''],
          ['Schedule B Description', pageState.data.niinStatus.schedulebDesc || ''],
          ['ENAC', pageState.data.niinStatus.enac || ''],
          ['ENAC Description', pageState.data.niinStatus.enacDesc || ''],
          ['Item Name Detail Description', nsn],
          [
            'Health Score',
            `${pageState.data.niinStatus.healthDescription || 'Unknown'} (${pageState.data.niinStatus.healthScore || ''})`,
          ],
          ['Health Info', pageState.data.niinStatus.healthReasons.join(', ') || ''],
        ]
      );

      addSectionToPDF(
        'Identification',
        [
          'FIIG',
          'INC',
          'CRIT CD',
          'II',
          'RPD MRC',
          'DMIL',
          'DMIL INT CD',
          'NIIN ASGMT',
          'PMIC',
          'ESD EMI',
          'HMIC',
          'HCC',
        ],
        pageState.data.ivmList?.map((item) => [
          item.FIIG,
          item.INC,
          item.CRIT_CD,
          item.II,
          item.RPD_MRC,
          item.DMIL,
          item.DMIL_INT_CD,
          item.NIIN_ASGMT,
          item.PMIC,
          item.ESD_EMI,
          item.HMIC,
          item.HCC,
        ]) || []
      );

      addSectionToPDF(
        'Reference/Part Number',
        [
          'REF PN',
          'CAGE CD',
          'STAT',
          'RNCC',
          'RNVC',
          'DAC',
          'RNAAC',
          'RNFC',
          'RNSC',
          'RNJC',
          'SADC',
          'HCC',
          'MSDS',
          'INSAM',
        ],
        pageState.data.refPartList?.map((item) => [
          item.REFPN,
          item.CAGECD,
          item.STAT,
          item.RNCC,
          item.RNVC,
          item.DAC,
          item.RNAAC,
          item.RNFC,
          item.RNSC,
          item.RNJC,
          item.SADC,
          item.HCC,
          item.MSDS,
          item.INSAM,
        ]) || []
      );

      addSectionToPDF(
        'Item Standardization Data',
        [
          'ISC',
          'CAGE CD',
          'DT DECISION',
          'NIIN STAT CD',
          'RPL NSN',
          'RPL ISC',
          'RPL ORIG',
          'RPL DT DECISION',
          'RPL NIIN STAT CD',
        ],
        (pageState.data?.itStdList as ExtendedItemStandardizationDto[])?.map((item) => [
          item.ISC,
          item.CAGECD,
          item.DT_DECISION,
          item.NIIN_STATCD,
          item.RPL_NSN,
          item.RPL_ISC,
          item.RPL_ORIG,
          item.RPL_DT_DECISION,
          item.RPL_NIIN_STATCD,
        ]) || []
      );

      addSectionToPDF(
        'MOE Rules',
        [
          'MOE RL',
          'AMC',
          'AMSC',
          'NIMSC',
          'EFF DATE',
          'IMC',
          'IMCA',
          'AAC',
          'SUPP COLLAB',
          'SUPP RCVR',
          'DSOR',
        ],
        pageState.data.moeRuleList?.map((item) => [
          item.MOE_RL,
          item.AMC,
          item.AMSC,
          item.NIMSC,
          item.EFF_DATE,
          item.IMC,
          item.IMCA,
          item.AAC,
          item.SUPP_COLLAB,
          item.SUPP_RCVR,
          item.DSOR,
        ]) || []
      );

      addSectionToPDF(
        'Management',
        ['EFF DT', 'MOE', 'AAC', 'SOS', 'UI', 'UI PRICE', 'QUP', 'CIIC', 'SLC', 'REP', 'USC'],
        pageState.data.managementList?.map((item) => [
          item.EFF_DT,
          item.MOE,
          item.AAC,
          item.SOS,
          item.UI,
          item.UI_PRICE,
          item.QUP,
          item.CIIC,
          item.SLC,
          item.REP,
          item.USC,
        ]) || []
      );

      addSectionToPDF(
        'Phrase',
        [
          'MOE',
          'USC',
          'PHRASE CD',
          'PHRASE STATEMENT',
          'ORDER OF USE',
          'JUMP TO CODE',
          'QPA',
          'UM',
          'TECH DOC NBR',
          'QNTV EXPRSN',
        ],
        pageState.data.phraseList?.map((item) => [
          item.MOE,
          item.USC,
          item.PHRASE_CD,
          item.PHRASE_STATEMENT,
          item.ORDER_OF_USE,
          item.JUMP_TO_CODE,
          item.QPA,
          item.UM,
          item.TECH_DOC_NBR,
          item.QNTV_EXPRSN,
        ]) || []
      );

      addSectionToPDF(
        'Freight',
        [
          'ORIG ACTY CD',
          'NMFC',
          'UFC CD MODF',
          'HMC',
          'CLAS RTNG CD',
          'LCL CD',
          'RAIL VARI CD',
          'WTR CMDTY CD',
          'TYPE CGO CD',
          'SP HDLG CD',
          'AIR DIM_CD',
          'AIR CMTY HDLG',
          'INTGTY CD',
          'FRT DESC',
        ],
        pageState.data.freightList?.map((item) => [
          item.ORIG_ACTY_CD,
          item.NMFC,
          item.UFC_CD_MODF,
          item.HMC,
          item.CLAS_RTNG_CD,
          item.LCL_CD,
          item.RAIL_VARI_CD,
          item.WTR_CMDTY_CD,
          item.TYPE_CGO_CD,
          item.SP_HDLG_CD,
          item.AIR_DIM_CD,
          item.AIR_CMTY_HDLG,
          item.INTGTY_CD,
          item.FRT_DESC,
        ]) || []
      );

      addSectionToPDF(
        'Solicitations',
        [
          'SOLICITATION',
          'FSC',
          'NIIN',
          'POST DATE',
          'RTN BY DATE',
          'QTY',
          'UNIT PR',
          'EST VALUE',
          'UI',
          'AMSC',
          'SBS ETA SIDE IND',
          'SBS ETA SIDE PCT',
          'SOL TYPE IND',
        ],
        (pageState.data.solicitationList as unknown as ExtendedSolicitationDto[])?.map((item) => [
          item.SOLICITATION,
          item.FSC,
          item.NIIN,
          item.POSTDATE,
          item.RETURNBYDATE,
          item.QTY,
          item.UNIT_PR,
          item.ESTVALUE,
          item.UI,
          item.AMSC,
          item.SBSETASIDEIND,
          item.SBSETASIDEPCT,
          item.SOLTYPEIND,
        ]) || []
      );

      addSectionToPDF(
        'Characteristics',
        ['MRC', 'REQUIREMENTS STATEMENT', 'CLEAR TEXT REPLY'],
        pageState.data.characteristicsList?.map((item) => [
          item.MRC,
          item.REQUIREMENTS_STATEMENT,
          item.CLEAR_TEXT_REPLY,
        ]) || []
      );
    }
    if (pageState.data?.svraForecast && pageState.data?.svraForecast.data_date) {
      const pdfWithAutoTable = pdf as jsPDFWithAutoTable;
      if (
        pdfWithAutoTable.lastAutoTable &&
        pdfWithAutoTable.lastAutoTable.finalY > pdf.internal.pageSize.height - 100
      ) {
        pdf.addPage();
        yOffset = 10;
      }

      let startDate: Date;
      try {
        startDate = parse(pageState.data?.svraForecast.data_date, 'M/d/yyyy', new Date());
        if (isNaN(startDate.getTime())) {
          throw new Error('Invalid date');
        }

        const headers = Array.from({ length: 25 }, (_, index) => {
          const date = addMonths(startDate, index);
          return format(date, 'MMM-yy');
        });
        headers.push('TOTAL');

        const projectionData = [
          [
            ...Array.from(
              { length: 25 },
              (_, index) =>
                pageState.data?.svraForecast[
                  `month${index + 1}` as keyof typeof pageState.data.svraForecast
                ]
            ),
            pageState.data?.svraForecast?.total,
          ],
        ];

        addSectionToPDF('DIBBS Projections', headers, projectionData);
      } catch (error) {
        console.error('Error processing date:', error);

        const headers = [
          ...Array.from({ length: 25 }, (_, index) => `Month ${index + 1}`),
          'TOTAL',
        ];

        const projectionData = [
          [
            ...Array.from(
              { length: 25 },
              (_, index) =>
                pageState.data?.svraForecast[
                  `month${index + 1}` as keyof typeof pageState.data.svraForecast
                ]
            ),
            pageState.data?.svraForecast?.total,
          ],
        ];
        addSectionToPDF('DIBBS Projections', headers, projectionData);
      }
    }

    pdf.save(`nsn-${nsn}-details.pdf`);
  };

  if (canViewPage === true) {
    return (
      <>
        <Box className="page-container">
          <h4 className="page-title">Detail</h4>
          {pageState.isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
              <LinearProgress sx={{ width: '100%' }} />
            </Box>
          ) : (
            <Box>
              <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  size="small"
                  sx={{
                    padding: '6px 12px',
                    borderRadius: '20px',
                    '&:hover': {
                      backgroundColor: '#306EB6',
                      color: 'white',
                    },
                  }}
                  onClick={generatePDF}
                >
                  Export
                </Button>
              </Box>
              <NsnDetailHeader headerData={pageState.data} nsn={nsn} />
              <NsnDetailIdDataGrid data={pageState.data?.ivmList} />
              <NsnDetailRefPnDataGrid data={pageState.data?.refPartList} />
              <NsnDetailStandardizationDataGrid data={pageState.data?.itStdList} />
              <NsnDetailMoeDataGrid data={pageState.data?.moeRuleList} />
              <NsnDetailManagementDataGrid data={pageState.data?.managementList} />
              <NsnDetailPhraseDataGrid data={pageState.data?.phraseList} />
              <NsnDetailFreightDataGrid data={pageState.data?.freightList} />
              <NsnDetailSolicitationsDataGrid data={pageState.data?.solicitationList} />
              <NsnDetailDibbsProjections data={pageState.data?.svraForecast} />
              <NsnDetailCharacteristicsDataGrid data={pageState.data?.characteristicsList} />
            </Box>
          )}
        </Box>
        <ValidationSnackbar
          open={snackBarState.open}
          message={snackBarState.message}
          onClose={handleSnackBarClose}
          severity="error"
        />
      </>
    );
  } else {
    return <CannotViewPage />;
  }
}
