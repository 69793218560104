import Actions from './actions';
import PartActions from './partActions';
import PartForecasts from './partForecast';
import PartHistory from './partHistory';
import Settings from './settings';
import SubstituteParts from './substituteParts';
import WatchedParts from './watchedPart';
import WatchedPartCaseLog from './watchedPartCaseLog';
import WatchedPartCaseLogFile from './watchedPartCaseLogFile';
import GidepUpload from './gidepUpload';

const DmsmsApi = {
  ...Settings,
  ...WatchedParts,
  ...SubstituteParts,
  ...PartHistory,
  ...PartForecasts,
  ...PartActions,
  ...Actions,
  ...WatchedPartCaseLog,
  ...WatchedPartCaseLogFile,
  ...GidepUpload,
};

export default DmsmsApi;
