// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto } from '../types/types';
import { RiskCaseLogEntryWatchedPartDto } from '../types/custom-types';
const { RISK_CASE_SERVICE_URL } = config;

const RiskCaseLogEntryWatchedParts = {
  /**
   * Retrieve all risk case log entries for a part.
   * @returns
   */
  getRiskCaseLogEntriesForWatchedPart: async function (
    watchedPartId: number
  ): Promise<ResponseDto<RiskCaseLogEntryWatchedPartDto[]>> {
    var url = `${RISK_CASE_SERVICE_URL}/RiskCaseLogEntryWatchedPart?watchedPartId=${watchedPartId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<RiskCaseLogEntryWatchedPartDto[]>;
  },

  /**
   * Adds a part to a risk case log entry.
   * @param forecast
   */
  addWatchedPartToRiskCaseLogEntry: async function (
    watchedPartId: number,
    riskCaseLogEntryId: number
  ): Promise<ResponseDto<any>> {
    var url = `${RISK_CASE_SERVICE_URL}/RiskCaseLogEntryWatchedPart?watchedPartId=${watchedPartId}&riskCaseLogEntryId=${riskCaseLogEntryId}`;
    const { data } = await httpClient.post(url);
    return data as ResponseDto<any>;
  },

  /**
   * Deletes a part from a risk case log entry.
   * @param part
   */
  deleteWatchedPartFromRiskCaseLogEntry: async function (id: number): Promise<ResponseDto<any>> {
    var url = `${RISK_CASE_SERVICE_URL}/RiskCaseLogEntryWatchedPart/${id}`;
    const { data } = await httpClient.delete(url);
    return data as ResponseDto<any>;
  },
};

export default RiskCaseLogEntryWatchedParts;
