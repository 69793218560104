import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PieChart } from '@mui/x-charts/PieChart';
import { generateMonochromaticScale } from '../../../components/Site-Admin/Colors/utils/generateMonochromaticScale';
import PopupRiskCases from '../../../components/risk-discovery/popup-risk-cases/PopupRiskCases';
import {
  DashboardDto,
  RiskCaseViewModelDto,
  RiskSourceData,
  RiskSourcesDataDto,
} from '../../../api/types/types';
import { useSelector } from 'react-redux';

interface CustomPieChartProps {
  data: RiskSourceData[];
  title: string;
  palette?: string[];
}

const CustomPieChart: React.FC<CustomPieChartProps> = ({ data, title, palette }) => {
  const [popupRiskCasesVisible, setPopupRiskCasesVisible] = useState<boolean>(false);
  const [riskCases, setRiskCases] = useState<RiskCaseViewModelDto[]>([]);

  const colorPerItem = data.map((item, index) => ({
    id: item.name,
    value: item.riskCases.length,
    label: `${item.name}: ${item.riskCases.length} Risk Cases`,
    color: palette
      ? palette[index % palette.length]
      : `hsl(${(index * 360) / data.length}, 70%, 50%)`,
  }));

  useEffect(() => {
    setPopupRiskCasesVisible(riskCases.length ? true : false);
  }, [riskCases]);

  const handleRiskCasesClose = () => {
    setRiskCases([]);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mx="auto"
    >
      <Typography variant="h6" align="center" gutterBottom>
        {title}
      </Typography>
      <PieChart
        series={[
          {
            data: colorPerItem,
            valueFormatter: (v) => '',
          },
        ]}
        height={500}
        width={500}
        sx={{ marginLeft: '100px' }}
        slotProps={{ legend: { hidden: true } }}
        onItemClick={(event, d) => {
          setRiskCases(data[d.dataIndex].riskCases);
        }}
      />

      <PopupRiskCases
        popupRiskCasesVisible={popupRiskCasesVisible}
        handleRiskCasesClose={handleRiskCasesClose}
        riskCases={riskCases}
      />
    </Box>
  );
};

const RiskSourcesPieCharts: React.FC = () => {
  const theme = useTheme();
  const [riskSources, setRiskSources] = useState<RiskSourcesDataDto>();
  const dashboardData: DashboardDto = useSelector((state: any) => state.dashboard.dashboardData);
  const loadingDashboardData: boolean = useSelector((state: any) => state.dashboard.loading);

  const colorPalette = generateMonochromaticScale(theme.palette.primary.main);

  useEffect(() => {
    if (dashboardData === null) return;
    setRiskSources(dashboardData.riskSourcesData);
  }, [dashboardData]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" pr={1}>
          Risk Sources Chart
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loadingDashboardData ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="650px">
            <CircularProgress />
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={4}
            p={2}
            width="100%"
            height="650px"
          >
            <CustomPieChart
              data={riskSources?.keywordModels || []}
              title="KWMs"
              palette={colorPalette}
            />
            <CustomPieChart
              data={riskSources?.suppliers || []}
              title="Suppliers"
              palette={colorPalette}
            />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default RiskSourcesPieCharts;
