// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import {
  DnBEntityResolutionDto,
  SelectBoxOptionDto,
  SupplierLogEntryFileDto,
  StringKeySelectBoxOptionDto,
  SupplierPreviewViewModelDto,
} from '../types/types';
import { parseDateFields } from '../configs/utils';

const { ALTRUITY_API_URL } = config;

const SupplierPreview = {
  getPreviewsList: async function (): Promise<SupplierPreviewViewModelDto[]> {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/SupplierPreview/GetPreviewsList`);
    return data.result;
  },
  deletePreview: async function (id: number): Promise<void> {
    const { data } = await httpClient.delete(`${ALTRUITY_API_URL}/SupplierPreview/Delete/${id}`);
    return data.result;
  },
  getPreviewById: async function (id: number): Promise<SupplierPreviewViewModelDto> {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/SupplierPreview/GetPreviewDetails/${id}`
    );
    return data.result;
  },
  updatePreview: async function (
    data: SupplierPreviewViewModelDto
  ): Promise<SupplierPreviewViewModelDto> {
    const { data: response } = await httpClient.post(
      `${ALTRUITY_API_URL}/SupplierPreview/Save`,
      data
    );
    return response.result;
  },
  getEntityResolution: async function (params: {
    name: string | null;
    streetAddressLine1: string | null;
    countryISOAlpha2Code: string | null;
    addressRegion?: string | null;
    addressLocality?: string | null;
    postalCode?: string | null;
  }): Promise<DnBEntityResolutionDto> {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/SupplierPreview/GetEntityResolution`,
      { params }
    );
    return data.result;
  },
  getIso2CountriesDropdown: async function (): Promise<StringKeySelectBoxOptionDto[]> {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/SupplierPreview/GetIso2CountriesDropdown`
    );
    return data.result;
  },
  getKetKeywordModelsDropdown: async function (): Promise<SelectBoxOptionDto[]> {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/SupplierPreview/GetKeywordModelsDropdown`
    );
    return data.result;
  },
  insertSupplierToScrm: async function (data: SupplierPreviewViewModelDto): Promise<void> {
    const { data: response } = await httpClient.post(
      `${ALTRUITY_API_URL}/SupplierPreview/InsertSupplierToScrm`,
      data
    );
    return response.result;
  },
  getSupplierFileById: async function (supplierFileId: number): Promise<SupplierLogEntryFileDto> {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/SupplierPreview/SupplierFile/${supplierFileId}`
    );
    return data.result;
  },
  savePreviewAndRunPerigonSearch: async function (
    data: SupplierPreviewViewModelDto
  ): Promise<void> {
    const { data: response } = await httpClient.post(
      `${ALTRUITY_API_URL}/SupplierPreview/SaveAndRunPerigonSearch`,
      data
    );
    return response.result;
  },
};

export default SupplierPreview;
