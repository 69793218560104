import React, { useEffect, useState } from 'react';
import { interval } from 'rxjs';
import fileDownload from 'js-file-download';
import { Box, IconButton, SnackbarCloseReason } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import LaunchIcon from '@mui/icons-material/Launch';
import { Delete } from '@mui/icons-material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid';
import ConfirmDeleteDialog from '../../../components/form/dialogs/ConfirmDeleteDialog';
import CircularProgressWithLabel from '../../../components/form/progress/CircularProgressWithLabel';
import { ValidationSnackbar } from '../../form/index';
import NsnBatchResultsModal from './NsnBatchResultsModal';
import { createColumn } from '../../../utils/data-grid';
import { NsnBatchDto } from '../../../api/types/types';
import { isNullOrWhitespace } from '../../../utils/validation-helpers';
import { b64toBlob } from '../../../utils/files';
import JobApi from '../../../api/job/JobApi';
import NsnApi from '../../../api/nsn/nsnAPI';

export default function NsnBatchResultsDataGrid() {
  const apiRef = useGridApiRef();
  const [data, setData] = useState<NsnBatchDto[]>([]);
  const [resultsModalState, setResultsModalState] = useState({
    open: false,
    batchId: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null);

  // Validation snackbar state
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
    severity: 'error',
  });

  /**
   * Triggers the snackbar
   * @param message
   * @param severity
   */
  function setSnackBar(message: string, severity: string = 'error') {
    setSnackBarState((oldState) => ({
      ...oldState,
      open: true,
      message: message,
      severity: severity,
    }));
  }

  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
      message: '',
    }));
  };

  /**
   * Refresh data every 3s
   */
  useEffect(() => {
    const subscription = interval(3000).subscribe(async () => {
      try {
        const response = await NsnApi.getBatches();
        setIsLoading(false);

        if (response.isSuccess) {
          setData(response.result);
        } else {
          setSnackBarState((prevState) => ({
            ...prevState,
            open: true,
            message: response.message,
          }));
        }
      } catch (e: any) {
        setIsLoading(false);
        setSnackBarState((prevState) => ({
          ...prevState,
          open: true,
          message: e.message,
        }));
        console.error(e);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [apiRef]);

  /**
   * Downloads a file to the user's computer
   * @param id BatchId
   * @param path path to the file on the server.
   */
  async function handleFileDownload(id: number, path: string) {
    const arr = path.split('\\');
    const fileName = arr[arr.length - 1];
    try {
      const response = await NsnApi.getBatchFile(id);

      if (response.isSuccess) {
        // const blob = new Blob([response.result], {
        // 	type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        // });
        const blob = b64toBlob(
          response.result,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        fileDownload(blob, fileName);
      } else {
        console.log(response.message);
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   *
   * @param id Open results modal.
   */
  function handleGetResultsClick(id: number) {
    setResultsModalState((prevState) => ({
      ...prevState,
      open: true,
      batchId: id,
    }));
  }

  /**
   * Close the Results Modal.
   */
  function handleModalClose() {
    setResultsModalState((prevState) => ({
      ...prevState,
      open: false,
    }));
  }

  /**
   *
   * @param id unique id of the row.
   * @returns
   */
  const handleDeleteClick = (id: GridRowId) => () => {
    setConfirmDeleteId(id as number);
    setOpenConfirmDialog(true);
  };

  async function deletePartAction(id: number) {
    try {
      const response = await JobApi.deleteNsnBatch(id as number);

      if (response.isSuccess) {
        setData(data.filter((row) => row.nsnBatchId !== id));
        setConfirmDeleteId(null);
        setOpenConfirmDialog(false);
        setSnackBar('Row deleted', 'success');
      } else {
        console.error(response.message);
        setSnackBar(response.message);
      }
    } catch (error) {
      console.error(error);
      setSnackBar('An error occurred.');
    }
  }

  /**
   *
   * @param id
   */
  const handleDelete = async (id: number) => {
    deletePartAction(id);
  };

  // Create column definitions
  const columns: GridColDef[] = [
    { ...createColumn('nsnBatchId', 'ID'), width: 80, flex: 0 },
    {
      ...createColumn('description', 'Description'),
      minWidth: 100,
      flex: 1,
    },
    //{ ...createColumn('statusPercent', '%'), width: 60, flex: 0 },
    {
      ...createColumn('statusPercent', '%'),
      renderCell: (params: any) => {
        if (isNullOrWhitespace(params.value)) {
          return <></>;
        } else {
          return (
            <CircularProgressWithLabel
              value={params.value as number}
              size={35}
              thickness={2}
              fontSize={10}
              fontWeight="bold"
            />
          );
        }
      },
      width: 60,
      flex: 0,
    },
    {
      ...createColumn('statusDescription', 'Status'),
      minWidth: 100,
      flex: 1,
    },
    {
      ...createColumn('dateStartedUtc', 'Started'),
      width: 200,
      flex: 0,
      valueFormatter: (value: string) => {
        if (value) {
          value += 'Z';
          return new Date(value).toLocaleString();
        } else {
          return undefined;
        }
      },
    },
    // {
    // 	...createColumn("dateUpdatedUtc", "Updated"),
    // 	width: 200,
    // 	flex: 0,
    // 	valueFormatter: (value: any) => new Date(value).toLocaleString(),
    // },
    {
      ...createColumn('dateCompletedUtc', 'Completed'),
      width: 200,
      flex: 0,
      valueFormatter: (value: string) => {
        if (value) {
          value += 'Z';
          return new Date(value).toLocaleString();
        } else {
          return undefined;
        }
      },
    },
    {
      ...createColumn('outputFilePath', 'File'),
      renderCell: (params: any) => {
        if (isNullOrWhitespace(params.formattedValue)) {
          return <></>;
        } else {
          return (
            <IconButton
              //variant="contained"
              color="primary"
              onClick={() => handleFileDownload(params.id, params.formattedValue)}
            >
              <DownloadIcon />
            </IconButton>
          );
        }
      },
      width: 80,
      flex: 0,
    },
    {
      ...createColumn('results', 'Results'),
      renderCell: (params: any) => {
        return (
          <IconButton
            color="primary"
            onClick={() => {
              handleGetResultsClick(params.id);
            }}
          >
            <LaunchIcon />
          </IconButton>
        );
      },
      width: 80,
      flex: 0,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        /*
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
		*/

        return [
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box className="nsn-detail-grid">
      <h5>Batch Results</h5>
      <DataGrid
        autoHeight
        getRowId={(rowData) => rowData.nsnBatchId}
        density="compact"
        rows={data}
        pageSizeOptions={[20, 50, 100]}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        columns={columns}
        apiRef={apiRef}
        loading={isLoading}
      />
      <NsnBatchResultsModal
        open={resultsModalState.open}
        batchId={resultsModalState.batchId}
        onClose={handleModalClose}
      />
      <ValidationSnackbar
        open={snackBarState.open}
        message={snackBarState.message}
        onClose={handleSnackBarClose}
        severity={snackBarState.severity}
      />{' '}
      <ConfirmDeleteDialog
        deleteId={confirmDeleteId}
        open={openConfirmDialog}
        onClose={setOpenConfirmDialog}
        onDelete={handleDelete}
      />
    </Box>
  );
}
