import React from 'react';
import {
  HomePage,
  SupplierPage,
  DashboardPage,
  DiscoveryPage,
  RiskModelsPage,
  RiskCase,
  CurrentRiskCases,
  RiskMeasuresAdmin,
  SupplierSettingsAdmin,
  NsnPage,
  NsnSearchResultsPage,
  NsnDetailPage,
  NsnBatchPage,
  NsnAdminSettingsPage,
  DmsmsAdminSettingsPage,
  Users,
  Roles,
  Colors,
  PartsPage,
  VendorAutoLoadAdmin,
  SuppliersDatagrid,
} from './pages';
import { withNavigationWatcher } from './contexts/navigation';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SuppliersPreview from './components/supplier-preview/SuppliersPreview';
import SupplierPreviewDetail from './components/supplier-preview/SupplierPreviewDetail';

const ProtectedRoute = ({ element: Component }) => {
  const { user } = useSelector((state) => state.auth);

  return user ? <Component /> : <Navigate to="/login" />;
};

const routes = [
  {
    path: '/',
    element: HomePage,
  },
  {
    path: '/scrm/suppliers',
    element: SuppliersDatagrid,
  },
  {
    path: '/scrm/suppliers/:id',
    element: SupplierPage,
  },
  {
    path: '/scrm/dashboard',
    element: DashboardPage,
  },
  {
    path: '/scrm/discovery/kwm/:id',
    element: DiscoveryPage,
  },
  {
    path: '/scrm/discovery/supplier/:id',
    element: DiscoveryPage,
  },
  {
    path: '/scrm/discovery/archived-supplier/:id',
    element: DiscoveryPage,
  },
  {
    path: '/scrm/discovery/archived-keyword/:id',
    element: DiscoveryPage,
  },
  {
    path: '/scrm/risk-models/:id',
    element: RiskModelsPage,
  },
  {
    path: '/scrm/risk-cases/current/',
    element: () => <CurrentRiskCases />,
  },
  {
    path: '/scrm/risk-cases/kwm/:id',
    element: () => <RiskCase />,
  },
  {
    path: '/scrm/risk-cases/supplier/:id',
    element: () => <RiskCase />,
  },
  {
    path: '/scrm/risk-cases/archived-kwm/:id',
    element: () => <RiskCase />,
  },
  {
    path: '/scrm/risk-cases/archived-supplier/:id',
    element: () => <RiskCase />,
  },
  {
    path: '/scrm/admin/risk-measures',
    element: () => <RiskMeasuresAdmin />,
  },
  {
    path: '/scrm/admin/supplier-settings',
    element: () => <SupplierSettingsAdmin />,
  },
  {
    path: '/scrm/admin/vendor-auto-load',
    element: () => <VendorAutoLoadAdmin />,
  },
  {
    path: '/scrm/suppliers/preview',
    element: () => <SuppliersPreview />,
  },
  {
    path: '/scrm/suppliers/new-preview',
    element: () => <SupplierPreviewDetail />,
  },
  {
    path: '/scrm/suppliers/preview/:id',
    element: () => <SupplierPreviewDetail />,
  },
  {
    path: '/dmsms/admin',
    element: DmsmsAdminSettingsPage,
  },
  {
    path: '/dmsms/dashboard',
    element: () => <h4>Dashboard</h4>,
  },
  {
    path: '/dmsms/parts',
    element: PartsPage,
  },
  {
    path: '/dmsms/log',
    element: () => <h4>Log</h4>,
  },
  {
    path: '/nsn/search',
    element: NsnPage,
  },
  {
    path: '/nsn/results',
    element: NsnSearchResultsPage,
  },
  {
    path: '/nsn/details',
    element: NsnDetailPage,
  },
  {
    path: '/nsn/batch',
    element: NsnBatchPage,
  },
  {
    path: '/nsn/admin',
    element: NsnAdminSettingsPage,
  },
  {
    path: '/site-admin/permissions/users',
    element: () => <Users />,
  },
  {
    path: '/site-admin/permissions/roles',
    element: () => <Roles />,
  },
  {
    path: '/site-admin/permissions/activities',
    element: () => <h4>Activities Page</h4>,
  },
  {
    path: '/site-admin/site-settings/colors',
    element: () => <Colors />,
  },
];

const protectedRoutes = routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(() => <ProtectedRoute element={route.element} />),
  };
});

export default protectedRoutes;
