// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto, NsnSearchSortCriteriaDto } from '../types/types';
const { NSN_SERVICE_URL } = config;

const SortCriteria = {
  /**
   * Retrieves all sort criteria
   * @returns
   */
  getAllSortCriteria: async function (): Promise<ResponseDto<NsnSearchSortCriteriaDto[]>> {
    var url = `${NSN_SERVICE_URL}/SortCriteria`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<NsnSearchSortCriteriaDto[]>;
  },
  /**
   * Retrieves sort criteria
   * @returns
   */
  getSortCriteria: async function (
    sortCriteriaId: number
  ): Promise<ResponseDto<NsnSearchSortCriteriaDto>> {
    var url = `${NSN_SERVICE_URL}/SortCriteria/${sortCriteriaId}`;
    const { data } = await httpClient.get(url);
    return data as ResponseDto<NsnSearchSortCriteriaDto>;
  },

  /**
   * Creates a sort criteria
   * @param sortCriteria
   */
  createSortCriteria: async function (
    sortCriteria: NsnSearchSortCriteriaDto
  ): Promise<ResponseDto<NsnSearchSortCriteriaDto>> {
    var url = `${NSN_SERVICE_URL}/SortCriteria`;
    const { data } = await httpClient.post(url, sortCriteria);
    return data as ResponseDto<NsnSearchSortCriteriaDto>;
  },

  /**
   * Updates a sort criteria
   * @param action
   */
  updateSortCriteria: async function (
    action: NsnSearchSortCriteriaDto
  ): Promise<ResponseDto<NsnSearchSortCriteriaDto>> {
    var url = `${NSN_SERVICE_URL}/SortCriteria`;
    const { data } = await httpClient.put(url, action);
    return data as ResponseDto<NsnSearchSortCriteriaDto>;
  },

  /**
   * Updates a sort criteria
   * @param action
   */
  updateSortCriteriaOrder: async function (
    oldIndex: number,
    newIndex: number
  ): Promise<ResponseDto<NsnSearchSortCriteriaDto>> {
    var url = `${NSN_SERVICE_URL}/SortCriteria/${oldIndex}/${newIndex}`;
    const { data } = await httpClient.patch(url);
    return data as ResponseDto<NsnSearchSortCriteriaDto>;
  },

  /**
   * Deletes a sort criteria
   * @param part
   */
  deleteSortCriteria: async function (
    sortCriteriaId: number
  ): Promise<ResponseDto<NsnSearchSortCriteriaDto[]>> {
    var url = `${NSN_SERVICE_URL}/SortCriteria/${sortCriteriaId}`;
    const { data } = await httpClient.delete(url);
    return data as ResponseDto<NsnSearchSortCriteriaDto[]>;
  },
};
export default SortCriteria;
