import React from 'react';
import UsersTable from '../../components/Site-Admin/Users/UsersTable';
import './Users.scss';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

const Users = () => {
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;

  if (isSuperAdmin === true) {
    return (
      <div className="site-admin-container">
        <Typography className="title">Users</Typography>
        <UsersTable />
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default Users;
