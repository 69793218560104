import { RiskMeasureDto } from '../../../../api/types/types';
import { DataPoint } from '../types/types';
import { RiskCaseWithSupplierDataDto } from '../../../../api/types/custom-types';

export default function getDataPoints(
  riskCases: RiskCaseWithSupplierDataDto[],
  riskMeasures: RiskMeasureDto[],
  xAxis: number,
  yAxis: number
): DataPoint[] {
  let dataPoints: DataPoint[] = [];

  riskCases.forEach((rc: RiskCaseWithSupplierDataDto) => {
    const xMeasure = rc.measures.find((rcm) => rcm.riskMeasureId === xAxis);
    const yMeasure = rc.measures.find((rcm) => rcm.riskMeasureId === yAxis);

    const xMeasureLevel = riskMeasures
      .find((rm) => rm.id === xMeasure?.riskMeasureId)
      ?.riskMeasureLevels.find((rml) => rml.levelId === xMeasure?.levelId);

    const yMeasureLevel = riskMeasures
      .find((rm) => rm.id === yMeasure?.riskMeasureId)
      ?.riskMeasureLevels.find((rml) => rml.levelId === yMeasure?.levelId);

    dataPoints.push({
      x: xMeasureLevel?.label || '',
      y: yMeasureLevel?.label || '',
      suppliers: rc.suppliers,
      riskCases: [rc.id],
    });
  });

  return dataPoints;
}
