// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import {
  DashboardKeyMetricsViewModelDto,
  DashboardDmsmsViewModelDto,
  RiskSourcesDataDto,
  HitsAgingViewModel,
  DnBAssessmentChartDto,
  DashboardDto,
} from '../types/types';
import { RiskCaseWithSupplierDataDto } from '../types/custom-types';

const { ALTRUITY_API_URL } = config;

const Dashboard = {
  getKeyMetrics: async function (): Promise<DashboardKeyMetricsViewModelDto> {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Dashboard/GetKeyMetrics`);
    return data.result;
  },

  getDmsmsData: async function (): Promise<DashboardDmsmsViewModelDto> {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Dashboard/GetDmsmsData`);
    return data.result;
  },

  getRiskCasesBySupplierWeight: async function (
    supplierWeightId: number
  ): Promise<RiskCaseWithSupplierDataDto[]> {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/Dashboard/GetRiskCasesBySupplierWeight${
        supplierWeightId !== 0 ? '?supplierWeightId=' + supplierWeightId.toString() : ''
      }`
    );
    return data.result;
  },

  getRiskSourcesData: async function (): Promise<RiskSourcesDataDto> {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Dashboard/GetRiskSourcesData`);
    return data.result;
  },

  GetHitsAgingData: async function (): Promise<HitsAgingViewModel> {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Dashboard/GetHitsAgingDetailedData`);
    return data;
  },

  getDnBAssessmentData: async function (supplierWeightId: number): Promise<DnBAssessmentChartDto> {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/Dashboard/GetDnBAssessmentData${
        supplierWeightId !== 0 ? '?supplierWeightId=' + supplierWeightId.toString() : ''
      }`
    );
    return data.result;
  },

  getDashboardData: async function (): Promise<DashboardDto> {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Dashboard`);
    return data.result;
  },
};

export default Dashboard;
