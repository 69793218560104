import React, { useEffect, useState } from 'react';
import Tabs from 'devextreme-react/tabs';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RiskCaseOverview from '../../components/risk-Cases-tab/overview/index';
import './index.scss';
import RiskCaseApi from '../../api/riskCase/riskCaseApi';
import {
  addRiskCases,
  setRiskCurrentCaseName,
  setSelectedMenuItem,
  updateRiskCaseMenu,
  updateRiskCaseName,
} from '../../slices/riskCasesSlice';
import CaseLog from '../../components/risk-discovery/risk-case-popup/sections/CaseLog';
import { AlertColor, Box, Button } from '@mui/material';
import CustomSnackbar from '../../components/custom-snackbar';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ArticlesTable from '../../components/risk-Cases-tab/article/RiskCaseArticle';
import { doesHavePermission } from '../../utils/permissions-helpers';
import { RoleWithRoleResourcesDto } from '../../api/types/types';
import { AppNames, PermissionsType } from '../../api/types/custom-types';
import { AppResourceNames } from '../../api/types/custom-types';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

interface RiskCase {
  generalInformation: {
    riskCaseId: number;
    dateAdded: string;
    dateUpdated: string;
    dateDeleted: null;
    isActive: boolean;
    isDeleted: boolean;
    riskCaseName: string;
    comments: string;
    description: string;
    owner: Owner | null;
    subscribers: any[];
    suppliers: any[];
    keywordModels: any[];
  };
  measures: {
    riskMeasures: Measure[];
  };

  caseLogs: {
    caseLogs: any[];
  };
  articles: {
    articles: any[];
  };
}

interface Measure {
  riskMeasureId: number;
  description: string;
  availableRiskMeasureLevels: RiskMeasureLevel[];
  riskMeasureName: string;
  isRequired?: boolean;
  selectedLevelId?: number;
}

interface RiskMeasureLevel {
  id: number;
  label: string;
  comments: string;
  selectedLevelId: number;
  isRequired: boolean;
}

interface Owner {
  key: string;
  name: string;
}

interface TabItemData {
  id: number;
  text: string;
}

interface TabClickEvent {
  itemData?: TabItemData;
}

var RISKCASE_TABS = [
  { id: 0, text: 'Overview' },
  // { id: 1, text: 'Parts / Assemblies' },
  { id: 2, text: 'Case Log' },
  { id: 3, text: 'Articles' },
];

const RiskCase: React.FC = () => {
  const [tabId, setTabId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [ownersDropdown, setOwnersDropdown] = useState<Owner[]>([]);
  const [formDataCaseLog, setFormDataCaseLog] = useState<any>();
  const [caseLogUpdateTrigger, setCaseLogUpdateTrigger] = useState<number>(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [riskCaseData, setRiskCaseData] = useState<RiskCase>({
    generalInformation: {
      riskCaseId: 0,
      riskCaseName: '',
      dateAdded: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
      dateDeleted: null,
      isActive: true,
      isDeleted: false,
      comments: '',
      description: '',
      owner: null,
      subscribers: [],
      suppliers: [],
      keywordModels: [],
    },
    measures: {
      riskMeasures: [],
    },
    caseLogs: {
      caseLogs: [],
    },
    articles: {
      articles: [],
    },
  });
  const { id } = useParams<{ id: string }>();
  const numercId = Number(id);

  const dispatch = useDispatch();
  const currentRiskCaseName = useSelector((state: any) => state.riskCases.currentRiskCaseName);

  const roles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canView = doesHavePermission(
    roles,
    AppNames.SCRM,
    AppResourceNames.RiskCases,
    isSuperAdmin,
    PermissionsType.View
  );
  const canEditRiskCase = doesHavePermission(
    roles,
    AppNames.SCRM,
    AppResourceNames.RiskCases,
    isSuperAdmin,
    PermissionsType.AddEdit
  );
  const canArchiveRiskCase = doesHavePermission(
    roles,
    AppNames.SCRM,
    AppResourceNames.RiskCases,
    isSuperAdmin,
    PermissionsType.Archive
  );

  dispatch(setSelectedMenuItem(numercId));

  function filterTabsBasedOnRole() {
    // Role Resources
    var caseLogRoleResource = null;
    var isScrmAdmin = false;

    for (var i = 0; i < roles.length; i++) {
      const role = roles[i];

      for (var j = 0; j < role.roleResources.length; j++) {
        const roleResource = role.roleResources[j];

        // SCRM - Suppliers
        if (
          roleResource.appResource.app.appName == AppNames.SCRM &&
          roleResource.appResource.resourceName == AppResourceNames.RiskCaseLog
        ) {
          // If Admin skip menu filtering.
          if (role.isAdmin === true) {
            isScrmAdmin = true;
          } else {
            caseLogRoleResource = roleResource;
          }
        }
      }
    }

    if (isScrmAdmin === false) {
      // Suppliers menu item
      if (caseLogRoleResource && caseLogRoleResource.canView === false) {
        RISKCASE_TABS = RISKCASE_TABS.filter((c) => c.text !== 'Case Log');
      }
    }
  }

  if (isSuperAdmin === false) {
    filterTabsBasedOnRole();
  }

  const fetchRiskCase = async () => {
    try {
      const data = await RiskCaseApi.getRiskCaseById(numercId);

      if (currentRiskCaseName !== data.generalInformation.riskCaseName) {
        dispatch(setRiskCurrentCaseName(data.generalInformation.riskCaseName));
      }
      setFormDataCaseLog(data);
      setRiskCaseData(data);
      const ownersDropdown = await RiskCaseApi.getOwnersDropdown();
      setOwnersDropdown(ownersDropdown);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataRiskCases = async () => {
    try {
      const data = await RiskCaseApi.getAllRiskCase();
      dispatch(addRiskCases(data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsReady(true);
    setLoading(false);
    fetchRiskCase();
    fetchDataRiskCases();
  }, [id]);

  const triggerCaseLogUpdate = () => {
    setCaseLogUpdateTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    fetchRiskCase();
  }, [id, caseLogUpdateTrigger]);

  const handleSave = async () => {
    try {
      if (!riskCaseData.generalInformation.owner) {
        setSnackbarMessage('You must select an owner');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      if (!riskCaseData.generalInformation.riskCaseName.trim()) {
        setSnackbarMessage('The risk case name is required');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const requiredMeasures = riskCaseData.measures.riskMeasures.filter(
        (measure) => measure.isRequired
      );
      const incompleteMeasures = requiredMeasures.filter((measure) => !measure.selectedLevelId);

      if (incompleteMeasures.length > 0) {
        setSnackbarMessage(
          'You must complete the severity for the following measures: ' +
            incompleteMeasures.map((m) => m.riskMeasureName).join(', ')
        );
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
      const updatedRiskCase = await RiskCaseApi.updateRiskCase({
        ...riskCaseData,
        articles: {
          articles: riskCaseData?.articles.articles.map((article: any) => ({
            id: article.id,
            articleId: article.articleId,
            title: article.title,
            addDate: article.addDate,
            articleCategories: [],
            articleCompanies: [],
            articleEntities: [],
            articleKeywords: [],
            articleLinks: [],
            articleRiskCases: article.articleRiskCase,
            authorByLine: article.authorByLine,
            content: article.content,
            dateAdded: article.dateAdded,
            dateDeleted: article.dateDeleted,
            dateUpdated: article.dateUpdated,
            description: article.description,
            imageUrl: article.imageUrl,
            isActive: article.isActive,
            isDeleted: article.isDeleted,
            language: article.language,
            medium: article.medium,
            negativeSentiment: article.negativeSentiment,
            neutralSentiment: article.neutralSentiment,
            positiveSentiment: article.positiveSentiment,
            pubDate: article.pubDate,
            refreshDate: article.refreshDate,
            score: article.score,
            semanticSummary: article.semanticSummary,
            sourceDomain: article.sourceDomain,
            sourceLocation: article.sourceLocation,
            supplierArticles: [],
            url: article.url,
          })),
        },
      });

      if (updatedRiskCase && updatedRiskCase.riskCaseName) {
        if (currentRiskCaseName !== updatedRiskCase.riskCaseName) {
          dispatch(setRiskCurrentCaseName(updatedRiskCase.riskCaseName));

          dispatch(
            updateRiskCaseName({
              id: updatedRiskCase.id,
              newName: updatedRiskCase.riskCaseName,
            })
          );
        }
        if (updatedRiskCase) {
          dispatch(
            updateRiskCaseMenu({
              updatedRiskCase: riskCaseData.generalInformation,
              isArchived: updatedRiskCase.isDeleted,
            })
          );
        }

        setSnackbarMessage('Risk case updated successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error saving risk case:', error);
      setSnackbarMessage('Error updating risk case');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  const onTabClick = (e: TabClickEvent) => {
    if (e.itemData) {
      setTabId(e.itemData.id);
    }
  };

  const getTabs = () => {
    if (id) {
      return RISKCASE_TABS.map((tab) => (tab.id === 1 ? { ...tab, disabled: true } : tab));
    }
    return RISKCASE_TABS;
  };

  const handleArchiveConfirmation = () => {
    setOpenConfirmDialog(true);
  };

  const handleArchived = async () => {
    try {
      const archivedRiskCase = await RiskCaseApi.archiveRiskCase(numercId);
      setOpenConfirmDialog(false);
      setSnackbarMessage('Risk case archived successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      dispatch(
        updateRiskCaseMenu({
          updatedRiskCase: riskCaseData.generalInformation,
          isArchived: true,
        })
      );
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Error archiving risk case');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  const updateGeneralInformation = (
    newGeneralInformation: Partial<typeof riskCaseData.generalInformation>
  ) => {
    setRiskCaseData((prevData) => ({
      ...prevData,
      generalInformation: {
        ...prevData.generalInformation,
        ...newGeneralInformation,
      },
    }));
  };

  const updateMeasures = (newMeasures: typeof riskCaseData.measures.riskMeasures) => {
    setRiskCaseData((prevData) => ({
      ...prevData,
      measures: {
        ...prevData.measures,
        riskMeasures: newMeasures,
      },
    }));
  };

  const updateCaseLogs = (newCaseLogs: typeof riskCaseData.caseLogs.caseLogs) => {
    const casesLogsWithoutDate = newCaseLogs.map(({ date, ...rest }) => rest);
    setRiskCaseData((prevData) => ({
      ...prevData,
      caseLogs: {
        ...prevData.caseLogs,
        caseLogs: casesLogsWithoutDate,
      },
    }));
  };

  const displayTab = () => {
    switch (tabId) {
      case 0:
        return (
          <RiskCaseOverview
            generalInformation={riskCaseData.generalInformation}
            measures={riskCaseData.measures.riskMeasures}
            updateGeneralInformation={updateGeneralInformation}
            updateMeasures={updateMeasures}
          />
        );
      case 2:
        return (
          <CaseLog
            owners={ownersDropdown}
            formData={formDataCaseLog}
            updateCaseLogs={updateCaseLogs}
            triggerCaseLogUpdate={triggerCaseLogUpdate}
          />
        );
      case 3:
        return <ArticlesTable articles={riskCaseData.articles.articles} />;
      default:
        return null;
    }
  };
  if (canView === true) {
    return (
      <div className="risk-case-page-container" key={id}>
        <h4 className="risk-case-title">{currentRiskCaseName}</h4>
        <div className="tabs-demo">
          <div className="widget-container">
            {isReady && (
              <Tabs
                id="withText"
                width={'700px'}
                selectedIndex={getTabs().findIndex((tab) => tab.id === tabId)}
                // selectedIndex={tabId}
                rtlEnabled={false}
                dataSource={getTabs()}
                scrollByContent={false}
                showNavButtons={false}
                orientation={'horizontal'}
                stylingMode={'secondary'}
                iconPosition={'top'}
                onItemClick={onTabClick}
              />
            )}
          </div>
        </div>
        {displayTab()}
        <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
          {tabId !== 2 && tabId !== 3 && canEditRiskCase && (
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          )}
          {canArchiveRiskCase ? (
            <Button variant="contained" color="primary" onClick={handleArchiveConfirmation}>
              Archive
            </Button>
          ) : null}
        </Box>
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
        <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
            {'Are you sure you want to archive this risk case?'}
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleArchived} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default RiskCase;
