// @ts-ignore: Implicit any module
import SuppliersAPI from '../../../api/suppliers/suppliersAPI';

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Chip,
  CircularProgress,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SupplierCard from './SupplierCard';
import HeatRanking from './HeatRanking';
import { DashboardDto, RiskCaseViewModelDto, SupplierHealthDto } from '../../../api/types/types';
import PermissionsApi from '../../../api/permissions/permissionsAPI';
import { useDispatch, useSelector } from 'react-redux';
import { addOwners } from '../../../slices/permissionsSlice';
import PopupRiskCases from '../../risk-discovery/popup-risk-cases/PopupRiskCases';

const SupplierHealth: React.FC = () => {
  const [healthScores, setHealthScores] = useState<SupplierHealthDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [popupRiskCasesVisible, setPopupRiskCasesVisible] = useState<boolean>(false);
  const [supplierRiskCases, setSupplierRiskCases] = useState<RiskCaseViewModelDto[]>([]);
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSortOption, setSelectedSortOption] = useState<string>('Health: Low to High');
  const dashboardData: DashboardDto = useSelector((state: any) => state.dashboard.dashboardData);
  const loadingDashboardData: boolean = useSelector((state: any) => state.dashboard.loading);

  const dispatch = useDispatch();

  const fetchDataOwners = async () => {
    try {
      const data = await PermissionsApi.getAllOwners();
      dispatch(addOwners(data));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCardClick = (riskCases: RiskCaseViewModelDto[]) => {
    setSupplierRiskCases(riskCases);
  };

  useEffect(() => {
    setPopupRiskCasesVisible(supplierRiskCases.length ? true : false);
  }, [supplierRiskCases]);

  useEffect(() => {
    if (dashboardData === null) return;
    setHealthScores(dashboardData.healthScores);
  }, [dashboardData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        fetchDataOwners();
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleRiskCasesClose = () => {
    setSupplierRiskCases([]);
  };

  const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = (option?: string) => {
    setSortAnchorEl(null);
    if (option) {
      setSelectedSortOption(option);

      if (option === 'Health: Low to High') {
        setHealthScores([...healthScores].sort((a, b) => a.score - b.score));
      } else if (option === 'Health: High to Low') {
        setHealthScores([...healthScores].sort((a, b) => b.score - a.score));
      }
    }
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" pr={1}>
          Supplier Health
        </Typography>
        <Chip
          label={
            loading || loadingDashboardData ? (
              <CircularProgress color="inherit" size={10} />
            ) : (
              healthScores.length
            )
          }
          sx={{ fontWeight: 'bold' }}
        />
      </AccordionSummary>
      <AccordionDetails>
        {!loading && !loadingDashboardData && (
          <Box padding={2}>
            <Button
              variant="text"
              onClick={handleSortClick}
              endIcon={<ExpandMoreIcon />}
              color="inherit"
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                alignSelf: 'flex-end',
              }}
            >
              {selectedSortOption}
            </Button>
            <Menu
              anchorEl={sortAnchorEl}
              open={Boolean(sortAnchorEl)}
              onClose={() => handleSortClose()}
            >
              <MenuItem onClick={() => handleSortClose('Health: Low to High')}>
                Health: Low to High
              </MenuItem>
              <MenuItem onClick={() => handleSortClose('Health: High to Low')}>
                Health: High to Low
              </MenuItem>
            </Menu>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent={loading || loadingDashboardData ? 'center' : 'space-between'}
        >
          {loading || loadingDashboardData ? (
            <CircularProgress />
          ) : (
            <>
              <Box
                sx={{
                  overflowY: 'auto',
                  height: '510px',
                  width: '50%',
                }}
              >
                <Grid container spacing={3} paddingX={2}>
                  {healthScores.map((healthScore: SupplierHealthDto) => (
                    <SupplierCard
                      key={healthScore.id}
                      score={healthScore}
                      onSupplierClick={handleCardClick}
                    />
                  ))}
                </Grid>
              </Box>
              <Box sx={{ width: '48%', height: '510px', overflowY: 'auto' }}>
                <HeatRanking scores={healthScores} onSupplierClick={handleCardClick} />
              </Box>
            </>
          )}
        </Box>
        <PopupRiskCases
          popupRiskCasesVisible={popupRiskCasesVisible}
          handleRiskCasesClose={handleRiskCasesClose}
          riskCases={supplierRiskCases}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SupplierHealth;
