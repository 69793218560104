import React from 'react';
import { LineChart } from '@mui/x-charts';
import { Box, Typography, useTheme } from '@mui/material';

interface SparklineChartProps {
  xAxisData: string[];
  seriesData: number[];
  title: string;
}

const SparklineChart: React.FC<SparklineChartProps> = ({ xAxisData, seriesData, title }) => {
  const theme = useTheme();

  const numericXAxisData = xAxisData.map((date) => Date.parse(date)).sort((a, b) => a - b);

  return (
    <Box>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {title}
      </Typography>
      <LineChart
        width={300}
        height={150}
        leftAxis={null}
        bottomAxis={null}
        slotProps={{ legend: { hidden: true } }}
        xAxis={[
          {
            data: numericXAxisData,
            valueFormatter: (day: number) => {
              const referenceDate = new Date(day);
              return new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }).format(referenceDate);
            },
          },
        ]}
        series={[
          {
            data: seriesData,
            label: title,
            color: theme.palette.primary.main,
          },
        ]}
      ></LineChart>
    </Box>
  );
};

export default SparklineChart;
