import React, { useState } from 'react';
import RiskDiscoveryHits from '../../components/risk-discovery/risk-discovery-hits/RiskDiscoveryHits';
import RiskDiscoveryHitCollections from '../../components/risk-discovery/risk-discovery-hit-collections/RiskDiscoveryHitCollections';
import './index.scss';
import RiskCasePopup from '../../components/risk-discovery/risk-case-popup/RiskCasePopup';
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../slices/discoverySlice';
import { AppNames, AppResourceNames, PermissionsType } from '../../api/types/custom-types';
import { doesHavePermission } from '../../utils/permissions-helpers';
import { RoleWithRoleResourcesDto } from '../../api/types/types';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

const Discovery = () => {
  const [key, setKey] = useState(0);
  const snackbar = useSelector((state: any) => state.discovery.snackbar);
  const dispatch = useDispatch();

  const roles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canView = doesHavePermission(
    roles,
    AppNames.SCRM,
    AppResourceNames.ArticleHits,
    isSuperAdmin,
    PermissionsType.View
  );

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setSnackbar({ open: false }));
  };

  if (canView === true) {
    return (
      <div className="risk-discovery-container">
        <Snackbar
          open={snackbar?.open}
          onClose={closeSnackbar}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={snackbar?.severity === 'error' ? null : 5000}
        >
          <Alert
            onClose={closeSnackbar}
            severity={snackbar?.severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar?.message}
          </Alert>
        </Snackbar>

        <RiskDiscoveryHits keyValue={key} setKey={setKey} />
        <RiskDiscoveryHitCollections key={key} />
        <RiskCasePopup />
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default Discovery;
