import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import HitsSupplierChart from './HitsSupplierChart';
import HitsKeywordModelChart from './HitsKeywordModelChart';
import DashboardApi from '../../../../api/dashboard/dashboard';
import { DashboardDto, HitsAgingViewModel } from '../../../../api/types/types';
import './HitsAgings.scss';
import { useSelector } from 'react-redux';

const HitsAgings = () => {
  const [hitsAgingData, setHitsAgingData] = useState<HitsAgingViewModel | null>(null);
  const dashboardData: DashboardDto = useSelector((state: any) => state.dashboard.dashboardData);
  const loadingDashboardData: boolean = useSelector((state: any) => state.dashboard.loading);

  useEffect(() => {
    if (dashboardData === null) return;
    setHitsAgingData(dashboardData.hitsAgingDetailedData);
  }, [dashboardData]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" pr={1}>
          Hits Agings
        </Typography>
        <Tooltip
          title="These two charts reveal the age of Hits (aka, Articles) discovered by the Risk Models. The bars represent the average age of Hits for the respective Supplier / Keyword Model. The dots represent the age of the oldest outstanding Hit for that Supplier / Keyword Model."
          arrow
        >
          <IconButton size="small" style={{ color: '#005B8C' }}>
            <HelpIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        {loadingDashboardData ? (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
          <div className="dashboard-container">
            <div className="chart-container">
              <Typography variant="h6" align="center">
                Suppliers
              </Typography>
              {hitsAgingData && <HitsSupplierChart hitsAgingData={hitsAgingData} />}
            </div>
            <div className="chart-container">
              <Typography variant="h6" align="center">
                Keyword Models
              </Typography>
              {hitsAgingData && <HitsKeywordModelChart hitsAgingData={hitsAgingData} />}
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default HitsAgings;
