import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { PerigonResultsVmDto, SelectBoxOptionDto } from '../../../api/types/types';
import SupplierPreviewArticlesTable from '../SupplierPreviewArticlesTable';

interface RiskModelsTabProps {
  enabled: boolean;
  availableRiskModels: SelectBoxOptionDto[];
  searchResults: PerigonResultsVmDto | undefined;
  onSaveAndRunPerigonSearch: (selectedItems: SelectBoxOptionDto[]) => void;
}

export const RiskModelsTab: React.FC<RiskModelsTabProps> = ({
  enabled,
  availableRiskModels,
  searchResults,
  onSaveAndRunPerigonSearch,
}) => {
  const [selectedModels, setSelectedModels] = useState<number[]>([]);

  const handleCheckboxChange = (id: number) => {
    setSelectedModels((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSaveAndRunClick = () => {
    const selectedItems = availableRiskModels.filter((model) => selectedModels.includes(model.id));
    setSelectedModels([]);
    onSaveAndRunPerigonSearch(selectedItems);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Risk Models
      </Typography>
      {availableRiskModels.map((model) => (
        <FormControlLabel
          key={model.id}
          control={
            <Checkbox
              checked={selectedModels.includes(model.id)}
              onChange={() => handleCheckboxChange(model.id)}
              disabled={!enabled}
            />
          }
          label={model.label}
        />
      ))}
      <Box mt={2}>
        <Button
          variant="contained"
          onClick={handleSaveAndRunClick}
          disabled={!enabled || selectedModels.length === 0}
        >
          SAVE PREVIEW AND RUN MODELS
        </Button>
      </Box>
      <Box mt={3}>
        <SupplierPreviewArticlesTable
          articles={searchResults?.articles ?? []}
        ></SupplierPreviewArticlesTable>
      </Box>
    </Box>
  );
};
