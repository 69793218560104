import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Typography,
  AlertColor,
} from '@mui/material';
import { DataGridPro, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Delete, Visibility } from '@mui/icons-material';
import { SupplierPreviewViewModelDto } from '../../api/types/types';
import SupplierPreviewApi from '../../api/supplierPreview/SupplierPreview';
import CustomSnackbar from '../custom-snackbar';
import { useNavigate } from 'react-router';

const SuppliersPreview = () => {
  const [rows, setRows] = useState<SupplierPreviewViewModelDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as AlertColor,
  });
  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  const navigate = useNavigate();

  const fetchSuppliersPreview = async () => {
    try {
      const response = await SupplierPreviewApi.getPreviewsList();
      setRows(response || []);
      setRows(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching suppliers preview:', error);
      setRows([]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchSuppliersPreview();
  }, []);

  const handleDeleteClick = (previewId: number) => {
    if (!previewId) return;
    setDeleteId(previewId);
    setOpenDialog(true);
  };

  const handleViewClick = (previewId: number) => {
    if (!previewId) {
      console.error('Invalid preview ID');
      return;
    }
    navigate(`/scrm/suppliers/preview/${previewId}`);
  };

  const handleAddSupplier = (id: number) => {
    navigate(`/scrm/suppliers/new-preview/`);
  };

  const handleConfirmDelete = async () => {
    if (!deleteId) return;

    try {
      setIsLoading(true);
      await SupplierPreviewApi.deletePreview(deleteId);

      setRows((prevRows) => prevRows.filter((row) => row.previewId !== deleteId));
      setSnackbar({
        open: true,
        message: 'Supplier preview deleted successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting supplier preview:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting supplier preview. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
      setOpenDialog(false);
      setDeleteId(null);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const columns = [
    {
      field: 'companyInformation.supplierName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: any) => params.row.companyInformation?.supplierName,
    },
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      flex: 1,
      renderCell: (params: any) => {
        const date = new Date(params.row.dateAdded);
        return date.toLocaleDateString();
      },
    },
    {
      field: 'previewer',
      headerName: 'Previewer',
      flex: 1,
      renderCell: (params: any) => params?.row?.companyInformation?.previewer?.name ?? 'Unknown',
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      flex: 1,
      renderCell: (params: any) => {
        if (!params.row.lastActivity) return '-';
        const date = new Date(params.row.lastActivity);
        return date.toLocaleDateString();
      },
    },
    {
      field: 'currentHits',
      headerName: 'Current Hits',
      flex: 1,
      renderCell: (params: any) => params?.row?.searchResults?.articlesCount ?? 0,
    },
    {
      field: 'cageCode',
      headerName: 'CAGE',
      flex: 1,
      renderCell: (params: any) => params?.row?.companyInformation?.cageCode ?? '',
    },
    {
      field: 'cageStatus',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: any) => params?.row?.companyInformation?.cageStatus ?? '',
    },
    {
      field: 'duns',
      headerName: 'D-U-N-S',
      flex: 1,
      renderCell: (params: any) => params?.row?.companyInformation?.duns ?? '',
    },
    {
      field: 'actions',
      type: 'actions' as const,
      headerName: 'Actions',
      width: 100,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDeleteClick(params.row.previewId)}
          key="delete"
        />,
        <GridActionsCellItem
          icon={<Visibility />}
          label="View"
          onClick={() => handleViewClick(params.row.previewId)}
          key="view"
        />,
      ],
    },
  ];
  const CustomNoRowsOverlay = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: '18px',
        color: 'gray',
      }}
    >
      <Typography>No data available</Typography>
    </Box>
  );

  return (
    <Box sx={{ p: '40px', height: '600px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4">Suppliers in Preview</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={4} mb={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const selectedSupplierId = selectionModel[0];
            handleAddSupplier(selectedSupplierId);
          }}
        >
          Add Supplier to Preview
        </Button>
      </Box>

      <DataGridPro
        rows={rows}
        columns={columns}
        getRowId={(row) => row.previewId}
        loading={isLoading}
        onRowSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel as number[]);
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        checkboxSelection
        disableRowSelectionOnClick
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </Box>
  );
};

export default SuppliersPreview;
