import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';
import { DnBEntityResolutionDto } from '../../api/types/types';

interface EntityResolutionDialogProps {
  dialogOpen: boolean;
  entityResolutionData: DnBEntityResolutionDto | null;
  handleCloseDialog: () => void;
  handleUseData: () => void;
}

const EntityResolutionDialog: React.FC<EntityResolutionDialogProps> = ({
  dialogOpen,
  entityResolutionData,
  handleCloseDialog,
  handleUseData,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={handleCloseDialog}>
      <DialogTitle component="div">
        <Typography variant="h6">Supplier Data Match Found</Typography>
      </DialogTitle>
      <DialogContent>
        {entityResolutionData ? (
          <>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              A match was found. Do you want to overwrite the existing values with the information
              below?
            </Typography>
            <Typography>
              <strong>DUNS Number:</strong> {entityResolutionData.dunsNumber}
            </Typography>
            <Typography>
              <strong>Primary Name:</strong> {entityResolutionData.primaryName}
            </Typography>
            <Typography>
              <strong>Street Address:</strong> {entityResolutionData.streetAddressLine1}
            </Typography>
            <Typography>
              <strong>City:</strong> {entityResolutionData.addressLocality}
            </Typography>
            <Typography>
              <strong>State:</strong> {entityResolutionData.addressRegion}
            </Typography>
            <Typography>
              <strong>Country:</strong> {entityResolutionData.countryISOAlpha2Code}
            </Typography>
            <Typography>
              <strong>Postal Code:</strong> {entityResolutionData.postalCode}
            </Typography>
          </>
        ) : (
          <Typography>No data available</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="secondary">
          Close
        </Button>
        {entityResolutionData && (
          <Button onClick={handleUseData} variant="contained" color="primary">
            Use Data
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EntityResolutionDialog;
