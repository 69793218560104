import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './side-nav-toolbar.scss';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';
import RootMenu from '../../components/root-menu/RootMenu';
import SubMenu from '../../components/sub-menu/SubMenu';
import SuppliersAPI from '../../api/suppliers/suppliersAPI';
import { addSelectedSupplier, addSuppliers } from '../../slices/suppliersSlice';
import { useDispatch, useSelector } from 'react-redux';
import RiskDiscoveryApi from '../../api/riskDiscovery/riskDiscoverylAPI';
import { setKeywordModel, setMenuLoading } from '../../slices/discoverySlice';
import RiskCaseApi from '../../api/riskCase/riskCaseApi';
import { setMenuRiskCase } from '../../slices/riskCasesSlice';
import KeywordModelAPI from '../../api/keywordModel/keywordModelApi';
import { addKeywordModelsMenu } from '../../slices/keywordModelSlice';
import { AppNames, AppResourceNames } from '../../api/types/custom-types';

var scrmOptions = [
  { id: '1', text: 'Dashboard', href: '/scrm/dashboard' },
  { id: '2', text: 'Suppliers' },
  { id: '3', text: 'Risk Models' },
  { id: '4', text: 'Discovery' },
  { id: '5', text: 'Risk Cases' },
  { id: '6', text: 'Admin' },
];

var userAdminOptions = [
  {
    id: '100',
    text: 'Permissions',
    expanded: true,
    items: [
      { id: '101', text: 'Users' },
      { id: '102', text: 'Roles' },
    ],
  },
  {
    id: '200',
    text: 'Site Settings',
    expanded: true,
    items: [{ id: '201', text: 'Colors' }],
  },
];

const nsnOptions = [
  { id: '1', text: 'Search', href: '/nsn/search' },
  { id: '2', text: 'Batch', href: '/nsn/batch' },
  { id: '3', text: 'Admin Settings', href: '/nsn/admin' },
];

const dmsmsOptions = [
  //{ id: '1', text: 'Dashboard', href: '/dmsms/dashboard' },
  { id: '2', text: 'Parts', href: '/dmsms/parts' },
  //{ id: '3', text: 'Log', href: '/dmsms/log' },
  { id: '4', text: 'Admin Settings', href: '/dmsms/admin' },
];

export default function SideNavToolbar({ children }) {
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [selectedMenuOption, setSelectedMenuOption] = useState('Root');
  const [menuOptions, setMenuOptions] = useState(userAdminOptions);

  const location = useLocation();
  const dispatch = useDispatch();
  const suppliers = useSelector((state) => state.suppliers.allSuppliers);
  const roles = useSelector((state) => state.auth.roles); // as RoleWithRoleResourcesDto[]
  const isSuperAdmin = useSelector((state) => state.auth.isSuperAdmin);

  /**
   * Removes menu options based on user roles
   */
  function filterMenuOptionsByRoles() {
    // Only Super Admins can access Site Admin options.
    userAdminOptions = [];

    // Role Resources - SCRM
    var supplierRoleResource = null;
    var riskCaseRoleResource = null;
    var riskModelRoleResource = null;
    var dashboardRoleResource = null;
    var articlehitsRoleResource = null;
    var isScrmAdmin = false;
    // Role Resources - Nsn Query
    var isNsnQueryAdmin = false;
    var searchRoleResource = null;
    var batchRoleResource = null;
    // Role Resources - DMSMS
    var isDmsmsAdmin = false;
    var partsRoleResource = null;

    for (var i = 0; i < roles.length; i++) {
      const role = roles[i];

      for (var j = 0; j < role.roleResources.length; j++) {
        const roleResource = role.roleResources[j];

        // SCRM - Suppliers
        if (
          roleResource.appResource.app.appName == AppNames.SCRM &&
          roleResource.appResource.resourceName == AppResourceNames.Suppliers
        ) {
          // If Admin skip menu filtering.
          if (role.isAdmin === true) {
            isScrmAdmin = true;
          } else {
            supplierRoleResource = roleResource;
          }
        }
        // SCRM - Risk Case
        if (
          roleResource.appResource.app.appName == AppNames.SCRM &&
          roleResource.appResource.resourceName == AppResourceNames.RiskCases
        ) {
          // If Admin skip menu filtering.
          if (role.isAdmin === true) {
            isScrmAdmin = true;
          } else {
            riskCaseRoleResource = roleResource;
          }
        }
        // SCRM - Discovery
        if (
          roleResource.appResource.app.appName == AppNames.SCRM &&
          roleResource.appResource.resourceName == AppResourceNames.ArticleHits
        ) {
          // If Admin skip menu filtering.
          if (role.isAdmin === true) {
            isScrmAdmin = true;
          } else {
            articlehitsRoleResource = roleResource;
          }
        }
        // SCRM - Risk Case
        if (
          roleResource.appResource.app.appName == AppNames.SCRM &&
          roleResource.appResource.resourceName == AppResourceNames.Suppliers
        ) {
          // If Admin skip menu filtering.
          if (role.isAdmin === true) {
            isScrmAdmin = true;
          } else {
            riskCaseRoleResource = roleResource;
          }
        }

        // SCRM - Dashboard
        if (
          roleResource.appResource.app.appName == AppNames.SCRM &&
          roleResource.appResource.resourceName == AppResourceNames.Dashboard
        ) {
          // If Admin skip menu filtering.
          if (role.isAdmin === true) {
            isScrmAdmin = true;
          } else {
            dashboardRoleResource = roleResource;
          }
        }

        // NSN Query - Search
        if (
          roleResource.appResource.app.appName == AppNames.NsnQuery &&
          roleResource.appResource.resourceName == AppResourceNames.Search
        ) {
          // If Admin skip menu filtering.
          if (role.isAdmin === true) {
            isNsnQueryAdmin = true;
          } else {
            searchRoleResource = roleResource;
          }
        }

        // NSN Query - Batch
        if (
          roleResource.appResource.app.appName == AppNames.NsnQuery &&
          roleResource.appResource.resourceName == AppResourceNames.Batch
        ) {
          // If Admin skip menu filtering.
          if (role.isAdmin === true) {
            isNsnQueryAdmin = true;
          } else {
            batchRoleResource = roleResource;
          }
        }

        // DMSMS - Parts
        if (
          roleResource.appResource.app.appName == AppNames.DMSMS &&
          roleResource.appResource.resourceName == AppResourceNames.Parts
        ) {
          // If Admin skip menu filtering.
          if (role.isAdmin === true) {
            isDmsmsAdmin = true;
          } else {
            partsRoleResource = roleResource;
          }
        }
      }
    }

    if (isScrmAdmin === false) {
      // Admin menu item
      scrmOptions = scrmOptions.filter((c) => c.text !== 'Admin');

      // Suppliers menu item
      if (supplierRoleResource && supplierRoleResource.canView === false) {
        scrmOptions = scrmOptions.filter((c) => c.text !== 'Suppliers');
      }

      // Risk Models menu item
      if (riskModelRoleResource && riskModelRoleResource.canView === false) {
        scrmOptions = scrmOptions.filter((c) => c.text !== 'Risk Models');
      }

      // Discovery menu item
      if (articlehitsRoleResource && articlehitsRoleResource.canView === false) {
        scrmOptions = scrmOptions.filter((c) => c.text !== 'Discovery');
      }

      // Risk Cases menu item
      if (riskCaseRoleResource && riskCaseRoleResource.canView === false) {
        scrmOptions = scrmOptions.filter((c) => c.text !== 'Risk Cases');
      }

      // Dashboard menu item
      if (dashboardRoleResource && dashboardRoleResource.canView === false) {
        scrmOptions = scrmOptions.filter((c) => c.text !== 'Dashboard');
      }
    }

    if (isDmsmsAdmin === false) {
      // Admin menu item
      dmsmsOptions = dmsmsOptions.filter((c) => c.text !== 'Admin Settings');

      // Batch menu item
      if (partsRoleResource && partsRoleResource.canView === false) {
        dmsmsOptions = dmsmsOptions.filter((c) => c.text !== 'Parts');
      }
    }

    if (isNsnQueryAdmin === false) {
      // Admin menu item
      nsnOptions = nsnOptions.filter((c) => c.text !== 'Admin Settings');
      // Search menu item
      if (searchRoleResource && searchRoleResource.canView === false) {
        nsnOptions = nsnOptions.filter((c) => c.text !== 'Search');
      }
      // Batch menu item
      if (batchRoleResource && batchRoleResource.canView === false) {
        nsnOptions = nsnOptions.filter((c) => c.text !== 'Batch');
      }
    }
  }

  if (isSuperAdmin === false) {
    filterMenuOptionsByRoles();
  }

  const returnToRootMenu = () => {
    setSelectedMenuOption('Root');
  };

  const fetchSuppliers = async () => {
    try {
      const data = await SuppliersAPI.getAllSuppliers();
      dispatch(addSuppliers({ allSuppliers: data }));
    } catch (error) {
      console.error(error);
    }
  };
  const clearSelectedSupplier = () => {
    dispatch(
      addSelectedSupplier({
        selectedSupplier: {
          id: '',
          supplierName: '',
        },
      })
    );
  };

  const fetchKeywordModelsMenu = async () => {
    try {
      const data = await KeywordModelAPI.getRiskModelsMenuItems();
      dispatch(addKeywordModelsMenu({ keywordsModelsMenu: data.keywordModels }));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMenuDiscovery = async () => {
    try {
      dispatch(setMenuLoading(true));
      const data = await RiskDiscoveryApi.getRiskDiscoveryMenu();
      dispatch(setKeywordModel(data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setMenuLoading(false));
    }
  };

  const fetchMenuRiskCase = async () => {
    try {
      const data = await RiskCaseApi.getRiskCasesMenu();
      dispatch(setMenuRiskCase(data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMenuDiscovery();
    fetchMenuRiskCase();
    fetchKeywordModelsMenu();
    if (suppliers.length === 0) {
      fetchSuppliers();
    }
  }, []);

  const url = useEffect(() => {
    if (location.pathname.startsWith('/scrm')) {
      setMenuOptions(scrmOptions);
      if (location.pathname.startsWith('/scrm/dashboard')) {
        setSelectedMenuOption('Dashboard');
      } else if (location.pathname.startsWith('/scrm/suppliers')) {
        setSelectedMenuOption('Suppliers');
      } else if (location.pathname.startsWith('/scrm/risk-models')) {
        setSelectedMenuOption('Risk Models');
      } else if (location.pathname.startsWith('/scrm/discovery')) {
        setSelectedMenuOption('Discovery');
      } else if (location.pathname.startsWith('/scrm/risk-cases')) {
        setSelectedMenuOption('Risk Cases');
      } else if (location.pathname.startsWith('/scrm/admin')) {
        setSelectedMenuOption('Admin');
      }
    } else if (location.pathname.startsWith('/site-admin')) {
      setMenuOptions(userAdminOptions);
      setSelectedMenuOption('Site Admin');
    } else if (location.pathname.startsWith('/nsn')) {
      setMenuOptions(nsnOptions);

      if (location.pathname.startsWith('/nsn/search')) {
        setSelectedMenuOption('Search');
      } else if (location.pathname.startsWith('/nsn/batch')) {
        setSelectedMenuOption('Batch');
      } else if (location.pathname.startsWith('/nsn/admin')) {
        setSelectedMenuOption('Admin Settings');
      }
    } else if (location.pathname.startsWith('/dmsms')) {
      setMenuOptions(dmsmsOptions);

      if (location.pathname.startsWith('/dmsms/admin')) {
        setSelectedMenuOption('Admin Settings');
      } else if (location.pathname.startsWith('/dmsms/parts')) {
        setSelectedMenuOption('Parts');
      } else if (location.pathname.startsWith('/dmsms/log')) {
        setSelectedMenuOption('Log');
      } else if (location.pathname.startsWith('/dmsms/dashboard')) {
        setSelectedMenuOption('Dashboard');
      }
    } else {
      setMenuOptions([]);
    }
  }, [
    location.pathname.startsWith('/home'),
    location.pathname.startsWith('/nsn'),
    location.pathname.startsWith('/scrm'),
    location.pathname.startsWith('/site-admin'),
    location.pathname.startsWith('/dmsms'),
  ]);

  const handleMenuOptionClick = (option) => {
    switch (option) {
      case 'Suppliers':
        setSelectedMenuOption(option);
        clearSelectedSupplier();
        break;
    }

    if (option === 'Suppliers') {
      setSelectedMenuOption(option);
      clearSelectedSupplier();
    }
  };

  const renderMenu = () => {
    if (selectedMenuOption === 'Suppliers') {
      return (
        <SubMenu
          returnToRootMenu={returnToRootMenu}
          text={selectedMenuOption}
          setSelectedMenuOption={setSelectedMenuOption}
        />
      );
    }
    if (
      selectedMenuOption === 'Risk Models' ||
      selectedMenuOption === 'Risk Cases' ||
      selectedMenuOption === 'Admin' ||
      selectedMenuOption === 'Discovery' ||
      selectedMenuOption === 'Site Admin'
    ) {
      return (
        <SubMenu
          returnToRootMenu={returnToRootMenu}
          text={selectedMenuOption}
          setSelectedMenuOption={setSelectedMenuOption}
        />
      );
    } else {
      return (
        <RootMenu
          menuOptions={menuOptions}
          selectedMenuOption={selectedMenuOption}
          setSelectedMenuOption={setSelectedMenuOption}
          handleMenuOptionClick={handleMenuOptionClick}
        />
      );
    }
  };

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Drawer className={['drawer', patchCssClass].join(' ')} opened={true} template={'menu'}>
        <div className={'container'}>
          <ScrollView className={'layout-body with-footer'}>
            <div className={'content'}>{children}</div>
          </ScrollView>
        </div>
        <Template name={'menu'}>
          <div className={'dx-swatch-additional side-navigation-menu'}>{renderMenu()}</div>
        </Template>
      </Drawer>
    </div>
  );
}
