import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './index.scss';
import Assessment from './assessments';
import Financials from './financials';
import Filings from './filings';
import Information from './information';
import FamilyTree from './family-tree';
import Principals from './principals';
import { useLocation } from 'react-router';
import { isNullOrWhitespace } from '../../../utils/validation-helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setId } from '../../../slices/suppliersSlice';
import { useParams } from 'react-router';

const DNB_SECTIONS = [
  { id: '1', description: 'Information', component: <Information /> },
  { id: '2', description: 'Assessment', component: <Assessment /> },
  { id: '5', description: 'Family Tree', component: <FamilyTree /> },
  { id: '6', description: 'Filings', component: <Filings /> },
  { id: '7', description: 'Financials', component: <Financials /> },
  { id: '8', description: 'Principals', component: <Principals /> },
];

const DBTab = () => {
  const dispatch = useDispatch();
  const { id: urlId } = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const sectionId = query.get('section');
  const defaultExpanded = !isNullOrWhitespace(sectionId) ? '6' : '1';
  const id = useSelector((state) => state.suppliers.id);
  const supplierPreviewId = useSelector((state) => state.suppliers.supplierPreviewId);

  const exactPath = 'scrm/suppliers/preview/';
  const isSupplierPreview = location.pathname.includes(exactPath);

  useEffect(() => {
    if (isSupplierPreview && !supplierPreviewId) return;
    dispatch(setId(isSupplierPreview ? supplierPreviewId : urlId));
  }, [urlId, supplierPreviewId]);

  if (id === null) return;

  return (
    <div key={id} style={{ paddingTop: 20 }}>
      {DNB_SECTIONS.map((section) => (
        <Accordion key={section.id} defaultExpanded={section.id === defaultExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${section.id}-content`}
            id={`panel-${section.id}-header`}
          >
            <Typography variant="subtitle1">{section.description}</Typography>
          </AccordionSummary>
          <AccordionDetails>{section.component}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default DBTab;
