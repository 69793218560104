import React, { useEffect, useState } from 'react';
import {
  RoleWithRoleResourcesDto,
  SubstitutePartDto,
  SupplierDto,
} from '../../../../api/types/types';
import { createColumn } from '../../../../utils/data-grid';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridDensity,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridSlots,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import DmsmsApi from '../../../../api/dmsms/dmsmsAPI';
// @ts-ignore: Implicit any module
import SuppliersAPI from '../../../../api/suppliers/suppliersAPI';
import { Button, SnackbarCloseReason } from '@mui/material';
import { Add, Edit, Delete, Save, Cancel } from '@mui/icons-material';
import { ValidationSnackbar } from '../../../../components/form';
import ConfirmDeleteDialog from '../../../form/dialogs/ConfirmDeleteDialog';
import { useSelector } from 'react-redux';
import { AppNames, AppResourceNames, PermissionsType } from '../../../../api/types/custom-types';
import { doesHavePermission } from '../../../../utils/permissions-helpers';

interface SubstitutePartsGridProps {
  watchedPartId: number;
  density?: GridDensity | undefined;
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

export default function SubstitutePartsGrid({ watchedPartId, density }: SubstitutePartsGridProps) {
  // States
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
    severity: 'error',
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null);
  const roles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canEdit = doesHavePermission(
    roles,
    AppNames.DMSMS,
    AppResourceNames.Parts,
    isSuperAdmin,
    PermissionsType.AddEdit
  );
  const canArchive = doesHavePermission(
    roles,
    AppNames.DMSMS,
    AppResourceNames.Parts,
    isSuperAdmin,
    PermissionsType.Archive
  );
  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
      message: '',
    }));
  };

  /**
   *
   * @param props Tootlar for grid
   * @returns
   */
  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = Math.random() * 10000;
      setRows((oldRows) => [
        ...oldRows,
        {
          id: id,
          watchedPartId: watchedPartId,
          supplierId: null,
          supplerName: null,
          oemPartNumber: '',
          nsn: null,
          description: '',
          unitCost: 0,
          comments: null,
          isNew: true,
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };

    return (
      <GridToolbarContainer>
        {canEdit === true ? (
          <Button
            color="primary"
            sx={{ m: 1 }}
            variant="contained"
            startIcon={<Add />}
            onClick={handleClick}
          >
            Add record
          </Button>
        ) : null}
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setConfirmDeleteId(id as number);
    setOpenConfirmDialog(true);
  };

  async function deletePart(id: number) {
    try {
      const response = await DmsmsApi.deleteSubstitutePart(id as number);

      if (response.isSuccess) {
        setRows(rows.filter((row) => row.id !== id));
        setConfirmDeleteId(null);
        setOpenConfirmDialog(false);
        setSnackBar('Row deleted', 'success');
      } else {
        console.error(response.message);
        setSnackBar(response.message);
      }
    } catch (error) {
      console.error(error);
      setSnackBar('An error occurred.');
    }
  }

  /**
   *
   * @param id
   */
  const handleDelete = async (id: number) => {
    deletePart(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);

    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  /**
   * Transforms a GridRowModel into a SubstitutePartDto
   * @param row
   * @returns
   */
  function getDtoFromGridRowModel(row: GridRowModel): SubstitutePartDto {
    return {
      id: row.id,
      watchedPartId: watchedPartId,
      supplierId: row.supplierId,
      supplerName: row.supplierName,
      oemPartNumber: row.oemPartNumber,
      nsn: row.nsn,
      description: row.description,
      unitCost: row.unitCost,
      comments: row.comments,
    } as SubstitutePartDto;
  }

  async function processRowUpdate(newRow: GridRowModel, oldRow: GridRowModel) {
    const id = newRow.id;
    try {
      // Create
      if (newRow.isNew) {
        newRow.id = 0;
        const response = await DmsmsApi.createSubstitutePart(getDtoFromGridRowModel(newRow));

        if (response.isSuccess) {
          newRow.id = response.result.id;
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === id ? updatedRow : row)));
          setSnackBar('Row added', 'success');
          return updatedRow;
        } else {
          console.error(response.message);
          setSnackBar(response.message);
        }
        // Update
      } else {
        const response = await DmsmsApi.updateSubstitutePart(getDtoFromGridRowModel(newRow));

        if (response.isSuccess) {
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          setSnackBar('Row updated', 'success');
          return updatedRow;
        } else {
          console.error(response.message);
          setSnackBar(response.message);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackBar('An error occurred.');
    }
    return oldRow;
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /**
   * Triggers the snackbar
   * @param message
   * @param severity
   */
  function setSnackBar(message: string, severity: string = 'error') {
    setSnackBarState((oldState) => ({
      ...oldState,
      open: true,
      message: message,
      severity: severity,
    }));
  }

  /**
   * Retrieve grid data
   */
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await DmsmsApi.getSubstitutePartsForWatchedPart(watchedPartId);

        if (response.isSuccess) {
          setRows(response.result);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    fetchData();
  }, [watchedPartId]);

  /**
   * Retrieve suppliers.
   */
  useEffect(() => {
    async function fetchSuppliers() {
      try {
        const response = await SuppliersAPI.getAllSuppliers(true);
        setSuppliers(response);
      } catch (error) {
        console.error(error);
      }
    }
    fetchSuppliers();
  }, []);

  // Columns for grid
  const columns: GridColDef[] = [
    {
      field: 'supplierId',
      headerName: 'Supplier',
      type: 'singleSelect',
      width: 150,
      editable: true,
      valueOptions: suppliers,
      getOptionLabel: (value: any) => value.supplierName,
      getOptionValue: (value: any) => value.id,
    },
    {
      ...createColumn('oemPartNumber', 'OEM Part', 'string', true),
      width: 200,
      flex: 0,
      editable: true,
    },
    { ...createColumn('nsn', 'NSN'), width: 200, editable: true },
    {
      ...createColumn('description', 'Description'),
      width: 100,
      flex: 1,
      editable: true,
    },
    {
      ...createColumn('unitCost', 'Unit Cost', 'number'),
      width: 100,
      valueFormatter: (value) => {
        if (value == null) return '';

        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(value);
      },
      editable: true,
    },
    { ...createColumn('comments', 'Comments'), flex: 1, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          ...(canEdit === true
            ? [
                <GridActionsCellItem
                  icon={<Edit />}
                  label="Edit"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />,
              ]
            : []),
          ...(canArchive === true
            ? [
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
                />,
              ]
            : []),
        ];
      },
    },
  ];

  return (
    <div style={{ height: '370px' }}>
      <DataGridPro
        loading={isLoading}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        rows={rows}
        columns={columns}
        density={density}
        // pagination
        // pageSizeOptions={[5, 10, 20, { value: -1, label: 'All' }]}
        slots={{
          toolbar: EditToolbar as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
      />
      <ValidationSnackbar
        open={snackBarState.open}
        message={snackBarState.message}
        severity={snackBarState.severity}
        onClose={handleSnackBarClose}
      />
      <ConfirmDeleteDialog
        deleteId={confirmDeleteId}
        open={openConfirmDialog}
        onClose={setOpenConfirmDialog}
        onDelete={handleDelete}
      />
    </div>
  );
}
