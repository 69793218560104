import React from 'react';
import { Typography } from '@mui/material';
import './index.scss';
import SupplierSettings from '../../components/scrm/admin/supplier-settings/SupplierSettings';
import { RoleWithRoleResourcesDto } from '../../api/types/types';
import { AppNames } from '../../api/types/custom-types';
import { useSelector } from 'react-redux';
import { isAdminForApp } from '../../utils/permissions-helpers';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

const SupplierSettingsAdmin = () => {
  const siteRoles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canViewPage = isAdminForApp(siteRoles, AppNames.SCRM, isSuperAdmin);

  if (canViewPage === true) {
    return (
      <div className="admin">
        <Typography className="title">Supplier Settings</Typography>
        <SupplierSettings />
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default SupplierSettingsAdmin;
