export const combineHelp = (
  <div style={{ fontSize: '12pt' }}>
    <p>
      Combines NSNs, Part Nos., CAGEs in to an AND clause.
      <b>Note: If this value is checked, all lists must contain the same enumber of items.</b>
    </p>
    <p>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div>
          NSNs:
          <ul>
            <li>111</li>
            <li>222</li>
          </ul>
        </div>
        <div>
          Part Nos:
          <ul>
            <li>333</li>
            <li>444</li>
          </ul>
        </div>
        <div>
          Cages:
          <ul>
            <li>555</li>
            <li>666</li>
          </ul>
        </div>
      </div>

      <div
        style={{
          textDecoration: 'underline',
        }}
      >
        COMBINE Checked:
      </div>
      <div>
        QUERY: (NSN = 111 AND PartNo = 333 AND Cage = 555) OR (NSN = 222 AND PartNo = 444 AND Cage =
        666)
      </div>
      <div
        style={{
          textDecoration: 'underline',
        }}
      >
        COMBINE Unhecked:
      </div>
      <div>QUERY: (NSN = 111 OR 222) AND (PartNo = 333 OR 444) AND (Cage = 555 OR 666)</div>
    </p>
  </div>
);

export const niinHelp = (
  <div style={{ fontSize: '12pt' }}>
    <p>
      Enter a NIIN or NSN for a direct lookup.
      <b>Note: If a value is provided for NIIN/NSN, all other search fields are ignored!</b>
    </p>
    <p>
      For a <b>NIIN/NSN</b> search, leading zeros can be omitted. Enter up to nine positions for
      NIIN and thirteen positions for NSN in the NIIN/NSN field and then click on the Go button.
      NIIN/NSN can be entered with or without dashes. If dashes are entered, up to sixteen positions
      are accepted.
    </p>
  </div>
);

export const partHelp = (
  <div style={{ fontSize: '12pt' }}>
    <p>
      For a <b>Part Number</b> search, enter the part number in the part number field and then click
      on the Go button. Similar part numbers will also be returned. For example, part search 12-345
      will also return 12345, 1-2345, 123-45, etc.
    </p>
    <p>
      To perform a <b>Partial Part Number</b> search enter three or more characters followed by an
      asterisk (*) and then click on the Go button.
    </p>
    <p>Part number searches can be combined with Cage and Keyword parameters</p>
    <p>
      <b>Note: If a value is provided for NIIN/NSN, all other search fields are ignored!</b>
    </p>
  </div>
);

export const cageHelp = (
  <div style={{ fontSize: '12pt' }}>
    <p>
      For a <b>Cage Code</b> search, enter the Cage code in the Cage Code field and then click on
      the Go button. A list of Parts associated with that Cage Code will be returned (which may
      consists of serveral pages).
    </p>
    <p>Cage Code searches can be combined with Part Number and Keyword parameters</p>
    <p>
      <b>Note: If a value is provided for NIIN/NSN, all other search fields are ignored!</b>
    </p>
  </div>
);

export const amscHelp = (
  <div style={{ fontSize: '12pt' }}>
    <p>
      Selecting <b>Include only NSNs that have an AMSC of 'G'</b> will result in the list of found
      NSNs to only include those NSNs that have at least one Management entry with an AMSC of 'G'.
    </p>
    <p>
      An AMSC of <b>G</b> means the government has unlimited rights to the technical data. This
      could make it easier to find alternate sources of supply as the technical data should be
      available.
    </p>
    <p>
      <b>Note: If a value is provided for NIIN/NSN, all other search fields are ignored!</b>
    </p>
  </div>
);

export const sosHelp = (
  <div style={{ fontSize: '12pt' }}>
    <p>
      Selecting <b>Include only Source of Supply parts</b> will result in the list of found parts to
      only include those parts with RNCC/RNVC values of 2/2, 3/2 or 5/2. These values typically
      identify the part as a Source of Supply to DLA.
    </p>
    <p>
      <b>Note: If a value is provided for NIIN/NSN, all other search fields are ignored!</b>
    </p>
  </div>
);

export const querySyntaxHelp = (
  <div style={{ fontSize: '12pt' }}>
    <p>
      For an <b>Item Name Keyword</b>
      search, first select whether you want to search the <b>
        Item Name &amp; Characteristics
      </b>{' '}
      data or just the <b>Item Name</b>. Then, enter your query string and click the Go button.
    </p>
    <p>The Keyword search allows simple keywords, or complex search requests.</p>
    <ul>
      <li>
        A simple example is entering the keyword <b>ENGINE</b>, all entries containing ENGINE will
        be returned.
      </li>
      <li>
        If you enter the two keywords <b>ENGINE OIL</b>, all enties containing ENGINE <b>or</b> OIL{' '}
        <b>or</b> both will be returned.
      </li>
      <li>
        If you enter the following: <b>ENGINE AND OIL</b>, all enties containing <b>only both</b>{' '}
        ENGINE <b>and</b> OIL will be returned.
      </li>
    </ul>
    The following describes the Search Syntax:
    <dl>
      <dt>Terms: What you want to search for.</dt>
      <dd>
        • A query is broken up into terms and operators. There are two types of terms: Single Terms
        and Phrases.
        <br />• A Single Term is a single word such as <b>ENGINE</b> or <b>OIL</b>.<br />• A Phrase
        is a group of words surrounded by double quotes such as <b>"ENGINE OIL"</b>.
        <br />• Multiple terms can be combined together with Boolean operators to form a more
        complex query, such as <b>"ENGINE OIL" AND DIESEL</b>
        <br />
        <br />
      </dd>
      <dt> Operators</dt>
      <dd>
        <ul>
          <li>
            <b>AND</b> : Both terms required (same as <b>&&</b>)
          </li>
          <li>
            <b>OR</b> : Only one term is required (same as <b>||</b>)
          </li>
          <li>
            <b>NOT</b> : Term must not exist (same as <b>-</b>)
          </li>
          <li>
            <b>*</b> : multiple character wildcard
          </li>
          <li>
            <b>?</b> : single character wildcard
          </li>
          <li>
            <b>+</b> : Term is required
          </li>
          <li>
            <b>-</b> : Term must not exist
          </li>
          <li>
            <b>()</b> : Grouping for precedence
          </li>
          <li>
            Examples: +(ENGINE AND OIL) -DIESEL [must contain both ENGINE and OIL, but cannot
            contain DIESEL]
          </li>
          <br />
        </ul>
      </dd>
      <dt> Wildcard Searches</dt>
      <dd>
        The single character wildcard search looks for terms that match that with the single
        character replaced. Wildcards cannot be the first character.
        <br />
        <br />
        For example, to search for <b>TEXT</b> or <b>TEST</b> you can use the search: <b>TE?T</b>
        <br />
        Multiple character wildcard searches looks for 0 or more characters.
        <br />
        For example, to search for <b>TEST</b>, <b>TESTS</b> or <b>TESTER</b>, you can use the
        search: <b>TEST*</b>
        <br />
        You can also use the wildcard searches in the middle of a term: <b>TE*T</b>
        <br />
        In addition, wildcard searches can be performed with phrases: <b>"HELL* W?RLD"</b>
        <br />
        <br />
      </dd>
      <dt>Fuzzy Searches</dt>
      <dd>
        Fuzzy searches are based on the Levenshtein Distance, or Edit Distance algorithm.
        <br />
        To do a fuzzy search use the tilde "~" symbol at the end of a Single word Term.
        <br />
        For example to search for a term similar in spelling to <b>ROAM</b> use the fuzzy search:{' '}
        <b>ROAM~</b>
        <br />
        This search will find terms like <b>FOAM</b> and <b>ROAMS</b>.
        <br />
        <br />
        An additional (optional) parameter can specify the required similarity.
        <br />
        The value is between 0 and 1, with a value closer to 1 only terms with a higher similarity
        will be matched.
        <br />
        For example: <b>ROAM~0.8</b>
        <br />
        The default that is used if the parameter is not given is 0.5.
        <br />
        <br />
      </dd>
      <dt>Proximity Searches</dt>
      <dd>
        Keyword Search supports finding words that are a within a specific distance away.
        <br />
        To do a proximity search use the tilde "~" symbol at the end of a Phrase.
        <br />
        For example to search for a <b>ENGINE</b> and <b>DIESEL</b> within 4 words of each other,
        use the search: <b>"ENGINE DIESEL"~4</b>
        <br />
        <br />
      </dd>
      <dt>Boolean Operators</dt>
      <dd>
        Boolean operators allow terms to be combined through logic operators.
        <br />
        Keyword Search supports AND, "+", OR, NOT and "-" as Boolean operators.
        <br />
        <dl>
          <dt>OR</dt>
          <dd>
            The OR operator is the default conjunction operator. This means that if there is no
            Boolean operator between two terms, the OR operator is used. The OR operator links two
            terms and finds a matching item if either of the terms exist. This is equivalent to a
            union using sets. The symbol || can be used in place of the word OR.
            <br />
            <br />
            To search for documents that contain either <b>"DIESEL OIL"</b> or just <b>"DIESEL"</b>,
            use the query:
            <br />
            <b>"DIESEL OIL" DIESEL</b>
            <br /> or
            <br /> <b>"DIESEL OIL" OR DIESEL</b>
            <br />
            <br />
          </dd>
          <dt>AND</dt>
          <dd>
            The AND operator matches documents where both terms exist anywhere in the text of a
            single item. This is equivalent to an intersection using sets. The symbol && can be used
            in place of the word AND.
            <br />
            <br />
            To search for items that contain <b>"DIESEL ENGINE"</b> and <b>"OIL LUBRICANT"</b>, use
            the query:
            <br />
            <b>"DIESEL ENGINE" AND "OIL LUBRICANT"</b>
          </dd>
          <dt>
            <span style={{ fontSize: '14px', fontWeight: '700' }}>+</span>
          </dt>
          <dd>
            The "+" or <i>required</i> operator requires that the term after the "+" symbol exist
            somewhere in a the field of a single item.
            <br />
            <br />
            To search for items that must contain <b>ENGINE</b> and may contain <b>OIL</b>, use the
            query:
            <b>+ENGINE OIL</b>
            <br />
            <br />
          </dd>

          <dt>NOT</dt>
          <dd>
            The NOT operator excludes items that contain the term after NOT. This is equivalent to a
            difference using sets. The symbol ! can be used in place of the word NOT.
            <br />
            <br />
            To search for items that contain <b>"ENGINE OIL"</b> but not <b>"DIESEL"</b>, use the
            query:
            <br />
            <b>"ENGINE OIL" NOT "DIESEL"</b>
            <br />
            <br />
            Note: The NOT operator cannot be used with just one term. There must be one inclusive
            search item.
            <br />
            For example, the following search will return no results: <b>NOT DIESEL</b>
            <br />
            <br />
          </dd>
          <dt>
            <span style={{ fontSize: '18px', fontWeight: '700' }}>-</span>
          </dt>
          <dd>
            The "-" or <i>prohibit</i> operator excludes items that contain the term after the "-"
            symbol.
            <br />
            <br />
            To search for documents that contain "DIESEL ENGINE" but not "OIL LUBRICANT", use the
            query:
            <br />
            <b>"DIESEL ENGINE" -"OIL LUBRICANT"</b>
            <br />
            <br />
          </dd>
          <dt>Grouping</dt>
          <dd>
            Parentheses are used to group clauses to form sub queries.
            <br />
            This can be very useful if you want to control the boolean logic for a query.
            <br />
            <br />
            To search for either <b>DIESEL</b> or <b>PROPANE</b> and <b>FUEL</b>, use the query:
            <br />
            <b>(DIESEL OR PROPANE) AND FUEL</b>
            <br />
            This eliminates any confusion and makes sure that <b>FUEL</b> must exist and either term{' '}
            <b>DIESEL</b> or <b>PROPANE</b> may exist.
          </dd>
        </dl>
      </dd>
    </dl>
  </div>
);
