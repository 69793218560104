import httpClient from '../configs/axiosConfig';
import config from '../../config';

const { ALTRUITY_API_URL } = config;

const SupplierRelationType = {
  getAllSupplierRelations: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/Supplier/GetSupplierRelationTypes`);
    return data.result;
  },
};

export default SupplierRelationType;
