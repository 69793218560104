import { Box, Typography } from '@mui/material';
import React from 'react';

export default function CannotViewPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h2" marginTop={10}>
        You do not have permission to view this page
      </Typography>
    </Box>
  );
}
