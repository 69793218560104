import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import RiskCasesTab from './RiskCasesTab';
import { RiskCaseViewModelDto } from '../../../api/types/types';
import DnBTab from './DnBTab';
import PartsTab from './PartsTab';

interface SupplierDetailPanelProps {
  riskCases: RiskCaseViewModelDto[];
  supplierName: string;
  supplierId: number;
  duns: string;
}

const SupplierDetailPanel: React.FC<SupplierDetailPanelProps> = ({
  riskCases,
  supplierName,
  supplierId,
  duns,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ height: '100%', width: '89%', padding: 2 }}>
      <Tabs value={activeTab} onChange={handleTabChange} sx={{ marginBottom: 2 }}>
        <Tab label="Risk Cases" />
        <Tab label="Dun & Bradstreet" />
        <Tab label="Parts" />
      </Tabs>

      <Box pb={2}>
        {activeTab === 0 && <RiskCasesTab riskCases={riskCases} />}

        {activeTab === 1 && <DnBTab supplierId={supplierId} duns={duns} />}

        {activeTab === 2 && <PartsTab supplierName={supplierName} />}
      </Box>
    </Box>
  );
};

export default SupplierDetailPanel;
