import { Accordion } from 'devextreme-react';
import './index.scss';
import GeneralInformation from './general-information';
import Names from './names';
import Locations from './locations';
import Industries from './industries';
import RelatedSuppliersTable from './related-suppliers-table';
import { useParams } from 'react-router-dom';

function CustomTitle(data) {
  return <div className="accordion-header">{data.Description}</div>;
}

const CustomItem = (data) => data.Component;

const SupplierSetupTab = ({ canAddOrEdit }) => {
  const { id } = useParams();

  const isNumber = !isNaN(parseInt(id));

  const SUPPLIER_SETUP_SECTIONS = [
    {
      Description: 'General Information',
      Component: <GeneralInformation canAddOrEdit={canAddOrEdit} />,
    },
    ...(isNumber
      ? [
          { Description: 'Names', Component: <Names canAddOrEdit={canAddOrEdit} /> },
          { Description: 'Locations', Component: <Locations canAddOrEdit={canAddOrEdit} /> },
          {
            Description: 'Subsidiaries',
            Component: (
              <RelatedSuppliersTable
                entityName="subsidiary"
                relationTypeId={4}
                canAddOrEdit={canAddOrEdit}
              />
            ),
          },
          {
            Description: 'Competitors',
            Component: (
              <RelatedSuppliersTable
                entityName="competitor"
                relationTypeId={8}
                canAddOrEdit={canAddOrEdit}
              />
            ),
          },
          {
            Description: 'Customers',
            Component: (
              <RelatedSuppliersTable
                entityName="customer"
                relationTypeId={7}
                canAddOrEdit={canAddOrEdit}
              />
            ),
          },
          { Description: 'Industries', Component: <Industries canAddOrEdit={canAddOrEdit} /> },
          {
            Description: 'Suppliers',
            Component: (
              <RelatedSuppliersTable
                entityName="supplier"
                relationTypeId={2}
                canAddOrEdit={canAddOrEdit}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <Accordion
        dataSource={SUPPLIER_SETUP_SECTIONS}
        collapsible={true}
        multiple={true}
        animationDuration={300}
        itemTitleRender={CustomTitle}
        itemRender={CustomItem}
        id="accordion-container"
      />
    </div>
  );
};

export default SupplierSetupTab;
