import React, { useMemo } from 'react';
import { Button, useTheme } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { RiskCaseViewModelDto } from '../../../api/types/types';
import GridBadge from './GridBadge';

interface RiskCasesTabProps {
  riskCases: RiskCaseViewModelDto[];
}

const formatDate = (dateString: string | null) =>
  dateString ? new Date(dateString).toLocaleDateString('en-US') : '';

const getMonochromaticScaleValue = (scale: string[], value: number): string => {
  const minIndex = Math.floor(scale.length * 0.2);
  const maxIndex = Math.ceil(scale.length * 0.8) - 1;

  const index = Math.round(minIndex + ((value - 1) * (maxIndex - minIndex)) / 4);
  return scale[index];
};

const RiskCasesTab: React.FC<RiskCasesTabProps> = ({ riskCases }) => {
  const theme = useTheme();

  const flattenRiskCases = useMemo(
    () =>
      riskCases.map((riskCase) => {
        const probability = riskCase.measures.riskMeasures.find(
          (rm) => rm.riskMeasureName === 'Probability'
        );
        const magnitude = riskCase.measures.riskMeasures.find(
          (rm) => rm.riskMeasureName === 'Magnitude'
        );

        return {
          id: riskCase.generalInformation.riskCaseId,
          name: riskCase.generalInformation.riskCaseName,
          dateCreated: riskCase.generalInformation.dateAdded
            ? formatDate(riskCase.generalInformation.dateAdded.toString())
            : null,
          lastActivity: riskCase.generalInformation.dateUpdated
            ? formatDate(riskCase.generalInformation.dateUpdated.toString())
            : null,
          description: riskCase.generalInformation.description,
          owner: riskCase.generalInformation.owner?.name,
          probability: probability,
          magnitude: magnitude,
        };
      }),
    [riskCases]
  );

  const riskCaseColumns: GridColDef[] = [
    { field: 'name', headerName: 'Risk Case Name', width: 200 },
    { field: 'dateCreated', headerName: 'Date Created', width: 150 },
    { field: 'lastActivity', headerName: 'Last Activity', width: 150 },
    { field: 'description', headerName: 'Description', width: 250 },
    { field: 'owner', headerName: 'Owner', width: 150 },
    {
      field: 'probability',
      headerName: 'Probability',
      width: 150,
      renderCell: (params) => {
        const probability = params.value?.availableRiskMeasureLevels.find(
          (rml: any) => rml.id === params.value.selectedLevelId
        )?.label;

        return (
          <GridBadge
            text={probability}
            color={
              params.value?.themeColor?.monochromaticScale?.length > 0
                ? getMonochromaticScaleValue(
                    params.value?.themeColor?.monochromaticScale,
                    params.value?.selectedLevelId
                  )
                : theme.palette.primary.main
            }
          />
        );
      },
    },
    {
      field: 'magnitude',
      headerName: 'Magnitude',
      width: 150,
      renderCell: (params) => {
        const magnitude = params.value?.availableRiskMeasureLevels.find(
          (rml: any) => rml.id === params.value.selectedLevelId
        )?.label;

        return (
          <GridBadge
            text={magnitude}
            color={
              params.value?.themeColor?.monochromaticScale?.length > 0
                ? getMonochromaticScaleValue(
                    params.value?.themeColor?.monochromaticScale,
                    params.value?.selectedLevelId
                  )
                : theme.palette.primary.main
            }
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() => window.open(`/scrm/risk-cases/supplier/${params.row.id}`, '_blank')}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <DataGridPro
      rows={flattenRiskCases}
      columns={riskCaseColumns}
      disableRowSelectionOnClick
      getRowId={(row) => row.id}
    />
  );
};

export default RiskCasesTab;
