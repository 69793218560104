import React from 'react';
import { createPortal } from 'react-dom';
import { Alert, CircularProgress, Slide, SlideProps, Snackbar } from '@mui/material';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

interface FileDownloadProps {
  isLoading: boolean;
  message: string;
}

export default function FileUploadSnackbar({ isLoading, message }: FileDownloadProps) {
  return createPortal(
    <Snackbar
      open={isLoading}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert variant="filled" severity="info" icon={<CircularProgress size={24} />}>
        {message}
      </Alert>
    </Snackbar>,
    document.getElementById('app-snackbar')!
  );
}
