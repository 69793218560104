import { Box, Typography } from '@mui/material';
import React from 'react';

interface GridBadgeProps {
  color: string;
  text: string;
}

const GridBadge: React.FC<GridBadgeProps> = ({ text, color }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '2px',
          backgroundColor: color,
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <Typography
          color="white"
          variant="body2"
          textAlign="center"
          sx={{
            fontSize: '12px',
            lineHeight: 1.2,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default GridBadge;
