import React from 'react';
import ColorsComponent from '../../components/Site-Admin/Colors/Colors';
import './Colors.scss';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

const Colors = () => {
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  if (isSuperAdmin === true) {
    return (
      <div className="colors-page">
        <Typography className="title">Colors</Typography>
        <ColorsComponent />
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default Colors;
