import { Checkbox } from '@mui/material';
import { DataGridPro, GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { isNullOrWhitespace } from '../../../utils/validation-helpers';
import DmsmsApi from '../../../api/dmsms/dmsmsAPI';
import GridBadge from './GridBadge';

const LifeStageIndicator = ({ stage }: { stage: string }) => {
  const getLifeStageColor = (stage: string) => {
    switch (stage) {
      case 'Early':
        return '#6FCF97';
      case 'Middle':
        return '#FFDD75';
      case 'Late':
        return '#FF6B6B';
      default:
        return '#9e9e9e';
    }
  };

  if (isNullOrWhitespace(stage)) {
    stage = 'Not Set';
  }

  return <GridBadge text={stage} color={getLifeStageColor(stage)} />;
};

interface PartsTabProps {
  supplierName: string;
}

const PartsTab: React.FC<PartsTabProps> = ({ supplierName }) => {
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await DmsmsApi.getWatchedParts(supplierName);

      setRows(response.result);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    { field: 'partNumber', headerName: 'Part No.', flex: 1 },
    { field: 'nsn', headerName: 'NSN', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'upperLevelAssembly', headerName: 'ULA', flex: 1 },
    {
      field: 'gidep',
      type: 'boolean',
      headerName: 'GIDEP',
      width: 80,
      flex: 0,
      renderCell: (params) => <Checkbox checked={params.row.isInGidep} disabled />,
    },
    {
      field: 'activeCage',
      headerName: 'Active Cage?',
      type: 'boolean',
      width: 110,
      flex: 0,
      valueGetter: (value, row) => {
        if (!value) {
          return false;
        }
        return row.cageStatus?.indexOf('ACTIVE') >= 0 || row.cageStatus?.indexOf('SPECIALIZED');
      },
      renderCell: (params) => (
        <Checkbox
          checked={
            params.row.cageStatus?.indexOf('ACTIVE') >= 0 ||
            params.row.cageStatus?.indexOf('SPECIALIZED') >= 0
          }
          disabled
        />
      ),
    },
    {
      field: 'atRisk',
      headerName: 'At Risk?',
      type: 'boolean',
      width: 70,
      flex: 0,
      valueGetter: (value, row) => {
        return row.isAtRisk;
      },
      valueSetter: (value, row) => {
        return { ...row, isAtRisk: value };
      },
      renderCell: (params) => <Checkbox checked={params.row.isAtRisk} disabled />,
    },
    {
      field: 'stageOfLife',
      headerName: 'Life Stage',
      width: 90,
      flex: 0,
      filterable: true,
      type: 'singleSelect',
      renderCell: (params) => <LifeStageIndicator stage={params.row.stageOfLife} />,
    },
  ];

  return (
    <DataGridPro rows={rows} columns={columns} disableRowSelectionOnClick loading={isLoading} />
  );
};

export default PartsTab;
