import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import configAzureLogin from '../configAzureLogin';
import UserAdminAPI from '../api/userAdmin/userAdminAPI';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: configAzureLogin.appId,
    redirectUri: configAzureLogin.redirectUri,
    authority: configAzureLogin.authority,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
});

const debug = false;

function log(header, message) {
  if (debug === true) {
    console.log(header);
    console.log(message);
  }
}

export const handleAuth = createAsyncThunk('auth/handleAuth', async (_, { rejectWithValue }) => {
  try {
    await msalInstance.initialize();
    const response = await msalInstance.handleRedirectPromise();
    log('handleRedirectPromise', response);

    if (response && response.account) {
      var acct = response.account;
      acct.tenantProfiles = null; // Throws not serializable error.
      log('ACCOUNT 1', acct);

      // Get Altruity authentication token.
      const authResponse = await UserAdminAPI.getToken(acct.username);

      if (authResponse.isSuccess === false) {
        console.error(authResponse.message);
        return rejectWithValue(authResponse.message);
      }

      return {
        account: authResponse.result.user,
        accessToken: authResponse.result.token,
      };
    } else {
      const accounts = msalInstance.getAllAccounts();
      log('ACCOUNTS', accounts);

      if (accounts.length > 0) {
        var userAcct = accounts[0];
        userAcct.tenantProfiles = null; // Throws not serializable error.able error.
        log('ACCOUNT 2', accounts);

        // Get Altruity authentication token.
        const authResponse = await UserAdminAPI.getToken(userAcct.username);

        if (authResponse.isSuccess === false) {
          console.error(authResponse.message);
          return rejectWithValue(authResponse.message);
        }

        return {
          account: authResponse.result.user,
          accessToken: authResponse.result.token,
        };
      } else {
        await msalInstance.loginRedirect({
          scopes: configAzureLogin.scopes,
        });
      }
    }
  } catch (error) {
    log('ERROR', error);
    if (error instanceof InteractionType) {
      await msalInstance.loginRedirect({
        scopes: configAzureLogin.scopes,
      });
    } else {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    accessToken: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      msalInstance.logoutRedirect();
      state.user = null;
      state.accessToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleAuth.fulfilled, (state, action) => {
        state.user = action.payload.account.fullName;
        state.email = action.payload.account.email;
        state.userName = action.payload.account.userName;
        state.accessToken = action.payload.accessToken;
        state.roles = action.payload.account.userRoles.map((c) => c.role);
        state.loading = false;
        state.isSuperAdmin = action.payload.account.isSuperAdmin;
      })
      .addCase(handleAuth.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
