import React, { useState } from 'react';
import {
  Button,
  TextField,
  FormGroup,
  Box,
  Grid,
  Typography,
  SnackbarCloseReason,
  CircularProgress,
} from '@mui/material';
import {
  AltruityCheckbox,
  AltruityTextField,
  ValidationSnackbar,
} from '../../../components/form/index';
import { textAreaStyles } from '../../../components/form/styles';
// @ts-ignore: Implicit any module
import { AppNames, AppResourceNames, PermissionsType } from '../../../api/types/custom-types';
//import { createSvgIcon } from "@mui/material/utils";
import NsnApi from '../../../api/nsn/nsnAPI';
import { CloudUpload, Publish } from '@mui/icons-material';
// @ts-ignore: Implicit any module
import NsnBatchResultsDataGrid from '../../../components/nsn/batch/NsnBatchResultsDataGrid';
import { isNullOrWhitespace } from '../../../utils/validation-helpers';
import { doesHavePermission } from '../../../utils/permissions-helpers';
import { useSelector } from 'react-redux';
import { NsnBatchRequestDto, RoleWithRoleResourcesDto } from '../../../api/types/types';
import CannotViewPage from '../../../components/no-permission-content/CannotViewPage';

const defaultFormData: NsnBatchRequestDto = {
  useNiin: true,
  usePartNumber: false,
  useCage: false,
  useFsc: false,
  useNomenclature: false,
  useCharacteristics: false,
  items: [],
  includeSosOnly: false,
  includeAwarded: false,
  useApproximateSearch: false,
  restrictRnvcToTwo: false,
  description: '',
  dibbsLookupDays: 365,
  dibbsHotOpportunities: false,
  moe: null,
  amc: null,
  amsc: null,
  rncc: null,
  rnvc: null,
  isOpen: true,
  isClosed: true,
  isAwarded: true,
  notInSam: false,
  excludedCages: null,
  file: null,
};

export default function NsnBatchPage() {
  // For state
  const [formData, setFormData] = useState<NsnBatchRequestDto>(defaultFormData);

  // Validation snackbar state
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
    severity: 'error',
  });

  const [isSending, setIsSending] = useState(false);
  const [lookupFormat, setLookupFormat] = useState(createLookupFormat(defaultFormData));

  const roles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canViewPage = doesHavePermission(
    roles,
    AppNames.NsnQuery,
    AppResourceNames.Batch,
    isSuperAdmin,
    PermissionsType.View
  );
  const canAddBatch = doesHavePermission(
    roles,
    AppNames.NsnQuery,
    AppResourceNames.Batch,
    isSuperAdmin,
    PermissionsType.AddEdit
  );
  const inputLabelClass = 'css-oad82q-MuiFormLabel-root-MuiInputLabel-root';

  /**
   * Closes the validation SnackBar
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
      message: '',
    }));
  };

  /***
   * Updates the form data state
   */
  function handleFormFieldChange(e: any, fieldName: string) {
    var { name, value } = e.target;

    // Use checkbox 'checked' prop to determine value.
    if (e.target.type === 'checkbox') {
      value = e.target.checked ? true : false;
      // convert text area to string array
    } else if (fieldName === 'items' || fieldName === 'excludedCages') {
      value = value.split('\n');
      // Add file.
    } else if (fieldName === 'file') {
      if (e.target.files.length) {
        value = e.target.files[0];
      }
    } else {
      // Convert to int if string is int.
      const intVal = parseInt(value);
      if (!isNaN(intVal)) {
        value = intVal;
      }
    }

    setFormData((prevData: any) => {
      const newData = {
        ...prevData,
        [name]: value,
      };

      setLookupFormat(createLookupFormat(newData));
      return newData;
    });
  }

  /**
   * Submits search parameters
   */
  async function handleSubmit() {
    /**
     * Displays error message
     * @param message
     * @param error
     */
    const showError = (message: string, error?: any) => {
      if (error) {
        console.error(message, error);
      }
      setSnackBarState({
        open: true,
        message: message,
        severity: 'error',
      });
    };

    if (formData.file === null && formData.items.length === 0) {
      setSnackBarState({
        open: true,
        message: 'No Search Criteria Provided!',
        severity: 'error',
      });
      return;
    }

    setIsSending(true);
    try {
      var response = await NsnApi.postBatch(formData);

      if (response.isSuccess) {
        setSnackBarState({
          open: true,
          message: 'Batch Request Submitted!',
          severity: 'success',
        });
      } else {
        showError(response.message);
      }
    } catch (ex) {
      showError('Error posting batch', ex);
    }
    setIsSending(false);
  }

  /**
   * Clears input item data
   */
  function handleClearItems() {
    setFormData((prevData: any) => ({
      ...prevData,
      items: [],
      file: null,
    }));
  }

  /**
   * Sets the format of the lookup items based on the lookup methods.
   * @param fData
   * @returns
   */
  function createLookupFormat(fData: NsnBatchRequestDto) {
    var format = '';
    if (fData.useNiin === true) {
      format += 'NIIN';
    }

    if (fData.useCage === true) {
      if (isNullOrWhitespace(format)) {
        format += 'CAGE';
      } else {
        format += ', CAGE';
      }
    }

    if (fData.usePartNumber === true) {
      if (isNullOrWhitespace(format)) {
        format += 'PART NUMBER';
      } else {
        format += ', PART NUMBER';
      }
    }

    if (fData.useFsc === true) {
      if (isNullOrWhitespace(format)) {
        format += 'FSC';
      } else {
        format += ', FSC';
      }
    }

    if (fData.useNomenclature === true) {
      if (isNullOrWhitespace(format)) {
        format += 'Nomenclature';
      } else {
        format += ', Nomenclature';
      }
    }

    if (fData.useCharacteristics === true) {
      if (isNullOrWhitespace(format)) {
        format += 'Characteristics';
      } else {
        format += ', Characteristics';
      }
    }

    return format;
  }

  if (canViewPage === true) {
    return (
      <>
        <Box className="nsn-query-container page-container">
          <Grid container>
            <Grid item xs={12}>
              <h4 className="page-title">NSN Batch Search</h4>
              <Grid
                container
                direction="column"
                rowSpacing={1}
                className="rcorners"
                style={{
                  margin: 'auto',
                  marginTop: '20px',
                }}
                sx={{
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    color: 'black',
                    textAlign: 'center',
                  }}
                >
                  <b>Enter Search Criteria</b>
                </Grid>

                <Grid container columnSpacing={4}>
                  <Grid item xs={4} container rowSpacing={2}>
                    <Grid item xs={12}>
                      <AltruityTextField
                        id="description"
                        name="description"
                        label="Description"
                        width="100%"
                        onChange={(e: any) => handleFormFieldChange(e, 'description')}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" className="typography-container">
                        <Typography className={inputLabelClass}>Select Lookup Method</Typography>
                      </Box>
                      <FormGroup>
                        <AltruityCheckbox
                          label="NIIN"
                          name="useNiin"
                          onChange={(e: any) => handleFormFieldChange(e, 'useNiin')}
                          defaultChecked={formData.useNiin}
                        />
                        <AltruityCheckbox
                          label="CAGE"
                          name="useCage"
                          onChange={(e: any) => handleFormFieldChange(e, 'useCage')}
                          defaultChecked={formData.useCage}
                        />
                        <AltruityCheckbox
                          label="PART NUMBER"
                          name="usePartNumber"
                          onChange={(e: any) => handleFormFieldChange(e, 'usePartNumber')}
                          defaultChecked={formData.usePartNumber}
                        />
                        <AltruityCheckbox
                          label="FSC"
                          name="useFsc"
                          onChange={(e: any) => handleFormFieldChange(e, 'useFsc')}
                          defaultChecked={formData.useFsc}
                        />
                        <AltruityCheckbox
                          label="Nomenclature"
                          name="useNomenclature"
                          onChange={(e: any) => handleFormFieldChange(e, 'useNomenclature')}
                          defaultChecked={formData.useNomenclature}
                        />
                        {/* <AltruityCheckbox
												label="Characteristics"
												name="useCharacteristics"
												onChange={(e: any) =>
													handleFormFieldChange(
														e,
														"useCharacteristics"
													)
												}
												defaultChecked={
													formData.useCharacteristics
												}
											/> */}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <AltruityTextField
                        id="dibbsLookupDays"
                        name="dibbsLookupDays"
                        label="Lookup Days"
                        width="25%"
                        onChange={(e: any) => handleFormFieldChange(e, 'dibbsLookupDays')}
                        defaultValue={formData.dibbsLookupDays}
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AltruityCheckbox
                        label="DIBBS Hot Opportunities (No Sos)"
                        name="dibbsHotOpportunities"
                        onChange={(e: any) => handleFormFieldChange(e, 'dibbsHotOpportunities')}
                        defaultChecked={formData.dibbsHotOpportunities}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={4} container rowSpacing={2}>
                    <Grid
                      item
                      container
                      columns={15}
                      columnSpacing={1}
                      xs={12}
                      style={{
                        flexBasis: 'calc(100% + 8px)',
                        maxWidth: 'calc(100% + 8px)',
                      }}
                    >
                      <Grid item xs={3}>
                        <AltruityTextField
                          id="moe"
                          name="moe"
                          label="MOE"
                          width="100%"
                          onChange={(e: any) => handleFormFieldChange(e, 'moe')}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <AltruityTextField
                          id="amc"
                          name="amc"
                          label="AMC"
                          width="100%"
                          onChange={(e: any) => handleFormFieldChange(e, 'amc')}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <AltruityTextField
                          id="amsc"
                          name="amsc"
                          label="AMSC"
                          width="100%"
                          onChange={(e: any) => handleFormFieldChange(e, 'amsc')}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <AltruityTextField
                          id="rncc"
                          name="rncc"
                          label="RNCC"
                          width="100%"
                          onChange={(e: any) => handleFormFieldChange(e, 'rncc')}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <AltruityTextField
                          id="rnvc"
                          name="rnvc"
                          label="RNVC"
                          width="100%"
                          onChange={(e: any) => handleFormFieldChange(e, 'rnvc')}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" className="typography-container">
                        <Typography className={inputLabelClass}>Award Status</Typography>
                      </Box>
                      <FormGroup>
                        <Box display="flex">
                          <AltruityCheckbox
                            label="Open"
                            name="isOpen"
                            onChange={(e: any) => handleFormFieldChange(e, 'isOpen')}
                            defaultChecked={formData.isOpen}
                          />
                          <AltruityCheckbox
                            label="Closed"
                            name="isClosed"
                            onChange={(e: any) => handleFormFieldChange(e, 'isClosed')}
                            defaultChecked={formData.isClosed}
                          />
                          <AltruityCheckbox
                            label="Awarded"
                            name="isAwarded"
                            onChange={(e: any) => handleFormFieldChange(e, 'isAwarded')}
                            defaultChecked={formData.isAwarded}
                          />
                        </Box>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={inputLabelClass}>SAM.gov Status</Typography>
                      <AltruityCheckbox
                        label="Not in SAM.gov"
                        name="notInSam"
                        onChange={(e: any) => handleFormFieldChange(e, 'notInSam')}
                        defaultChecked={formData.notInSam}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="excludedCages"
                        name="excludedCages"
                        label="Excluded CAGEs"
                        fullWidth
                        multiline
                        rows={6}
                        onChange={(e: any) => handleFormFieldChange(e, 'excludedCages')}
                        value={
                          formData.excludedCages
                            ? formData.excludedCages.length > 1
                              ? formData.excludedCages.join('\n')
                              : formData.excludedCages[0]
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4} container rowSpacing={2}>
                    <Grid item xs={12}>
                      <Typography className={inputLabelClass}>Input Format</Typography>
                      <Typography>{lookupFormat}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="items"
                        name="items"
                        label="Lookup Items"
                        fullWidth
                        multiline
                        disabled={formData.file ? true : false}
                        rows={14}
                        onChange={(e: any) => handleFormFieldChange(e, 'items')}
                        value={
                          formData.items.length > 1 ? formData.items.join('\n') : formData.items[0]
                        }
                      />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        sx={{
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          disabled={formData.items.length > 0}
                          startIcon={<CloudUpload />}
                        >
                          Upload file
                          <TextField
                            type="file"
                            id="file"
                            name="file"
                            hidden
                            inputProps={{
                              accept:
                                'text/csv,  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                            }}
                            sx={{ display: 'none' }}
                            onChange={(e: any) => handleFormFieldChange(e, 'file')}
                          />
                        </Button>
                        <Button variant="outlined" onClick={handleClearItems}>
                          Clear
                        </Button>
                      </Box>
                      {formData.file ? <Typography>{formData.file.name}</Typography> : null}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    {canAddBatch === true ? (
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isSending}
                        endIcon={isSending ? <CircularProgress size={16} /> : <Publish />}
                      >
                        {isSending ? 'Sending' : 'Post Batch'}
                      </Button>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <NsnBatchResultsDataGrid />
            </Grid>
          </Grid>
        </Box>
        <ValidationSnackbar
          open={snackBarState.open}
          message={snackBarState.message}
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        />
      </>
    );
  } else {
    return <CannotViewPage />;
  }
}
