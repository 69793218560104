// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router';
// import { Button, DataGrid, Form, LoadIndicator } from 'devextreme-react';
// import { GroupItem, Item, SimpleItem } from 'devextreme-react/cjs/form';
// import { Column, MasterDetail } from 'devextreme-react/cjs/data-grid';
// import './index.scss';
// import { formatDate } from '../../../../utils/format-helpers';
// import DnbAPI from '../../../../api/dnb/dnbAPI';

// const PrincipalsDetails = ({ principal, caption = '' }) => {
//   return (
//     <Form>
//       <GroupItem caption={caption} colSpan={6} colCount={6}>
//         <SimpleItem
//           dataField=""
//           label={{ text: 'FULL NAME' }}
//           colSpan={2}
//           editorOptions={{
//             readOnly: true,
//             value: principal?.fullName,
//           }}
//         />
//         <SimpleItem
//           dataField=""
//           label={{ text: 'GENDER' }}
//           colSpan={1}
//           editorOptions={{
//             readOnly: true,
//             value: principal?.gender,
//           }}
//         />
//         <SimpleItem
//           dataField=""
//           label={{ text: 'BIRTHDATE' }}
//           editorType="dxDateBox"
//           colSpan={1}
//           editorOptions={{
//             readOnly: true,
//             value: principal?.birthdate,
//           }}
//         />
//         <SimpleItem
//           dataField=""
//           label={{ text: 'BIRTH PLACE' }}
//           colSpan={1}
//           editorOptions={{
//             readOnly: true,
//             value: principal?.birthPlace,
//           }}
//         />
//         <SimpleItem
//           dataField=""
//           label={{ text: 'NATIONALITY' }}
//           colSpan={1}
//           editorOptions={{
//             readOnly: true,
//             value: principal?.nationality,
//           }}
//         />
//         <SimpleItem
//           dataField=""
//           label={{ text: 'RESPONSIBLE AREAS' }}
//           colSpan={3}
//           editorOptions={{
//             readOnly: true,
//             value: principal?.responsibleAreas,
//           }}
//         />
//         <SimpleItem
//           dataField=""
//           label={{ text: 'PAST BANKRUPTCY?' }}
//           colSpan={1}
//           editorOptions={{
//             readOnly: true,
//             value: principal?.hasPastBankruptcy ? 'Yes' : 'No',
//           }}
//         />
//         <SimpleItem
//           dataField=""
//           label={{ text: 'DISQUAL. DIRECTOR?' }}
//           colSpan={1}
//           editorOptions={{
//             readOnly: true,
//             value: principal?.isDisqualifiedDirector ? 'Yes' : 'No',
//           }}
//         />
//         <SimpleItem
//           dataField=""
//           label={{ text: 'SIGNING AUTH.?' }}
//           colSpan={1}
//           editorOptions={{
//             readOnly: true,
//             value: principal?.isSigningAuthority ? 'Yes' : 'No',
//           }}
//         />

//         <GroupItem colSpan={3}>
//           <p className="filing-datagrid-primary-title">Job Titles</p>
//           <DataGrid dataSource={principal?.dnBPersonJobTitles} showBorders={true}>
//             <Column dataField="title" caption="Title" />
//           </DataGrid>
//         </GroupItem>

//         <GroupItem colSpan={3}>
//           <p className="principals-datagrid-primary-title">Management Responsibilities</p>
//           <DataGrid dataSource={principal?.dnBPersonManagementResponsibilities} showBorders={true}>
//             <Column dataField="title" caption="Title" />
//           </DataGrid>
//         </GroupItem>

//         <GroupItem colSpan={3}>
//           <p className="principals-datagrid-primary-title">Positions</p>
//           <DataGrid dataSource={principal?.dnBPersonPositions} showBorders={true}>
//             <Column
//               dataField="startDate"
//               caption="Start Date"
//               cellRender={(cellData) => formatDate(cellData.data.startDate)}
//             />
//             <Column dataField="position" caption="Position" />
//           </DataGrid>
//         </GroupItem>

//         <GroupItem colSpan={3}>
//           <p className="principals-datagrid-primary-title">Associations</p>
//           <DataGrid dataSource={principal?.dnBPersonAssociations} showBorders={true}>
//             <Column
//               dataField="startDate"
//               caption="Start Date"
//               cellRender={(cellData) => formatDate(cellData.data.startDate)}
//             />
//             <Column dataField="name" caption="Name" />
//             <Column dataField="duns" caption="DUNS" />
//           </DataGrid>
//         </GroupItem>

//         <GroupItem colSpan={3}>
//           <p className="principals-datagrid-primary-title">Education</p>
//           <DataGrid dataSource={principal?.dnBPersonEducations} showBorders={true}>
//             <Column dataField="status" caption="Status" />
//             <Column dataField="qualification" caption="Qualification" />
//           </DataGrid>
//         </GroupItem>

//         <GroupItem colSpan={3}>
//           <p className="principals-datagrid-primary-title">Disqualifications</p>
//           <DataGrid dataSource={principal?.dnBPersonDisqualifications} showBorders={true}>
//             <Column
//               dataField="startDate"
//               caption="Start Date"
//               cellRender={(cellData) => formatDate(cellData.data.startDate)}
//             />
//             <Column dataField="description" caption="Description" />
//             <Column
//               dataField="endDate"
//               caption="End Date"
//               cellRender={(cellData) => formatDate(cellData.data.endDate)}
//             />
//           </DataGrid>
//         </GroupItem>

//         <GroupItem colSpan={3}>
//           <p className="principals-datagrid-primary-title">Hobbies</p>
//           <DataGrid dataSource={principal?.dnBPersonHobbies} showBorders={true}>
//             <Column dataField="hobby" caption="Hobby" />
//           </DataGrid>
//         </GroupItem>
//       </GroupItem>
//     </Form>
//   );
// };

// const Principals = () => {
//   const [principalsData, setPrincipalsData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { id } = useParams();

//   const fetchData = async (refresh) => {
//     if (!refresh) setIsLoading(true);

//     try {
//       const data = await DnbAPI.getPrincipalsDataById(id, refresh);
//       setPrincipalsData(data);
//     } catch (error) {
//       setError(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const handleRefresh = () => {
//     fetchData(true);
//   };

//   const mostSeniorPrincipal = principalsData?.dnBPrincipalPeople.find(
//     (person) => person.isMostSenior
//   );

//   const otherPrincipals = principalsData?.dnBPrincipalPeople.filter(
//     (person) => !person.isMostSenior
//   );

//   if (error) return <div>No Data</div>;

//   const renderDetails = ({ data }) => <PrincipalsDetails principal={data.data} />;

//   return (
//     <>
//       {isLoading ? (
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//         >
//           <LoadIndicator />
//         </div>
//       ) : (
//         <>
//           <Form formData={principalsData} colCount={6}>
//             <GroupItem caption="Summary" colSpan={6} colCount={6}>
//               <Item
//                 dataField="currentCount"
//                 label={{ text: 'CURRENT CNT.' }}
//                 colSpan={1}
//                 editorOptions={{ readOnly: true }}
//               />
//               <Item
//                 dataField="detrimentalCount"
//                 label={{ text: 'DETRIMENTAL CNT.' }}
//                 colSpan={1}
//                 editorOptions={{ readOnly: true }}
//               />
//               <Item
//                 dataField="detrimentalPercent"
//                 label={{ text: 'DETRIMENTAL %' }}
//                 colSpan={1}
//                 editorOptions={{ readOnly: true }}
//               />
//               <Item
//                 dataField="favorableInactiveAssociationsCount"
//                 label={{ text: 'FAV. INACTIVE CNT.' }}
//                 colSpan={1}
//                 editorOptions={{ readOnly: true }}
//               />
//               <GroupItem colSpan={2} colCount={4} cssClass="custom-item">
//                 <SimpleItem
//                   dataField="dateUpdated"
//                   editorType="dxDateBox"
//                   label={{ text: 'LAST UPDATED' }}
//                   colSpan={3}
//                   editorOptions={{ readOnly: true }}
//                 />
//                 <Item colSpan={1}>
//                   <Button
//                     icon="refresh"
//                     type="normal"
//                     onClick={handleRefresh}
//                     hint="Refresh"
//                     style={{ marginTop: '32px', marginLeft: '10px' }}
//                   />
//                 </Item>
//               </GroupItem>
//               <Item
//                 dataField="favorableInactiveAssociationsCount"
//                 label={{ text: 'INACTIVE ASSOC.' }}
//                 colSpan={1}
//                 editorOptions={{ readOnly: true }}
//               />
//               <Item
//                 dataField="otherAssociationsCount"
//                 label={{ text: 'OTHER ASSOC.' }}
//                 colSpan={1}
//                 editorOptions={{ readOnly: true }}
//               />
//               <Item
//                 dataField="unfavorableInactiveAssociationsCount"
//                 label={{ text: 'UNFAV. INACTIVE' }}
//                 colSpan={1}
//                 editorOptions={{ readOnly: true }}
//               />
//             </GroupItem>
//           </Form>
//           <PrincipalsDetails principal={mostSeniorPrincipal} caption="Most Senior Principal" />
//           <Form>
//             <GroupItem caption="Other Current Principals">
//               <DataGrid
//                 dataSource={otherPrincipals}
//                 showBorders={true}
//                 keyExpr="dnBPrincipalPersonId"
//               >
//                 <Column dataField="fullName" caption="Full Name" />
//                 <Column
//                   dataField="dnBPersonJobTitles"
//                   caption="Job Titles"
//                   cellRender={({ data }) =>
//                     data.dnBPersonJobTitles.map((title) => title.title).join(', ')
//                   }
//                 />
//                 <MasterDetail enabled={true} component={renderDetails} />
//               </DataGrid>
//             </GroupItem>
//           </Form>
//         </>
//       )}
//     </>
//   );
// };

// export default Principals;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import RefreshIcon from '@mui/icons-material/Refresh';
import { formatDate } from '../../../../utils/format-helpers';
import DnbAPI from '../../../../api/dnb/dnbAPI';
import { useSelector } from 'react-redux';

const SummarySection = ({ principalsData, handleRefresh }) => (
  <Card sx={{ mb: 3 }}>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="CURRENT CNT."
            value={principalsData?.currentCount || ''}
            InputProps={{ readOnly: true }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="DETRIMENTAL CNT."
            value={principalsData?.detrimentalCount || ''}
            InputProps={{ readOnly: true }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="DETRIMENTAL %"
            value={principalsData?.detrimentalPercent || ''}
            InputProps={{ readOnly: true }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="FAV. INACTIVE CNT."
            value={principalsData?.favorableInactiveAssociationsCount || ''}
            InputProps={{ readOnly: true }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <TextField
              fullWidth
              label="LAST UPDATED"
              value={formatDate(principalsData?.dateUpdated) || ''}
              InputProps={{ readOnly: true }}
              size="small"
            />
            <IconButton onClick={handleRefresh} sx={{ ml: 1 }}>
              <RefreshIcon />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="INACTIVE ASSOC."
            value={principalsData?.favorableInactiveAssociationsCount || ''}
            InputProps={{ readOnly: true }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="OTHER ASSOC."
            value={principalsData?.otherAssociationsCount || ''}
            InputProps={{ readOnly: true }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="UNFAV. INACTIVE"
            value={principalsData?.unfavorableInactiveAssociationsCount || ''}
            InputProps={{ readOnly: true }}
            size="small"
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const PrincipalsDetails = ({ principal, caption = '' }) => (
  <Box sx={{ p: 2 }}>
    <Grid container spacing={2}>
      {/* Información Personal */}
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="FULL NAME"
          value={principal?.fullName || ''}
          InputProps={{ readOnly: true }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label="GENDER"
          value={principal?.gender || ''}
          InputProps={{ readOnly: true }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label="BIRTHDATE"
          value={formatDate(principal?.birthdate) || ''}
          InputProps={{ readOnly: true }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label="BIRTH PLACE"
          value={principal?.birthPlace || ''}
          InputProps={{ readOnly: true }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label="NATIONALITY"
          value={principal?.nationality || ''}
          InputProps={{ readOnly: true }}
          size="small"
        />
      </Grid>

      {/* Áreas Responsables y Otros Campos */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="RESPONSIBLE AREAS"
          value={principal?.responsibleAreas || ''}
          InputProps={{ readOnly: true }}
          size="small"
          multiline
          rows={2}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label="PAST BANKRUPTCY?"
          value={principal?.hasPastBankruptcy ? 'Yes' : 'No'}
          InputProps={{ readOnly: true }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label="DISQUAL. DIRECTOR?"
          value={principal?.isDisqualifiedDirector ? 'Yes' : 'No'}
          InputProps={{ readOnly: true }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label="SIGNING AUTH.?"
          value={principal?.isSigningAuthority ? 'Yes' : 'No'}
          InputProps={{ readOnly: true }}
          size="small"
        />
      </Grid>

      {/* Tablas */}
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Job Titles
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {principal?.dnBPersonJobTitles?.map((title, index) => (
                <TableRow key={index}>
                  <TableCell>{title.title}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Management Responsibilities
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {principal?.dnBPersonManagementResponsibilities?.map((resp, index) => (
                <TableRow key={index}>
                  <TableCell>{resp.title}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Positions
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>Position</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {principal?.dnBPersonPositions?.map((position, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDate(position.startDate)}</TableCell>
                  <TableCell>{position.position}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Associations
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>DUNS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {principal?.dnBPersonAssociations?.map((assoc, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDate(assoc.startDate)}</TableCell>
                  <TableCell>{assoc.name}</TableCell>
                  <TableCell>{assoc.duns}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Education
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Qualification</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {principal?.dnBPersonEducations?.map((edu, index) => (
                <TableRow key={index}>
                  <TableCell>{edu.status}</TableCell>
                  <TableCell>{edu.qualification}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Disqualifications
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {principal?.dnBPersonDisqualifications?.map((disq, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDate(disq.startDate)}</TableCell>
                  <TableCell>{disq.description}</TableCell>
                  <TableCell>{formatDate(disq.endDate)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Hobbies
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Hobby</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {principal?.dnBPersonHobbies?.map((hobby, index) => (
                <TableRow key={index}>
                  <TableCell>{hobby.hobby}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Box>
);

const PrincipalsGrid = ({ principals }) => {
  const apiRef = useGridApiRef();

  const processedRows = React.useMemo(() => {
    return (principals || []).map((principal, index) => ({
      id: principal.id || `principal-${index}`,
      fullName: principal.fullName || '',
      jobTitles: principal.dnBPersonJobTitles
        ? principal.dnBPersonJobTitles.map((title) => title.title).join(', ')
        : '',
      ...principal,
    }));
  }, [principals]);

  const columns = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'jobTitles',
      headerName: 'Job Titles',
      flex: 1,
      minWidth: 200,
    },
  ];

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <PrincipalsDetails principal={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 600, []);

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Other Current Principals
        </Typography>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGridPro
            rows={processedRows}
            columns={columns}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            rowThreshold={0}
            disableSelectionOnClick
            density="compact"
            pagination
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            disableColumnMenu
            sx={{
              '& .MuiDataGrid-cell': {
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

const Principals = () => {
  const [principalsData, setPrincipalsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const id = useSelector((state) => state.suppliers.id);

  const fetchData = async (refresh = false) => {
    if (!refresh) setIsLoading(true);
    try {
      const data = await DnbAPI.getPrincipalsDataById(id, refresh);
      setPrincipalsData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Typography color="error">No Data</Typography>;

  const mostSeniorPrincipal = principalsData?.dnBPrincipalPeople.find(
    (person) => person.isMostSenior
  );

  const otherPrincipals = principalsData?.dnBPrincipalPeople.filter(
    (person) => !person.isMostSenior
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Summary
      </Typography>
      <SummarySection principalsData={principalsData} handleRefresh={() => fetchData(true)} />
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Most Senior Principal
          </Typography>
          <PrincipalsDetails principal={mostSeniorPrincipal} />
        </CardContent>
      </Card>
      <PrincipalsGrid principals={otherPrincipals || []} />
    </Box>
  );
};

export default Principals;
