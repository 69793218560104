import { createSlice } from '@reduxjs/toolkit';
import { SnackbarProps } from '../types/Snackbar';

const initialState = {
  keywordModel: {
    byKeywordModel: {},
    bySupplier: {},
    archivedHits: {
      byKeywordModel: {},
      bySupplier: {},
    },
  },
  riskCase: {
    isPopupVisible: false,
    data: null,
    articleIds: null,
  },
  snackbar: undefined as SnackbarProps | undefined,
  isMenuLoading: false,
};

const discoverySlice = createSlice({
  name: 'discovery',
  initialState,
  reducers: {
    setKeywordModel: (state, action) => {
      state.keywordModel = action.payload;
    },
    setRiskCase(state, action) {
      state.riskCase = { ...state.riskCase, ...action.payload };
    },
    setSnackbar(state, action) {
      state.snackbar = { ...state.snackbar, ...action.payload };
    },
    setMenuLoading: (state, action) => {
      state.isMenuLoading = action.payload;
    },
  },
});

export const { setKeywordModel, setRiskCase, setSnackbar, setMenuLoading } = discoverySlice.actions;
export default discoverySlice.reducer;
