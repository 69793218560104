// @ts-ignore: Implicit any module
import SuppliersAPI from '../../../api/suppliers/suppliersAPI';

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress,
  ListItem,
  DialogTitle,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Bubble } from 'react-chartjs-2';
import { Chart, Tooltip, Legend, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import {
  DashboardDto,
  DnBAssessmentChartDto,
  SupplierWeightViewModelDto,
} from '../../../api/types/types';
import DashboardApi from '../../../api/dashboard/dashboardApi';
import { DataPointDnBAssessmentData } from './types/types';
import CloseIcon from '@mui/icons-material/Close';
import { SupplierWithDnBAssessmentDataDto } from '../../../api/types/custom-types';
import MUITooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useSelector } from 'react-redux';

Chart.register(Tooltip, Legend, PointElement, LinearScale, Title, CategoryScale);

const DnBAssessmentScatterChart: React.FC = () => {
  const theme = useTheme();
  const [processedData, setProcessedData] = useState<DataPointDnBAssessmentData[]>([]);
  const [selectedSupplierWeight, setSelectedSupplierWeight] = useState<number>(0);
  const [supplierWeights, setSupplierWeights] = useState<SupplierWeightViewModelDto[]>([]);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [assessmentData, setAssessmentData] = useState<DnBAssessmentChartDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const dashboardData: DashboardDto = useSelector((state: any) => state.dashboard.dashboardData);
  const loadingDashboardData: boolean = useSelector((state: any) => state.dashboard.loading);

  useEffect(() => {
    if (dashboardData === null) return;
    const response = dashboardData.dnBAssessmentData;
    setAssessmentData(response);
    setProcessedData(groupDataPoints(response.suppliersInDnB as any));
    setSupplierWeights([
      {
        id: 0,
        label: 'All Suppliers',
        weight: 0,
        themeColor: null,
      },
      ...dashboardData.supplierWeights,
    ]);
  }, [dashboardData]);

  const getDnBAssessmentData = async () => {
    const response = await DashboardApi.getDnBAssessmentData(selectedSupplierWeight);
    setAssessmentData(response);

    setProcessedData(groupDataPoints(response.suppliersInDnB as any)); // added as any for the following issue:
    /** Argument of type 'import("c:/_Job/Repos/rheinmetall-permissions/FrontEnd/src/api/types/types").SupplierWithDnBAssessmentDataDto[]' is not assignable
     *  to parameter of type 'import("c:/_Job/Repos/rheinmetall-permissions/FrontEnd/src/api/types/custom-types").SupplierWithDnBAssessmentDataDto[]'.
     *  Type 'SupplierWithDnBAssessmentDataDto' is missing the following properties from type 'SupplierWithDnBAssessmentDataDto': supplierId, supplierNamets(2345)
     *
     *  SupplierWithDnBAssessmentDataDto might have broken when the types.ts was updated
     */
  };

  useEffect(() => {
    if (!selectedSupplierWeight) return;
    setLoading(true);
    getDnBAssessmentData().then(() => {
      setLoading(false);
    });
  }, [selectedSupplierWeight]);

  const groupDataPoints = (
    points: SupplierWithDnBAssessmentDataDto[]
  ): DataPointDnBAssessmentData[] => {
    const grouped: { [key: string]: DataPointDnBAssessmentData } = {};
    points.forEach((point) => {
      const key = `${point.failureClassScore}-${point.viabilityClassScore}`;
      if (grouped[key]) {
        const uniqueSuppliers = new Set(grouped[key].suppliers?.map((s) => s.supplierId));
        uniqueSuppliers.add(point.supplierId);
        grouped[key].suppliers = Array.from(uniqueSuppliers).map(
          (id) => points.find((s: SupplierWithDnBAssessmentDataDto) => s.supplierId === id)!
        );
      } else {
        grouped[key] = {
          x: point.failureClassScore,
          y: point.viabilityClassScore,
          suppliers: [point],
        };
      }
    });
    return Object.values(grouped);
  };

  const getAverage = (suppliers: SupplierWithDnBAssessmentDataDto[]): number => {
    if (suppliers.length === 0) return 0;

    const total = suppliers.reduce(
      (sum, supplier) => sum + (supplier.failureScoreNationalPercentile || 0),
      0
    );
    return total / suppliers.length;
  };

  const chartData = {
    datasets: [
      {
        label: 'Scatter Plot',
        data: processedData.map((d) => ({
          x: d.x,
          y: d.y,
          r: 4 + getAverage(d.suppliers),
          suppliers: [...d.suppliers],
        })),
        backgroundColor: theme.palette.primary.main,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'linear' as const,
        position: 'bottom' as const,
        title: {
          display: true,
          text: 'Failure Class Score',
        },
        min: 0,
        max: 9,
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        type: 'linear' as const,
        title: {
          display: true,
          text: 'Viability Class Score',
        },
        min: 0,
        max: 9,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const suppliers = context.raw.suppliers;
            return `(${context.raw.x}, ${context.raw.y}) - ${suppliers.map((s: SupplierWithDnBAssessmentDataDto) => s.supplierName)}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const openSuppliersDialog = () => {
    setOpenSupplierDialog(true);
  };

  const handleCloseSuppliersDialog = () => {
    setOpenSupplierDialog(false);
  };

  const helpTexts = [
    {
      label: 'X Axis - Failure Class Score',
      help: 'A value that succinctly expresses the likelihood of risk by specifying in which risk group the entity belongs. In other words, it specifies where this entity falls in a statistical distribution of all entities (e.g., a bell curve, a half-normal distribution). Values: 0 to 9.',
    },
    {
      label: 'Y Axis - Viability Class Score',
      help: 'The score that predicts the likelihood that the entity will go out of business in the next 12 months compared to all other US entities in the Dun & Bradstreet database. Values: 0 to 9 A score of 0 denotes the entity is either Out Of Business or Unable To Confirm. Scores 1 to 9 reflect the probability that the entity will go out of business; 1 being the lowest probability and 9 the highest.',
    },
    {
      label: 'Z Axis Point Size - Failure Score National Percentile',
      help: 'There are 10 possible bubbles sizes that correspond with Failure Score National Percentile. A small bubble size indicates that most other entities have a better Failure Score than the selected entity. As the bubble size increases, this indicates that the entity is performing better vis-a-vis other entities.',
    },
  ];

  return (
    <>
      <Dialog open={openSupplierDialog} onClose={handleCloseSuppliersDialog}>
        <DialogTitle
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" paddingRight={'10px'}>
            Suppliers not in D&B
          </Typography>
          <IconButton
            sx={{ color: theme.palette.primary.main }}
            onClick={handleCloseSuppliersDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingY: 0, height: '300px', overflowY: 'auto' }}>
          {assessmentData?.suppliersNotInDnB.map((supplier) => (
            <ListItem key={supplier.supplierId} sx={{ paddingX: 0 }}>
              <Button
                onClick={() => {
                  window.open(`/scrm/suppliers/${supplier.supplierId}`, '_blank');
                }}
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  padding: '1px',
                  color: theme.palette.primary.main,
                  fontSize: '16px',
                  minWidth: 'auto',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}
                variant="text"
              >
                {supplier.supplierName}
              </Button>
            </ListItem>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuppliersDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" pr={1}>
            D&B Assessment
          </Typography>
          <Chip
            label={
              loading || loadingDashboardData ? (
                <CircularProgress color="inherit" size={10} />
              ) : (
                assessmentData?.suppliersInDnB.length
              )
            }
            sx={{ fontWeight: 'bold', marginRight: '10px' }}
          />
          <Chip
            label={
              loading || loadingDashboardData ? (
                <CircularProgress color="inherit" size={10} />
              ) : (
                `${assessmentData?.suppliersNotInDnB.length} suppliers not found in D&B`
              )
            }
            disabled={loading || loadingDashboardData}
            onClick={(e) => {
              e.stopPropagation();
              openSuppliersDialog();
            }}
            sx={{ backgroundColor: 'red', color: 'white' }}
          />
        </AccordionSummary>
        <AccordionDetails>
          {loadingDashboardData || loading || loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height={'400px'}>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={4}
              width="100%"
              height={'400px'}
            >
              <Box width="65%" height={'100%'}>
                <Bubble data={chartData} options={options} />
              </Box>

              <Box display="flex" flexDirection="column" gap={2} width="30%">
                <FormControl fullWidth>
                  <InputLabel id="supplier-weight-label">Supplier Weight</InputLabel>
                  <Select
                    labelId="supplier-weight-label"
                    value={selectedSupplierWeight}
                    onChange={(e) => setSelectedSupplierWeight(e.target.value as number)}
                    label="Supplier Weight"
                  >
                    {supplierWeights.map((weight) => (
                      <MenuItem key={weight.id} value={weight.id}>
                        {weight.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  mt={1}
                  justifyContent="flex-start"
                >
                  {helpTexts.map((helpText) => (
                    <MUITooltip key={helpText.label} title={helpText.help} placement="left">
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          cursor: 'pointer',
                          color: theme.palette.primary.main,
                          padding: '8px 4px',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s',
                          '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                          },
                        }}
                      >
                        <HelpOutlineIcon sx={{ fontSize: '20px' }} />
                        <Typography ml={1} sx={{ fontSize: '0.875rem' }} fontWeight={500}>
                          {helpText.label}
                        </Typography>
                      </Box>
                    </MUITooltip>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DnBAssessmentScatterChart;
