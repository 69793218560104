import { Box, Button, LinearProgress, SnackbarCloseReason } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowOrderChangeParams,
  GridRowsProp,
  GridSlots,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { createColumn } from '../../../utils/data-grid';
import { Add, Cancel, Delete, Edit, Save } from '@mui/icons-material';
import { ValidationSnackbar } from '../../../components/form';
import './admin.scss';
import NsnApi from '../../../api/nsn/nsnAPI';
import { NsnSearchSortCriteriaDto, RoleWithRoleResourcesDto } from '../../../api/types/types';
import ConfirmDeleteDialog from '../../../components/form/dialogs/ConfirmDeleteDialog';
import { useSelector } from 'react-redux';
import { isAdminForApp } from '../../../utils/permissions-helpers';
import { AppNames } from '../../../api/types/custom-types';
import CannotViewPage from '../../../components/no-permission-content/CannotViewPage';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

export default function NsnAdminSettings() {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: '',
    severity: 'error',
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null);
  const [pageFirstLoad, setPageFirstLoad] = useState(true);
  const roles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canViewPage = isAdminForApp(roles, AppNames.NsnQuery, isSuperAdmin);
  /**
   * Retrieve grid data
   */
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await NsnApi.getAllSortCriteria();

        if (response.isSuccess) {
          setRows(response.result);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
      setPageFirstLoad(false);
    }

    fetchData();
  }, []);

  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState((prevState) => ({
      ...prevState,
      open: false,
      message: '',
    }));
  };

  /**
   *
   * @param props Tootlar for grid
   * @returns
   */
  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = Math.random() * 10000;
      setRows((oldRows) => {
        const lastRow = oldRows.length > 0 ? oldRows[oldRows.length - 1] : null;
        const newOrder = lastRow ? lastRow.order + 1 : 0;

        return [
          ...oldRows,
          {
            id: id,
            rnsc: '',
            rncc: '',
            rnvc: '',
            order: newOrder,
            isNew: true,
          },
        ];
      });
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          sx={{ m: 1 }}
          variant="contained"
          startIcon={<Add />}
          onClick={handleClick}
        >
          Add record
        </Button>
      </GridToolbarContainer>
    );
  }

  // Editing Actions ===========================================================
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = rows.find((row) => row.id === id);

    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setConfirmDeleteId(id as number);
    setOpenConfirmDialog(true);
  };

  /**
   * Action for 'Delete' on the 'Confirm' dialog.
   * @param id
   */
  const handleDelete = async (id: number) => {
    deleteSortCriteria(id);
  };

  /**
   * Delete the sort criteria.
   * @param id Id of the row
   */
  async function deleteSortCriteria(id: number) {
    try {
      const response = await NsnApi.deleteSortCriteria(id as number);

      if (response.isSuccess) {
        setRows(response.result);
        setConfirmDeleteId(null);
        setOpenConfirmDialog(false);
        setSnackBar('Row deleted', 'success');
      } else {
        console.error(response.message);
        setSnackBar(response.message);
      }
    } catch (error) {
      console.error(error);
      setSnackBar('An error occurred.');
    }
  }

  /**
   * Send the updated row data to the server
   * @param newRow
   * @param oldRow
   * @returns
   */
  async function processRowUpdate(newRow: GridRowModel, oldRow: GridRowModel) {
    const id = newRow.id;
    try {
      // Create
      if (newRow.isNew) {
        newRow.id = 0;

        const response = await NsnApi.createSortCriteria(getDtoFromGridRowModel(newRow));

        if (response.isSuccess) {
          newRow.id = response.result.id;
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === id ? updatedRow : row)));
          setSnackBar('Row added', 'success');
          return updatedRow;
        } else {
          console.error(response.message);
          setSnackBar(response.message);
        }
        // Update
      } else {
        const response = await NsnApi.updateSortCriteria(getDtoFromGridRowModel(newRow));

        if (response.isSuccess) {
          setSnackBar('Row updated', 'success');
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          return newRow;
        } else {
          console.error(response.message);
          setSnackBar(response.message);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackBar('An error occurred.');
    }
    return oldRow;
  }

  /**
   * Transforms a GridRowModel into a SubstitutePartDto
   * @param row
   * @returns
   */
  function getDtoFromGridRowModel(row: GridRowModel): NsnSearchSortCriteriaDto {
    return {
      id: row.id,
      rnsc: row.rnsc,
      rncc: row.rncc,
      rnvc: row.rnvc,
      order: row.order,
    } as NsnSearchSortCriteriaDto;
  }

  /**
   * Triggers the snackbar
   * @param message
   * @param severity
   */
  function setSnackBar(message: string, severity: string = 'error') {
    setSnackBarState((oldState) => ({
      ...oldState,
      open: true,
      message: message,
      severity: severity,
    }));
  }

  /**
   * Sends a updated row position to the server.
   * @param initialIndex
   * @param newIndex
   * @param rows
   * @returns
   */
  function updateRowPosition(
    initialIndex: number,
    newIndex: number,
    rows: Array<GridRowModel>
  ): Promise<any> {
    return new Promise(async (resolve) => {
      try {
        const response = await NsnApi.updateSortCriteriaOrder(initialIndex, newIndex);
        if (response.isSuccess) {
          resolve(response.result);
        } else {
          resolve(rows);
        }
      } catch (error) {
        setSnackBar('Rows not updated', 'error');
        resolve(rows);
      }
    });
  }

  async function handleRowOrderChange(params: GridRowOrderChangeParams) {
    setIsLoading(true);
    const newRows = await updateRowPosition(params.oldIndex, params.targetIndex, rows);
    setRows(newRows);
    setIsLoading(false);
  }

  // Create column definitions
  const columns: GridColDef[] = [
    { ...createColumn('rncc', 'RNCC'), width: 95, editable: true },
    { ...createColumn('rnvc', 'RNVC'), width: 95, editable: true },
    { ...createColumn('rnsc', 'RNSC'), width: 95, editable: true },
    //{ ...createColumn('order', 'ORDER'), width: 60 }, // Debug
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              onClick={handleSaveClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (canViewPage === true) {
    return (
      <Box className="page-container">
        <h4 className="page-title">Admin Settings</h4>
        <div className="admin-grid" id="sort-order-grid">
          <h5>NSN Search Results Sort Order</h5>{' '}
          <DataGridPro
            loading={isLoading}
            editMode="row"
            onRowEditStop={handleRowEditStop}
            onRowModesModelChange={handleRowModesModelChange}
            rowModesModel={rowModesModel}
            processRowUpdate={processRowUpdate}
            rows={rows}
            columns={columns}
            rowReordering
            onRowOrderChange={handleRowOrderChange}
            slots={{
              toolbar: EditToolbar as GridSlots['toolbar'],
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
          />
          {pageFirstLoad && <LinearProgress sx={{ width: '100%' }} />}
        </div>
        <ValidationSnackbar
          open={snackBarState.open}
          message={snackBarState.message}
          severity={snackBarState.severity}
          onClose={handleSnackBarClose}
        />
        <ConfirmDeleteDialog
          deleteId={confirmDeleteId}
          open={openConfirmDialog}
          onClose={setOpenConfirmDialog}
          onDelete={handleDelete}
        />
      </Box>
    );
  } else {
    return <CannotViewPage />;
  }
}
