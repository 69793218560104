import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardDto } from '../api/types/types';

interface DashboardState {
  dashboardData: DashboardDto | null;
  loading: boolean;
}

const initialState: DashboardState = {
  dashboardData: null,
  loading: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardData(state, action: PayloadAction<DashboardDto>) {
      state.dashboardData = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { setDashboardData, setLoading } = dashboardSlice.actions;
export default dashboardSlice.reducer;
