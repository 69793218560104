import React from 'react';
import { Typography } from '@mui/material';
import './index.scss';
import RiskMeasures from '../../components/risk-measures/RiskMeasures';
import { RoleWithRoleResourcesDto } from '../../api/types/types';
import { useSelector } from 'react-redux';
import { isAdminForApp } from '../../utils/permissions-helpers';
import { AppNames } from '../../api/types/custom-types';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

const RiskMeasuresAdmin = () => {
  const siteRoles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canViewPage = isAdminForApp(siteRoles, AppNames.SCRM, isSuperAdmin);
  if (canViewPage === true) {
    return (
      <div className="admin">
        <Typography className="title">Risk Measures</Typography>
        <RiskMeasures />
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default RiskMeasuresAdmin;
