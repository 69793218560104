import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { ArticleWithKeywordModelsDto } from '../../api/types/types';
import PopupArticle, { Article } from '../risk-discovery/risk-discovery-popup/PopupArticle';

interface ArticlesTableProps {
  articles: ArticleWithKeywordModelsDto[];
}

const mapArticleDtoToArticle = (dto: ArticleWithKeywordModelsDto): Article => {
  const article: Article = {
    id: dto.id,
    title: dto.title,
    pubDate: (dto.pubDate as unknown as string) || undefined,
    sourceDomain: dto.sourceDomain,
    url: dto.url,
    sentimentMetrics: '',
    semanticSummary: dto.semanticSummary,
    content: dto.content,
    imageUrl: dto.imageUrl,
    suppliers: dto.supplierArticles.map((sa) => sa.supplier?.supplierName).join(', '),
    positiveSentiment: dto.positiveSentiment,
    negativeSentiment: dto.negativeSentiment,
    supplierArticles: dto.supplierArticles.map((sa) => ({
      id: sa.supplierId.toString(),
      name: sa.supplier?.supplierName ?? '',
    })),
    articleKeywords: dto.articleKeywords.map((k) => ({
      id: k.articleKeywordId.toString(),
      name: k.name,
    })),
  };

  return article;
};

const SupplierPreviewArticlesTable: React.FC<ArticlesTableProps> = ({ articles }) => {
  const [isPopupVisible, setPopupVisible] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const safeArticles = Array.isArray(articles) ? articles : [];

  const handleViewClick = (index: number) => {
    setCurrentIndex(index);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setCurrentIndex(0);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className="table-container no-padding-shadow">
      <TableContainer>
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Source Domain</TableCell>
              <TableCell>+ Sentiment</TableCell>
              <TableCell>- Sentiment</TableCell>
              <TableCell>Summary Excerpt</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {safeArticles.map((article, index) => (
              <TableRow key={article.id}>
                <TableCell>{truncateText(article.title, 80)}</TableCell>
                <TableCell>{article.sourceDomain}</TableCell>
                <TableCell>{article.positiveSentiment}</TableCell>
                <TableCell>{article.negativeSentiment}</TableCell>
                <TableCell>{truncateText(article.semanticSummary, 80)}</TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    onClick={() => handleViewClick(index)}
                    className="action-button"
                  >
                    VIEW
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPopupVisible && currentIndex !== null && (
        <PopupArticle
          onClose={closePopup}
          articles={safeArticles.map(mapArticleDtoToArticle)}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      )}
    </div>
  );
};

export default SupplierPreviewArticlesTable;
