import Dashboard from '../../components/scrm/dashboard/Dashboard';
import React from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { RoleWithRoleResourcesDto } from '../../api/types/types';
import { AppNames, AppResourceNames, PermissionsType } from '../../api/types/custom-types';
import { doesHavePermission } from '../../utils/permissions-helpers';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

const DashboardPage = () => {
  const roles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canView = doesHavePermission(
    roles,
    AppNames.SCRM,
    AppResourceNames.Dashboard,
    isSuperAdmin,
    PermissionsType.View
  );

  if (canView === true) {
    return (
      <div className="dashboard-page">
        <Dashboard />
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default DashboardPage;
