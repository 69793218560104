import { Typography } from '@mui/material';
import TableParts from '../../components/dmsms/parts/TableParts';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RoleWithRoleResourcesDto } from '../../api/types/types';
import { doesHavePermission } from '../../utils/permissions-helpers';
import { AppNames, AppResourceNames, PermissionsType } from '../../api/types/custom-types';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

const PartsPage = () => {
  const roles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canViewPage = doesHavePermission(
    roles,
    AppNames.NsnQuery,
    AppResourceNames.Search,
    isSuperAdmin,
    PermissionsType.View
  );

  if (canViewPage === true) {
    return (
      <div className="page-container">
        <Typography variant="h4" className="page-title">
          Parts
        </Typography>

        <TableParts />
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default PartsPage;
