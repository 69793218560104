// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto } from '../types/types';
const { DMSMS_SERVICE_URL } = config;

const GidepUpload = {
  /**
   * Uploads a GIDEP fiel for processing
   * @param {File} file the Spreadsheet obtained from GIDEP.
   * @returns ID of the batch request
   */
  uploadGidepFile: async function (file: File): Promise<ResponseDto<number>> {
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }

    const { data } = await httpClient.post(`${DMSMS_SERVICE_URL}/GidepUpload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data as ResponseDto<number>;
  },
};
export default GidepUpload;
