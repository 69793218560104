import React, { useEffect } from 'react';
import {
  Backdrop,
  Box,
  TextField,
  Paper,
  Typography,
  Grid,
  Button,
  Tab,
  Tabs,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  AlertColor,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import {
  DnBEntityResolutionDto,
  ResearchNotesVmDto,
  SelectBoxOptionDto,
  StringKeySelectBoxOptionDto,
  SupplierPreviewViewModelDto,
} from '../../api/types/types';
import CustomSnackbar from '../../components/custom-snackbar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// @ts-ignore
import DnbAPI from '../../api/dnb/dnbAPI';
// @ts-ignore
import NsnApi from '../../api/nsn/nsnAPI';
// @ts-ignore
import SuppliersApi from '../../api/suppliers/suppliersAPI';

import SupplierPreviewApi from '../../api/supplierPreview/SupplierPreview';
import Popup from 'devextreme-react/cjs/popup';
import EntityResolutionDialog from './EntityResolutionDialog';
import ResearchNotesForm from './ResearchNotesForm';
import { FormStatus } from '../risk-case/models/FormStatus';
import { RiskModelsTab } from './search-result-tabs/RiskModelsTab';
// @ts-ignore
import { addSuppliers } from '../../slices/suppliersSlice';

// @ts-ignore
import DBTab from '../../components/supplier-tabs/d-b';
import { useDispatch, useSelector } from 'react-redux';

// @ts-ignore
import { setSupplierPreviewId, setSupplierPreviewDuns } from '../../slices/suppliersSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

interface DunsValidationData {
  isError: boolean;
  companyName: string | null;
  duns: string;
}

export const SupplierPreviewDetail: React.FC = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [preview, setPreview] = React.useState<SupplierPreviewViewModelDto | null>(null);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: 'success' as AlertColor,
    autoHide: true,
  });
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [dunsValidationData, setDunsValidationData] = React.useState<DunsValidationData | null>(
    null
  );
  const [cageCodeValidated, setCageCodeValidated] = React.useState(false);
  const [countriesDropdown, setCountriesDropdown] = React.useState<StringKeySelectBoxOptionDto[]>(
    []
  );
  const [availableRiskModels, setAvailableRiskModels] = React.useState<SelectBoxOptionDto[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [supplierToScrm, setSupplierToScrm] = React.useState<SupplierPreviewViewModelDto>();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [entityResolutionData, setEntityResolutionData] =
    React.useState<DnBEntityResolutionDto | null>(null);

  const [searchResultstabValue, setsearchResultstabValue] = React.useState(0);
  const handleSearchResultstabValueTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setsearchResultstabValue(newValue);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { id } = useParams();
  const isNewPreview = window.location.pathname.includes('new-preview');

  const navigate = useNavigate();
  const showOnlyActiveSuppliers = useSelector((state: any) => state.suppliers.showOnlyActive);

  const previewIsSaved =
    preview?.companyInformation.supplierId !== undefined &&
    preview?.companyInformation.supplierId > 0;

  useEffect(() => {
    fetchCountriesDropdown();
    fetchAvailableRiskModels();
    if (!isNewPreview && Boolean(id)) {
      fetchData();
    } else {
      // Initialize empty preview for new entry
      setPreview({
        previewId: 0,
        searchResults: {
          articlesCount: 0,
          articles: [],
        },
        keywordModelIdsToRun: [],
        previewStatus: '',
        dateAdded: '',
        lastActivity: '',
        companyInformation: {
          supplierId: 0,
          supplierName: '',
          duns: '',
          cageCode: '',
          cageStatus: '',
          previewer: { name: '', key: '' },
        },
        otherCompanyInformation: {
          locations: [
            {
              id: 0,
              street: '',
              city: '',
              state: '',
              countryCode: '',
              postalColde: '',
            },
          ],
          aliases: [],
        },
        researchNotes: {
          researchNotes: [],
        },
      });
    }
  }, [id]);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await SupplierPreviewApi.getPreviewById(Number(id));
      setPreview(response);
      dispatch(setSupplierPreviewId(response.companyInformation.supplierId));
      dispatch(setSupplierPreviewDuns(response.companyInformation.duns));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCountriesDropdown = async () => {
    try {
      const data = await SupplierPreviewApi.getIso2CountriesDropdown();
      setCountriesDropdown(data);
    } catch (error) {
      console.error('Error fetching countries dropdown:', error);
    }
  };

  const dispatch = useDispatch();

  const fetchAvailableRiskModels = async () => {
    try {
      const data = await SupplierPreviewApi.getKetKeywordModelsDropdown();
      setAvailableRiskModels(data);
    } catch (error) {
      console.error('Error fetching risk models:', error);
    }
  };

  useEffect(() => {
    if (window.location.pathname.match(/\/scrm\/suppliers\/preview\/\d+/)) {
      fetchData();
      fetchCountriesDropdown();
    }
  }, []);

  const handleSave = async () => {
    if (!preview?.companyInformation?.supplierName?.trim()) {
      setSnackbar({
        open: true,
        message: 'Company Name is required',
        severity: 'error',
        autoHide: true,
      });
      return;
    }

    if (!preview?.otherCompanyInformation?.locations[0]?.countryCode?.trim()) {
      setSnackbar({
        open: true,
        message: 'Country Code is required',
        severity: 'error',
        autoHide: true,
      });
      return;
    }
    if (!preview?.companyInformation?.cageCode?.trim()) {
      setSnackbar({
        open: true,
        message: 'CAGE Code is required',
        severity: 'error',
        autoHide: true,
      });
      return;
    }

    try {
      if (!preview) return;

      const requestBody = {
        ...preview,
        dateAdded: preview.dateAdded
          ? new Date(preview.dateAdded).toISOString()
          : new Date().toISOString(),
        lastActivity: preview.lastActivity
          ? new Date(preview.lastActivity).toISOString()
          : new Date().toISOString(),
        previewStatus: preview.previewStatus ?? '',
        companyInformation: {
          ...preview.companyInformation,
          duns: preview.companyInformation.duns ?? '',
          cageCode: preview.companyInformation.cageCode ?? '',
          cageStatus: preview.companyInformation.cageStatus ?? '',
          supplierName: preview.companyInformation.supplierName ?? '',
        },
      };

      setIsSaving(true);
      const response = await SupplierPreviewApi.updatePreview(requestBody);

      if (response && typeof response === 'object') {
        setSupplierToScrm(response);
        setPreview(response);
        setSnackbar({
          open: true,
          message: 'Data saved successfully',
          severity: 'success',
          autoHide: true,
        });
        dispatch(setSupplierPreviewId(response.companyInformation.supplierId));
        dispatch(setSupplierPreviewDuns(response.companyInformation.duns));
        navigate(`/scrm/suppliers/preview/${response.previewId}`);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error saving data:', error);
      setSnackbar({
        open: true,
        message: error instanceof Error ? error.message : 'Error saving data',
        severity: 'error',
        autoHide: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handlePreviewUpdate = (field: string, value: string) => {
    if (!preview) return;

    setPreview({
      ...preview,
      companyInformation: {
        ...preview.companyInformation,
        [field]: value,
      },
    });
  };

  const handleLocationUpdate = (field: string, value: string) => {
    if (!preview) return;

    const updatedLocations = [...preview.otherCompanyInformation.locations];
    updatedLocations[0] = {
      ...updatedLocations[0],
      [field]: value,
    };

    setPreview({
      ...preview,
      otherCompanyInformation: {
        ...preview.otherCompanyInformation,
        locations: updatedLocations,
      },
    });
  };

  const displayDunsValidationMessage = async () => {
    if (!preview?.companyInformation.duns) return;
    setPopupVisible(true);
    try {
      setDunsValidationData(null);
      const data = await DnbAPI.getCompanyNameByDuns(preview.companyInformation.duns);
      setDunsValidationData({
        isError: false,
        companyName: data.companyName,
        duns: preview.companyInformation.duns,
      });
    } catch (error) {
      console.error('Error validating D-U-N-S:', error);
      setDunsValidationData({
        isError: true,
        companyName: null,
        duns: preview.companyInformation.duns,
      });
    }
  };

  const validateCageCode = async () => {
    if (!preview?.companyInformation.cageCode) {
      setSnackbar({
        open: true,
        message: 'Please enter a CAGE Code',
        severity: 'error',
        autoHide: true,
      });
      return;
    }

    if (preview.companyInformation.cageCode.length !== 5) {
      setSnackbar({
        open: true,
        message: 'CAGE Code must be 5 characters',
        severity: 'error',
        autoHide: true,
      });
      setCageCodeValidated(false);
      return;
    }

    try {
      const response = await NsnApi.getCageCode(Number(preview.companyInformation.cageCode));

      if (response.result.exists === true) {
        handlePreviewUpdate('cageStatus', 'active');
        setSnackbar({
          open: true,
          message: `CAGE Code is valid - Status: ${response.result.status}`,
          severity: 'success',
          autoHide: true,
        });
        setCageCodeValidated(true);
      } else {
        handlePreviewUpdate('cageStatus', 'inactive');
        setSnackbar({
          open: true,
          message: 'CAGE Code is invalid',
          severity: 'error',
          autoHide: true,
        });
        setCageCodeValidated(false);
      }
    } catch (error) {
      console.error('Error validating CAGE Code:', error);
      setCageCodeValidated(false);
    }
  };

  const handleValidateSupplierData = async () => {
    try {
      setIsSaving(true);

      const data = await SupplierPreviewApi.getEntityResolution({
        name: preview?.companyInformation.supplierName || null,
        streetAddressLine1: preview?.otherCompanyInformation.locations[0]?.street || null,
        countryISOAlpha2Code: preview?.otherCompanyInformation.locations[0]?.countryCode || null,
        addressRegion: preview?.otherCompanyInformation.locations[0]?.state || null,
        addressLocality: preview?.otherCompanyInformation.locations[0]?.city || null,
        postalCode: preview?.otherCompanyInformation.locations[0]?.postalColde || null,
      });
      setSnackbar({
        open: true,
        message: 'Supplier data validated successfully',
        severity: 'success',
        autoHide: true,
      });
      setEntityResolutionData(data);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error validating supplier data:', error);
      setSnackbar({
        open: true,
        message: 'Error validating supplier data',
        severity: 'error',
        autoHide: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleInsertSupplierToScrm = async () => {
    if (!supplierToScrm || !supplierToScrm.companyInformation.previewer) return;

    try {
      setIsSaving(true);

      const formattedData: SupplierPreviewViewModelDto = {
        ...supplierToScrm,
        dateAdded: supplierToScrm.dateAdded
          ? new Date(supplierToScrm.dateAdded).toISOString()
          : null,
        lastActivity: supplierToScrm.lastActivity
          ? new Date(supplierToScrm.lastActivity).toISOString()
          : null,
        previewStatus: supplierToScrm.previewStatus ?? '',
        companyInformation: {
          ...supplierToScrm.companyInformation,
          duns: supplierToScrm.companyInformation.duns ?? null,
          cageCode: supplierToScrm.companyInformation.cageCode ?? null,
          cageStatus: supplierToScrm.companyInformation.cageStatus ?? null,
          supplierName: supplierToScrm.companyInformation.supplierName,
          previewer: supplierToScrm.companyInformation.previewer ?? null,
        },
      };

      await SupplierPreviewApi.insertSupplierToScrm(formattedData);
      setSnackbar({
        open: true,
        message: 'Supplier inserted to SCRM successfully',
        severity: 'success',
        autoHide: true,
      });

      const data = await SuppliersApi.getAllSuppliers(showOnlyActiveSuppliers);
      dispatch(addSuppliers({ allSuppliers: data }));

      setTimeout(() => {
        navigate(`/scrm/suppliers/preview/`);
      }, 3000);
    } catch (error) {
      console.error('Error inserting supplier to SCRM:', error);
      setSnackbar({
        open: true,
        message: error instanceof Error ? error.message : 'Error inserting supplier to SCRM',
        severity: 'error',
        autoHide: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleUseData = () => {
    if (!preview || !entityResolutionData) return;

    setPreview((prev) => {
      if (!prev) return null;

      // Ensure the locations array is updated
      const updatedLocations =
        prev.otherCompanyInformation.locations.length === 0
          ? [
              {
                id: 0,
                street: entityResolutionData.streetAddressLine1,
                city: entityResolutionData.addressLocality,
                state: entityResolutionData.addressRegion,
                countryCode: entityResolutionData.countryISOAlpha2Code,
                postalColde: entityResolutionData.postalCode,
              },
            ]
          : prev.otherCompanyInformation.locations.map((location, index) =>
              index === 0
                ? {
                    ...location,
                    street: entityResolutionData.streetAddressLine1,
                    city: entityResolutionData.addressLocality,
                    state: entityResolutionData.addressRegion,
                    countryCode: entityResolutionData.countryISOAlpha2Code,
                    postalColde: entityResolutionData.postalCode,
                  }
                : location
            );

      return {
        ...prev,
        otherCompanyInformation: {
          ...prev.otherCompanyInformation,
          locations: updatedLocations,
        },
        companyInformation: {
          ...prev.companyInformation,
          duns: entityResolutionData.dunsNumber,
        },
      };
    });

    setDialogOpen(false);
  };

  const handleResearchNotesChange = (data: ResearchNotesVmDto, status: FormStatus) => {
    if (!preview || !status.isValid) return;

    setPreview({
      ...preview,
      researchNotes: data,
    });
  };

  const handleRunPerigonSeachEvent = async (selectedItems: SelectBoxOptionDto[]) => {
    if (!preview || selectedItems.length === 0) {
      setSnackbar({
        open: true,
        message: 'One or more Risk Models must be selected to run the models.',
        severity: 'error',
        autoHide: true,
      });
      return;
    }

    let newPreviewValue: SupplierPreviewViewModelDto = {
      ...preview,
      keywordModelIdsToRun: selectedItems.map((si) => si.id),
    };

    setPreview(newPreviewValue);
    try {
      setIsSaving(true);

      await SupplierPreviewApi.savePreviewAndRunPerigonSearch(newPreviewValue);
      setSnackbar({
        open: true,
        message:
          'The selected models are now running in the background. An email notification will be sent to the previewer upon completion.',
        severity: 'success',
        autoHide: false,
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          error instanceof Error ? error.message : 'An error occurred while running risk models.',
        severity: 'error',
        autoHide: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Paper sx={{ p: '40px' }}>
      <Typography variant="h4" gutterBottom>
        Supplier Preview
      </Typography>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Company Name"
                variant="outlined"
                required
                margin="normal"
                value={preview?.companyInformation.supplierName || ''}
                onChange={(e) => handlePreviewUpdate('supplierName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Previewer"
                variant="outlined"
                disabled
                margin="normal"
                value={preview?.companyInformation?.previewer?.name || ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="D-U-N-S"
                variant="outlined"
                margin="normal"
                value={preview?.companyInformation.duns || ''}
                onChange={(e) => handlePreviewUpdate('duns', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={displayDunsValidationMessage}>
                        <CheckCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="CAGE"
                variant="outlined"
                margin="normal"
                value={preview?.companyInformation.cageCode || ''}
                onChange={(e) =>
                  handlePreviewUpdate('cageCode', e.target.value.toUpperCase().slice(0, 5))
                }
                onBlur={validateCageCode}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={validateCageCode}>
                        <CheckCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor:
                      preview?.companyInformation.cageStatus === 'active'
                        ? '#66BB6A'
                        : preview?.companyInformation.cageStatus === 'inactive'
                          ? '#ef9a9a'
                          : 'inherit',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Street Address"
                variant="outlined"
                margin="normal"
                value={preview?.otherCompanyInformation.locations[0]?.street || ''}
                onChange={(e) => handleLocationUpdate('street', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="City / Locality"
                variant="outlined"
                margin="normal"
                value={preview?.otherCompanyInformation.locations[0]?.city || ''}
                onChange={(e) => handleLocationUpdate('city', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                label="State / Region"
                variant="outlined"
                margin="normal"
                value={preview?.otherCompanyInformation.locations[0]?.state || ''}
                onChange={(e) => handleLocationUpdate('state', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Country Code</InputLabel>
                <Select
                  label="Country Code"
                  value={
                    preview?.otherCompanyInformation.locations[0]?.countryCode?.toUpperCase() || ''
                  }
                  onChange={(e) =>
                    handleLocationUpdate('countryCode', e.target.value.toUpperCase())
                  }
                  renderValue={(selected) => selected}
                >
                  {countriesDropdown.map((country) => (
                    <MenuItem key={country.id} value={country.id.toUpperCase()}>
                      {`${country.id.toUpperCase()} - ${country.label}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                label="Postal Code"
                variant="outlined"
                margin="normal"
                value={preview?.otherCompanyInformation.locations[0]?.postalColde || ''}
                onChange={(e) => handleLocationUpdate('postalColde', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Other Company Information
              </Typography>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Names / Aliases" />
                    <Tab label="Locations" />
                  </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                  {preview?.otherCompanyInformation.aliases.map((alias, index) => (
                    <Typography key={index}>{alias}</Typography>
                  ))}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  {preview?.otherCompanyInformation.locations.map((location, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Typography>{`${location.street}, ${location.city}, ${location.state} ${location.postalColde}`}</Typography>
                    </Box>
                  ))}
                </TabPanel>
              </Paper>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Research Notes
              </Typography>
              <ResearchNotesForm
                initialData={preview?.researchNotes ?? null}
                isLoading={false}
                onFormUpdate={handleResearchNotesChange}
              ></ResearchNotesForm>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save
                </Button>
                <Button variant="contained" onClick={handleValidateSupplierData}>
                  Validate Supplier Data
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  variant="contained"
                  onClick={handleInsertSupplierToScrm}
                  disabled={!supplierToScrm}
                >
                  Insert Supplier TO SCRM
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Search Results
            </Typography>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={searchResultstabValue} onChange={handleSearchResultstabValueTabChange}>
                  <Tab label="Dun & Bradstreet" />
                  <Tab label="Risk Models" />
                </Tabs>
              </Box>
              <TabPanel value={searchResultstabValue} index={0}>
                {!previewIsSaved ? <></> : <DBTab />}
              </TabPanel>
              <TabPanel value={searchResultstabValue} index={1}>
                {!previewIsSaved ? (
                  <></>
                ) : (
                  <RiskModelsTab
                    enabled={previewIsSaved}
                    availableRiskModels={availableRiskModels}
                    searchResults={preview?.searchResults}
                    onSaveAndRunPerigonSearch={handleRunPerigonSeachEvent}
                  ></RiskModelsTab>
                )}
              </TabPanel>
            </Paper>
          </Grid>

          <CustomSnackbar
            open={snackbar.open}
            message={snackbar.message}
            severity={snackbar.severity}
            autoHide={snackbar.autoHide}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          />
          <Popup
            visible={popupVisible}
            onHiding={() => setPopupVisible(false)}
            dragEnabled={true}
            hideOnOutsideClick={true}
            showCloseButton={true}
            title="D-U-N-S Validation Result"
            width={400}
            height={200}
          >
            <div>
              {dunsValidationData === null ? (
                <p style={{ margin: '20px' }}>Validating...</p>
              ) : dunsValidationData?.isError ? (
                <p style={{ margin: '20px' }}>Failed to validate D-U-N-S.</p>
              ) : dunsValidationData?.companyName === null ? (
                <p style={{ margin: '20px' }}>
                  Unable to find <strong>{dunsValidationData?.duns}</strong>.
                </p>
              ) : (
                <p style={{ margin: '20px' }}>
                  Found <strong>{dunsValidationData?.companyName}</strong>.
                </p>
              )}
            </div>
          </Popup>
        </>
      )}

      <EntityResolutionDialog
        dialogOpen={dialogOpen}
        entityResolutionData={entityResolutionData}
        handleCloseDialog={handleCloseDialog}
        handleUseData={handleUseData}
      />

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSaving}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

export default SupplierPreviewDetail;
