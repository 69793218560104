// @ts-ignore
import DnbAPI from '../../../api/dnb/dnbAPI';
// @ts-ignore
import Filings from '../../supplier-tabs/d-b/filings';

import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, Tooltip, CircularProgress } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {
  CompanyInformationDTO,
  DnBAssessmentDto,
  DnBCompanyFinancialDto,
} from '../../../api/types/types';
import SparklineChart from './Sparkline';

interface DnBTabProps {
  supplierId: number;
  duns: string;
}

const formatDate = (dateString: string | null) =>
  dateString ? new Date(dateString).toLocaleDateString('en-US') : '';

const DnBTab: React.FC<DnBTabProps> = ({ supplierId, duns }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyInformation, setCompanyInformation] = useState<CompanyInformationDTO>();
  const [riskAssessment, setRiskAssessment] = useState<DnBAssessmentDto>();
  const [financialData, setFinancialData] = useState<DnBCompanyFinancialDto>();

  const fetchData = async () => {
    setLoading(true);
    try {
      const [companyInformationResponse, riskAssessmentResponse, financialDataResponse] =
        await Promise.all([
          DnbAPI.getCompanyInformationByDuns(duns),
          DnbAPI.getAssessmentDataById(supplierId, true),
          DnbAPI.getCompanyFinancialsById(supplierId),
        ]);

      setCompanyInformation(companyInformationResponse);
      setRiskAssessment(riskAssessmentResponse);
      setFinancialData(financialDataResponse);
    } catch (e: any) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Typography variant="body1" gutterBottom>
              Company Summary
            </Typography>
            <TextField
              value={companyInformation?.summary}
              InputProps={{ readOnly: true }}
              fullWidth
              multiline
            />
          </Grid>

          <Grid item xs={3}>
            <Typography variant="body1" gutterBottom>
              Last Updated
            </Typography>
            <TextField
              value={
                companyInformation?.lastUpdated
                  ? formatDate(companyInformation?.lastUpdated.toString())
                  : ''
              }
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs={9}>
            <Typography variant="body1" gutterBottom>
              Commentary
            </Typography>
            <TextField
              value={riskAssessment?.overallCommentary}
              InputProps={{ readOnly: true }}
              fullWidth
              multiline
            />
          </Grid>

          <Grid item xs={3}>
            <Typography variant="body1" gutterBottom>
              Overall Risk Assessment
            </Typography>
            <TextField
              value={riskAssessment?.overallDescription}
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Yearly Revenue
                </Typography>
                <TextField
                  value={
                    companyInformation?.yearlyRevenue
                      ? `$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(companyInformation.yearlyRevenue)}`
                      : ''
                  }
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Employee Count
                </Typography>
                <TextField
                  value={
                    companyInformation?.numberOfEmployees
                      ? new Intl.NumberFormat('en-US').format(companyInformation.numberOfEmployees)
                      : ''
                  }
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="body1" gutterBottom>
                    Viability Class Score
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title="The score that predicts the likelihood that the entity will go out of business in the next 12 months compared to all other US entities in the Dun & Bradstreet database. Values: 0 to 9 A score of 0 denotes the entity is either Out Of Business or Unable To Confirm. Scores 1 to 9 reflect the probability that the entity will go out of business; 1 being the lowest probability and 9 the highest.">
                    <HelpIcon color="primary" fontSize="small" />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={riskAssessment?.viabilityClassScore}
                    InputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="body1" gutterBottom>
                    Failure Class Score
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title="A value that succinctly expresses the likelihood of risk by specifying in which risk group the entity belongs. In other words, it specifies where this entity falls in a statistical distribution of all entities (e.g., a bell curve, a half-normal distribution). Values: 0 to 9.">
                    <HelpIcon color="primary" fontSize="small" />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={
                      Number.isFinite(riskAssessment?.failureScoreClassScore?.[0])
                        ? riskAssessment?.failureScoreClassScore?.[0]
                        : null
                    }
                    InputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="body1" gutterBottom>
                    National Percentile
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title="There are 10 possible values that correspond with Failure Score National Percentile. A small value indicates that most other entities have a better Failure Score than the selected entity. As the value increases, this indicates that the entity is performing better vis-a-vis other entities.">
                    <HelpIcon color="primary" fontSize="small" />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={
                      riskAssessment?.failureScoreNationalPercentile
                        ? Math.round(riskAssessment?.failureScoreNationalPercentile / 10)
                        : null
                    }
                    InputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SparklineChart
                  xAxisData={
                    financialData?.dnBHistoricFinancials.map((h) => h.toDate?.toString() || '') ||
                    []
                  }
                  seriesData={
                    financialData?.dnBHistoricFinancials.map((h) => h.tangibleNetWorth || 0) || []
                  }
                  title="Tangible Net Worth"
                />
              </Grid>
              <Grid item xs={6}>
                <SparklineChart
                  xAxisData={
                    financialData?.dnBHistoricFinancials.map((h) => h.toDate?.toString() || '') ||
                    []
                  }
                  seriesData={
                    financialData?.dnBHistoricFinancials.map((h) => h.workingCapital || 0) || []
                  }
                  title="Working Capital"
                />
              </Grid>
              <Grid item xs={6}>
                <SparklineChart
                  xAxisData={
                    financialData?.dnBHistoricFinancials.map((h) => h.toDate?.toString() || '') ||
                    []
                  }
                  seriesData={
                    financialData?.dnBHistoricFinancials.map((h) => h.currentRatio || 0) || []
                  }
                  title="Current Ratio"
                />
              </Grid>
              <Grid item xs={6}>
                <SparklineChart
                  xAxisData={
                    financialData?.dnBHistoricFinancials.map((h) => h.toDate?.toString() || '') ||
                    []
                  }
                  seriesData={
                    financialData?.dnBHistoricFinancials.map((h) => h.quickRatio || 0) || []
                  }
                  title="Quick Ratio"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" pb={1}>
              Filings
            </Typography>
            <Filings supplierId={supplierId} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DnBTab;
