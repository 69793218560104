import React, { useEffect } from 'react';
import KeyMetrics from './KeyMetrics';
import SupplierHealth from './SupplierHealth';
import Dmsms from './Dmsms';
import RiskCasesChart from './RiskCasesChart';
import RiskSourcesPieCharts from './RiskSourcesPieCharts';
import RiskCaseAging from './RiskCaseAging/RiskCaseAging';
import HitsAgings from './hitsAgings/HitsAgings';
import DnBAssessmentScatterChart from './DnBAssessmentScatterChart';
import DashboardApi from '../../../api/dashboard/dashboardApi';
import { useDispatch } from 'react-redux';
import { setDashboardData, setLoading } from '../../../slices/dashboardSlice';

const Dashboard = () => {
  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      const response = await DashboardApi.getDashboardData();
      dispatch(setDashboardData(response));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <KeyMetrics />
      <SupplierHealth />
      <RiskCasesChart />
      <RiskSourcesPieCharts />
      <DnBAssessmentScatterChart />
      <Dmsms />
      <HitsAgings />
      <RiskCaseAging />
    </>
  );
};

export default Dashboard;
