// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';

import {
  AppDto,
  AppResourceDto,
  AuthDto,
  CreateRoleDto,
  ResponseDto,
  RoleDto,
  RoleResourceDto,
  UserDto,
} from '../types/types';
import { UsersAdminDto } from '../types/custom-types';

const { ALTRUITY_API_URL } = config;

interface BodyUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  createdById: number;
  updatedById: number;
}

const UserAdmin = {
  getAllUsers: async (): Promise<UsersAdminDto[]> => {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/UserAdmin/User`);
    return data.result;
  },
  getAllUsersByRole: async (roleId: number): Promise<UserDto[]> => {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/UserAdmin/UsersByRole/${roleId}`);
    return data.result;
  },
  getAllApp: async (): Promise<AppDto[]> => {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/UserAdmin/App`);
    return data.result;
  },
  getAllAppResourcesByAppId: async (id: number): Promise<AppResourceDto[]> => {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/UserAdmin/AppResource/App/${id}`);
    return data.result;
  },
  getAllRoleResourcesByAppId: async (id: number): Promise<RoleResourceDto[]> => {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/UserAdmin/RoleResource/App/${id}`);
    return data.result;
  },
  getRoleAppById: async (id: number): Promise<RoleDto[]> => {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/UserAdmin/Role/App/${id}`);
    return data.result;
  },
  getRoleUserById: async (id: number): Promise<RoleDto[]> => {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/UserAdmin/Role/User/${id}`);
    return data.result;
  },
  putUserById: async (id: number, bodyUser: BodyUser): Promise<UserDto> => {
    const { data } = await httpClient.put(`${ALTRUITY_API_URL}/UserAdmin/User/${id}`, bodyUser);
    return data.result;
  },
  addUser: async (bodyUser: BodyUser): Promise<UserDto> => {
    const { data } = await httpClient.post(`${ALTRUITY_API_URL}/UserAdmin/User`, bodyUser);
    return data.result;
  },
  addUserRole: async (userId: number, roleId: number): Promise<RoleDto[]> => {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/UserAdmin/${userId}/Roles/${roleId}`
    );
    return data.result;
  },
  addSingleUserToRole: async (userId: number, roleId: number): Promise<ResponseDto<UserDto>> => {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/UserAdmin/User/${userId}/Roles/${roleId}`
    );
    return data;
  },
  addRole: async (bodyRole: CreateRoleDto): Promise<ResponseDto<RoleDto>> => {
    const { data } = await httpClient.post(`${ALTRUITY_API_URL}/UserAdmin/Role`, bodyRole);
    return data;
  },
  updateRole: async (bodyRole: CreateRoleDto): Promise<ResponseDto<RoleDto>> => {
    const { data } = await httpClient.put(`${ALTRUITY_API_URL}/UserAdmin/Role`, bodyRole);
    return data;
  },
  setRoleIsAdmin: async (roleId: number, isAdmin: boolean): Promise<ResponseDto<RoleDto>> => {
    const { data } = await httpClient.patch(
      `${ALTRUITY_API_URL}/UserAdmin/Role/SetAdmin/${roleId}/${isAdmin}`
    );
    return data as ResponseDto<RoleDto>;
  },
  updateRoleResources: async (
    roleId: number,
    updatedRoleResources: RoleResourceDto[]
  ): Promise<ResponseDto<RoleResourceDto[]>> => {
    const { data } = await httpClient.patch(
      `${ALTRUITY_API_URL}/UserAdmin/RoleResource/Role/${roleId}`,
      updatedRoleResources
    );
    return data;
  },
  getUserNotInSystem: async (): Promise<UsersAdminDto[]> => {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/UserAdmin/User/NotInSystem`);
    return data;
  },
  putUserIdRoles: async (userId: number, roles: number[]): Promise<ResponseDto<RoleDto>> => {
    const { data } = await httpClient.put(
      `${ALTRUITY_API_URL}/UserAdmin/User/${userId}/Roles`,
      roles
    );
    return data.result;
  },
  removeUserFromRole: async (userId: number, roleId: number): Promise<ResponseDto<any>> => {
    const { data } = await httpClient.delete(
      `${ALTRUITY_API_URL}/UserAdmin/User/${userId}/Roles/${roleId}`
    );
    return data;
  },
  getToken: async (email: string): Promise<ResponseDto<AuthDto>> => {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/UserAdmin/Token/${email}`);
    return data as ResponseDto<AuthDto>;
  },
  makeSuperAdmin: async (id: number, isSuperAdmin: boolean): Promise<ResponseDto<UserDto>> => {
    const { data } = await httpClient.patch(
      `${ALTRUITY_API_URL}/UserAdmin/${id}/MakeSuperAdmin/${isSuperAdmin}`
    );
    return data as ResponseDto<UserDto>;
  },
};

export default UserAdmin;
