import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import SuppliersAPI from '../../../../api/suppliers/suppliersAPI';
import { createColumn } from '../../../../utils/data-grid';
import DataGrid from '../../../data-grid/DataGrid';
import { useSelector } from 'react-redux';

const Locations = ({ canAddOrEdit }) => {
  const { id: supplierId } = useParams();
  const [data, setData] = useState();

  const columns = [
    createColumn('streetAddress1', 'Street Address 1'),
    createColumn('streetAddress2', 'Street Address 2'),
    createColumn('city', 'City'),
    createColumn('state', 'State'),
    createColumn('region', 'Region'),
    createColumn('postalCode', 'Postal Code'),
    createColumn('country', 'Country'),
    createColumn('latitude', 'Latitude', 'number'),
    createColumn('longitude', 'Longitude', 'number'),
  ];

  const loadData = async () => setData(await SuppliersAPI.getAllSupplierLocations(supplierId));

  const createData = async (data) =>
    await SuppliersAPI.addSupplierLocation({ supplierId, ...data });

  const deleteData = async (id) => await SuppliersAPI.deleteSupplierLocation(id);

  const updateData = async (id, data) =>
    await SuppliersAPI.updateSupplierLocation({
      id,
      supplierId,
      ...data,
    });

  return (
    <DataGrid
      data={data}
      columns={columns}
      loadData={loadData}
      createData={createData}
      deleteData={deleteData}
      updateData={updateData}
      isCreatable={canAddOrEdit === true}
      isEditable={canAddOrEdit === true}
    />
  );
};

export default Locations;
