import React from 'react';
import { Typography } from '@mui/material';
import './SuppliersDatagrid.scss';
import SuppliersDatagridComponent from '../../components/scrm/suppliers/SuppliersDatagrid';

const SuppliersDatagrid = () => {
  return (
    <div className="suppliers-datagrid">
      <Typography className="title">All Suppliers</Typography>
      <SuppliersDatagridComponent />
    </div>
  );
};

export default SuppliersDatagrid;
