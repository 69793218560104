import React from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format, addMonths, parse } from 'date-fns';
import { createColumn } from '../../../utils/data-grid';
import { SvraForecastDto } from '../../../api/types/types';

const NsnDetailDibbsProjections: React.FC<{ data: SvraForecastDto }> = ({ data }) => {
  if (!data?.data_date) {
    return (
      <Box className="nsn-detail-grid">
        <h5>SVRA Forecast</h5>
        <DataGrid
          autoHeight
          rows={[]}
          columns={[]}
          disableRowSelectionOnClick
          hideFooter
          slots={{
            noRowsOverlay: () => (
              <Box display="flex" alignItems="center" justifyContent="center">
                No data available
              </Box>
            ),
          }}
        />
      </Box>
    );
  }

  let startDate: Date;
  try {
    startDate = parse(data.data_date, 'M/d/yyyy', new Date());
    if (isNaN(startDate.getTime())) {
      throw new Error('Invalid date');
    }
  } catch (error) {
    console.error('Invalid date format:', data.data_date);
    return (
      <Box className="nsn-detail-grid">
        <h5>SVRA Forecast</h5>
        <DataGrid
          autoHeight
          rows={[]}
          columns={[]}
          disableRowSelectionOnClick
          hideFooter
          slots={{
            noRowsOverlay: () => (
              <Box display="flex" alignItems="center" justifyContent="center">
                No data available
              </Box>
            ),
          }}
        />
      </Box>
    );
  }

  const columns = [
    ...Array.from({ length: 25 }, (_, index) => {
      const date = addMonths(startDate, index);
      const headerName = format(date, 'MMM-yy');
      return {
        ...createColumn(`month${index + 1}`, headerName),
        minWidth: 80,
        flex: 1,
        type: 'number' as const,
      };
    }),
    {
      ...createColumn('total', 'Total'),
      minWidth: 80,
      flex: 1,
      type: 'number' as const,
    },
  ];

  const rows = [
    {
      id: 1,
      ...Object.fromEntries(
        Array.from({ length: 25 }, (_, index) => [
          `month${index + 1}`,
          data[`month${index + 1}` as keyof SvraForecastDto],
        ])
      ),
      total: data.total,
    },
  ];

  return (
    <Box className="nsn-detail-grid">
      <h5> DIBBS Projections</h5>
      <DataGrid
        autoHeight
        getRowId={(row) => row.id}
        density="compact"
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        hideFooter
      />
    </Box>
  );
};

export default NsnDetailDibbsProjections;
