import React, { useEffect, useState } from 'react';
import { DashboardDto, DashboardKeyMetricsViewModelDto } from '../../../api/types/types';
import KeyMetricsUI from './KeyMetricsUI';
import { useSelector } from 'react-redux';

const KeyMetrics = () => {
  const [metrics, setMetrics] = useState<DashboardKeyMetricsViewModelDto | null>(null);
  const dashboardData: DashboardDto = useSelector((state: any) => state.dashboard.dashboardData);

  useEffect(() => {
    if (dashboardData === null) return;
    setMetrics(dashboardData.keyMetrics);
  }, [dashboardData]);

  return <KeyMetricsUI metrics={metrics}></KeyMetricsUI>;
};

export default KeyMetrics;
