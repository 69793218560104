import { configureStore } from '@reduxjs/toolkit';
import suppliersSlice from '../slices/suppliersSlice';
import authSlice from '../slices/authSlice';
import discoverySlice from '../slices/discoverySlice';
import hitsSlice from '../slices/hitsSlice';
import riskCasesSlice from '../slices/riskCasesSlice';
import permissionsSlice from '../slices/permissionsSlice';
import keywordModelSlice from '../slices/keywordModelSlice';
import siteAdminSlice from '../slices/siteAdminSlice';
import themeSlice from '../slices/themeSlice';
import dmsmsSlice from '../slices/dmsmsSlice';
import dashboardSlice from '../slices/dashboardSlice';

const store = configureStore({
  reducer: {
    suppliers: suppliersSlice,
    auth: authSlice,
    discovery: discoverySlice,
    hits: hitsSlice,
    riskCases: riskCasesSlice,
    permission: permissionsSlice,
    keywordModel: keywordModelSlice,
    siteAdmin: siteAdminSlice,
    theme: themeSlice,
    dmsms: dmsmsSlice,
    dashboard: dashboardSlice,
  },
});

export default store;
