import {
  KeywordModelRiskCaseDto,
  RiskCaseLogEntryDto,
  RiskCaseMeasureLevelDto,
  SubscriberDto,
  SupplierRiskCaseDto,
  SupplierWithNoDnBDataDto,
  UserDto,
} from './types';

export interface RiskCaseLogEntryWatchedPartDto {
  id: number;
  watchedPartId: number;
  riskCaseLogEntryId: number;
}

export interface RiskCaseWithSupplierDataDto {
  id: number;
  riskCaseName: string;
  measures: RiskCaseMeasureLevelDto[];
  suppliers: SupplierRiskCaseWithThemeDataDto[];
}

export interface DnBAssessmentChartDto {
  suppliersInDnB: SupplierWithDnBAssessmentDataDto[];
  suppliersNotInDnB: SupplierWithNoDnBDataDto[];
}

// #region Nsn Query

export interface NsnQueryDto {
  nsn: string | null;
  part: string | null;
  cage: string | null;
  doSosOnly: boolean;
  doAmscgOnly: boolean;
  doFullContent: boolean;
  keywordSearch: string | null;
  rowsPerPage: number | null;
}

export const BatchQueryLookupMethod = {
  Cage_PartNumber: { name: 'Cage & Part Number', value: 0 },
  Niin_Cage: { name: 'NIIN & Cage', value: 1 },
  Niin_PartNumber: { name: 'NIIN & Part Number', value: 2 },
  Niin: { name: 'NIIN (only)', value: 3 },
  Niin_Cage_PartNumber: { name: 'NIIN, Cage, & Part Number', value: 4 },
  PartNumber: { name: 'Part Number (only)', value: 5 },
  Cage: { name: 'Cage (only)', value: 6 },
  DibbsFscHotOpportunities: {
    name: 'DIBBS by FSC Hot Opportunities',
    value: 7,
  },
  DibbsByNsn: { name: 'DIBBS by FSC', value: 8 },
};
// #endregion

export interface UsersAdminDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
  userName: string;
  isActive: boolean;
  dateCreated: string | null;
  dateLastActivity: string | null;
  dateLastUpdated: string | null;
  dateDeleted: string | null;
  createdById: number | null;
  updatedById: number | null;
  deletedById: number | null;
  isSuperAdmin: boolean;
  isDeactivatedInActiveDirectory: boolean;
  roleIds: number[] | null;
}

export interface AppDto {
  id: number;
  appName: string;
  dateCreated: Date;
  dateArchived: Date | null;
  dateLastUpdated: Date | null;
  dateDeleted: Date | null;
  createdById: number;
  updatedById: number;
  deletedById: number;
  enabled?: boolean;
  role?: string;
  isDisabled?: boolean;
}

export interface RoleDto {
  id: number;
  roleName: string;
  isActive: boolean;
  appId: number;
  dateCreated: Date | null;
  dateArchived: Date | null;
  dateLastUpdated: Date | null;
  dateDeleted: Date | null;
}

// TODO: Added manually, add support for inheritance classes
export interface RiskCaseResponseDto {
  id: number;
  riskCaseName: string;
  description: string | null;
  ownerId: number;
  subscribers: SubscriberDto[];
  createdById: number;
  measures: RiskCaseMeasureLevelDto[];
  suppliers: SupplierRiskCaseDto[];
  logEntries: RiskCaseLogEntryDto[];
  keywordModels: KeywordModelRiskCaseDto[];
  dateAdded: Date | null;
  dateUpdated: Date | null;
  dateDeleted: Date | null;
  isActive: boolean;
  isDeleted: boolean;
  owner: UserDto;
  riskCaseSuppliers: string;
  probability: string;
  magnitude: string;
}

export interface SupplierRiskCaseWithThemeDataDto extends SupplierRiskCaseDto {
  hex: string | null;
}

export interface SupplierWithDnBAssessmentDataDto extends SupplierWithNoDnBDataDto {
  dnBAssessmentId: number;
  viabilityClassScore: number;
  failureClassScore: number;
  failureScoreNationalPercentile: number;
}

// App Resource Names (syn with Altruity.Common.Utilities.GlobalConstants)
export const AppNames = {
  SCRM: 'SCRM',
  DMSMS: 'DMSMS',
  NsnQuery: 'NSN Query',
};

export const AppResourceNames = {
  Suppliers: 'Suppliers',
  KeywordsModels: 'Keywords Models',
  ArticleHits: 'Article Hits',
  RiskCases: 'Risk Cases',
  RiskCaseLog: 'Risk Case Log',
  Dashboard: 'Dashboard',
  Parts: 'Parts',
  Search: 'Search',
  Batch: 'Batch',
};

export const PermissionsType = {
  View: 'View',
  AddEdit: 'AddEdit',
  Archive: 'Archive',
};
