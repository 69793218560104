import { Typography } from '@mui/material';
import RolesTable from '../../components/Site-Admin/Roles/RolesTable';
import React from 'react';
import { useSelector } from 'react-redux';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';
// import "./Roles.scss";

const Roles = () => {
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  if (isSuperAdmin === true) {
    return (
      <div className="site-admin-container">
        <Typography className="title">Roles</Typography>
        <RolesTable />
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default Roles;
