import React, { useEffect, useState } from 'react';
import './index.scss';
import Tabs from 'devextreme-react/tabs';
import DBTab from '../../components/supplier-tabs/d-b';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import SupplierSetupTab from '../../components/supplier-tabs/supplier-setup';
import { useDispatch, useSelector } from 'react-redux';
import SuppliersAPI from '../../api/suppliers/suppliersAPI';
import { addSelectedSupplier, addSuppliers } from '../../slices/suppliersSlice';
import RunModelsTab from '../../components/supplier-tabs/run-models';
import { isNullOrWhitespace } from '../../utils/validation-helpers';
import { AppNames, AppResourceNames, PermissionsType } from '../../api/types/custom-types';
import { doesHavePermission } from '../../utils/permissions-helpers';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

const SUPPLIER_TABS = [
  // { id: 0, text: 'Overview' },
  { id: 1, text: 'D&B' },
  // { id: 2, text: 'Financial' },
  // { id: 3, text: 'Parts-At-Risk' },
  // { id: 4, text: 'Surveys' },
  // { id: 5, text: 'Onboarding' },
  { id: 6, text: 'Supplier Setup' },
  { id: 7, text: 'Run Models' },
];
const NEW_SUPPLIER_TABS = [
  // { id: 0, text: 'Overview', disabled: true },
  { id: 1, text: 'D&B', disabled: true },
  // { id: 2, text: 'Financial', disabled: true },
  // { id: 3, text: 'Parts-At-Risk', disabled: true },
  // { id: 4, text: 'Surveys', disabled: true },
  // { id: 5, text: 'Onboarding', disabled: true },
  { id: 6, text: 'Supplier Setup', disabled: false },
];

const SupplierPage = () => {
  const [tabId, setTabId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cage = queryParams.get('cage');
  const section = queryParams.get('section');

  const dispatch = useDispatch();
  const suppliers = useSelector((state) => state.suppliers.allSuppliers);

  const roles = useSelector((state) => state.auth.roles); // as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state) => state.auth.isSuperAdmin); //as boolean;
  const canViewSupplier = doesHavePermission(
    roles,
    AppNames.SCRM,
    AppResourceNames.Suppliers,
    isSuperAdmin,
    PermissionsType.View
  );
  const canAddEditSupplier = doesHavePermission(
    roles,
    AppNames.SCRM,
    AppResourceNames.Suppliers,
    isSuperAdmin,
    PermissionsType.AddEdit
  );

  /**
   * If a cage is supplied in the query parameters, get supplier from the API and redirect.
   */
  useEffect(() => {
    async function redirectIfCage() {
      if (!isNullOrWhitespace(cage)) {
        try {
          const supplier = await SuppliersAPI.getSupplierByCage(cage);
          if (supplier && supplier.id !== null) {
            navigate(`/scrm/suppliers/${supplier.id}`);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }

    redirectIfCage();
  }, [cage]);

  useEffect(() => {
    if (!isNullOrWhitespace(section)) {
      setTabId(1);
    }
  }, [section]);

  const fetchSuppliers = async () => {
    setLoading(true);

    if (suppliers.length === 0) {
      try {
        const data = await SuppliersAPI.getAllSuppliers();
        dispatch(addSuppliers({ allSuppliers: data }));
      } catch (error) {
        console.error(error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  useEffect(() => {
    if (!loading) {
      if (id !== 'add-supplier') {
        if (isNullOrWhitespace(section)) {
          setTabId(6);
        }
        const supplier = suppliers.find((supplier) => supplier.id === Number(id));
        if (supplier) {
          dispatch(
            addSelectedSupplier({
              selectedSupplier: {
                id: supplier?.id,
                supplierName: supplier?.supplierName,
              },
            })
          );
        }
      } else {
        setTabId(6);
      }
      setIsReady(true);
    }
  }, [id, loading, suppliers, dispatch]);

  const onTabClick = (e) => {
    setTabId(e.itemData.id);
  };

  const getSupplierName = (id) => {
    if (id === 'add-supplier') {
      return 'New Supplier';
    }

    const supplier = suppliers.find((supplier) => supplier.id === Number(id));
    return supplier?.supplierName;
  };

  const getTabs = () => {
    if (id === 'add-supplier') {
      return NEW_SUPPLIER_TABS;
    }

    const supplier = suppliers.find((supplier) => supplier.id === Number(id));
    if (supplier && !supplier.duns) {
      return SUPPLIER_TABS.map((tab) => (tab.id === 1 ? { ...tab, disabled: true } : tab));
    }
    return SUPPLIER_TABS;
  };

  const displayTab = () => {
    switch (tabId) {
      case 1:
        return <DBTab />;
      case 6:
        return <SupplierSetupTab canAddOrEdit={canAddEditSupplier} />;
      case 7:
        return <RunModelsTab />;
      default:
        break;
    }
  };

  if (canViewSupplier === true) {
    return (
      <div className="supplier-page-container" key={id}>
        {id === '0' ? (
          <h4 className="supplier-title">Supplier Not Found</h4>
        ) : (
          <>
            <h4 className="supplier-title">{getSupplierName(id)}</h4>
            <div className="tabs-demo">
              <div className="widget-container">
                {isReady && (
                  <Tabs
                    id="withText"
                    width={'700px'}
                    selectedIndex={getTabs().findIndex((tab) => tab.id === tabId)}
                    rtlEnabled={false}
                    dataSource={getTabs()}
                    scrollByContent={false}
                    showNavButtons={false}
                    orientation={'horizontal'}
                    stylingMode={'secondary'}
                    iconPosition={'top'}
                    fullWidth={true}
                    onItemClick={onTabClick}
                  />
                )}
              </div>
            </div>
            {displayTab()}
          </>
        )}
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default SupplierPage;
