import { createSlice } from '@reduxjs/toolkit';

const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState: {
    allSuppliers: [],
    selectedSupplier: {
      id: '',
      supplierName: '',
    },
    showOnlyActive: false,
    id: null,
    supplierPreviewId: null,
    supplierPreviewDuns: null,
  },
  reducers: {
    addSuppliers: (state, action) => {
      return { ...state, allSuppliers: [...action.payload.allSuppliers] };
    },
    addSelectedSupplier: (state, action) => {
      return { ...state, selectedSupplier: action.payload.selectedSupplier };
    },
    toggleShowOnlyActiveSwitch: (state) => {
      return { ...state, showOnlyActive: !state.showOnlyActive };
    },
    setId: (state, action) => {
      return { ...state, id: action.payload };
    },
    setSupplierPreviewId: (state, action) => {
      return { ...state, supplierPreviewId: action.payload };
    },
    setSupplierPreviewDuns: (state, action) => {
      return { ...state, supplierPreviewDuns: action.payload };
    },
  },
});

export const {
  addSuppliers,
  addSelectedSupplier,
  toggleShowOnlyActiveSwitch,
  setId,
  setSupplierPreviewId,
  setSupplierPreviewDuns,
} = suppliersSlice.actions;
export default suppliersSlice.reducer;
