import React from 'react';
import { Typography } from '@mui/material';
import './index.scss';
import VendorAutoLoad from '../../components/scrm/admin/vendor-auto-load/VendorAutoLoad';
import { RoleWithRoleResourcesDto } from '../../api/types/types';
import { useSelector } from 'react-redux';
import { AppNames } from '../../api/types/custom-types';
import { isAdminForApp } from '../../utils/permissions-helpers';
import CannotViewPage from '../../components/no-permission-content/CannotViewPage';

const VendorAutoLoadAdmin = () => {
  const siteRoles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canViewPage = isAdminForApp(siteRoles, AppNames.SCRM, isSuperAdmin);

  if (canViewPage === true) {
    return (
      <div className="admin">
        <Typography className="title">Vendor Auto-Load</Typography>
        <VendorAutoLoad />
      </div>
    );
  } else {
    return <CannotViewPage />;
  }
};

export default VendorAutoLoadAdmin;
