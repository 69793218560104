import { RoleWithRoleResourcesDto } from '../api/types/types';
import { PermissionsType } from '../api/types/custom-types';

export function doesHavePermission(
  roles: RoleWithRoleResourcesDto[],
  appName: string,
  appResourceName: string,
  isSuperAdmin: boolean,
  type: string
) {
  // Super Admins can do everything
  if (isSuperAdmin === true) {
    return true;
  }

  var canDo = false;
  for (var i = 0; i < roles.length; i++) {
    const role = roles[i];

    const roleResource = role.roleResources.find(
      (c) => c.appResource.app.appName === appName && c.appResource.resourceName === appResourceName
    );

    if (roleResource) {
      // If Admin they can view
      if (role.isAdmin === true) {
        canDo = true;
      } else {
        switch (type) {
          case PermissionsType.View:
            canDo = roleResource.canView;
            break;

          case PermissionsType.AddEdit:
            canDo = roleResource.canAddEdit;
            break;

          case PermissionsType.Archive:
            canDo = roleResource.canArchive;
            break;
        }
      }

      break;
    }
  }

  return canDo;
}

export function isAdminForApp(
  roles: RoleWithRoleResourcesDto[],
  appName: string,
  isSuperAdmin: boolean
) {
  // Super Admins can do everything
  if (isSuperAdmin === true) {
    return true;
  }

  var isAdmin = false;
  for (var i = 0; i < roles.length; i++) {
    const role = roles[i];

    const roleResource = role.roleResources.find((c) => c.appResource.app.appName === appName);

    if (roleResource) {
      // If Admin they can view
      if (role.isAdmin === true) {
        isAdmin = true;
      }

      break;
    }
  }

  return isAdmin;
}

/**
 *
 * @param roles Determines if a user can access a particular app.
 * @param appName
 * @param isSuperAdmin
 * @returns
 */
export function canAccessApp(
  roles: RoleWithRoleResourcesDto[],
  appName: string,
  isSuperAdmin: boolean
) {
  if (isSuperAdmin) {
    return true;
  }

  for (var i = 0; i < roles.length; i++) {
    const role = roles[i];

    const roleResource = role.roleResources.find((c) => c.appResource.app.appName === appName);

    if (roleResource) {
      return true;
    }
  }

  return false;
}
