import React, { useEffect, useState } from 'react';
import BankruptcyComponent from './bankruptcy';
import ClaimsComponent from './claims';
import ExclusionsComponent from './exclusions';
import InsolvencyComponent from './insolvency';
import JudgementsComponent from './judgements';
import LiensComponent from './liens';
import LiquidationsComponent from './liquidations';
import SuitsComponent from './suits';
import { useLocation } from 'react-router';
import './index.scss';
import { useSelector } from 'react-redux';

import OtherItemsComponent from './other-items';
import { LoadIndicator } from 'devextreme-react';
import DnbAPI from '../../../../api/dnb/dnbAPI';
import StatusIndicator from './status';

const Filings = ({ supplierId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [bankruptcyData, setBankruptcyData] = useState(null);
  const [suitsData, setSuitsData] = useState(null);
  const [liensData, setLiensData] = useState(null);
  const [claimsData, setClaimsData] = useState(null);
  const [liquidationsData, setLiquidationsData] = useState(null);
  const [insolvencyData, setInsolvencyData] = useState(null);
  const [judgmentsData, setJudgmentsData] = useState(null);
  const [exclusionsData, setExclusionsData] = useState(null);
  const [otherItemsData, setOtherItemsData] = useState(null);
  const urlSupplierId = useSelector((state) => state.suppliers.id);
  const location = useLocation();
  const id = supplierId || urlSupplierId;

  const fetchSuitsData = () => DnbAPI.getSuitsDataById(id);
  const fetchBankruptcyData = () => DnbAPI.getBankruptcyDataById(id);
  const fetchLiensData = () => DnbAPI.getLiensDataById(id);
  const fetchClaimsData = () => DnbAPI.getClaimsDataById(id);
  const fetchLiquidationsData = () => DnbAPI.getLiquidationsDataById(id);
  const fetchInsolvenciesData = () => DnbAPI.getInsolvenciesDataById(id);
  const fetchJudgmentsData = () => DnbAPI.getJudgmentsDataById(id);
  const fetchExclusionsData = () => DnbAPI.getExclusionsDataById(id);
  const fetchOtherItemsData = () => DnbAPI.getDnbOtherItemsDataById(id);

  const fetchData = async () => {
    setIsLoading(true);
    const results = await Promise.allSettled([
      fetchSuitsData(),
      fetchBankruptcyData(),
      fetchLiensData(),
      fetchClaimsData(),
      fetchLiquidationsData(),
      fetchInsolvenciesData(),
      fetchJudgmentsData(),
      fetchExclusionsData(),
      fetchOtherItemsData(),
    ]);

    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        switch (index) {
          case 0:
            setSuitsData(result.value);
            break;
          case 1:
            setBankruptcyData(result.value);
            break;
          case 2:
            setLiensData(result.value);
            break;
          case 3:
            setClaimsData(result.value);
            break;
          case 4:
            setLiquidationsData(result.value);
            break;
          case 5:
            setInsolvencyData(result.value);
            break;
          case 6:
            setJudgmentsData(result.value);
            break;
          case 7:
            setExclusionsData(result.value);
            break;
          case 8:
            setOtherItemsData(result.value);
            break;
          default:
            break;
        }
      } else {
        console.error(`Error fetching data for index ${index}:`, result.reason);
      }
    });

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sectionId = query.get('section');

    if (sectionId && !isLoading) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location, isLoading]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadIndicator />
        </div>
      ) : (
        <>
          <StatusIndicator
            bankruptcyData={bankruptcyData?.result?.filings}
            claimsData={claimsData?.result?.dnBClaimFilings}
            exclusionsData={exclusionsData?.result?.exclusions}
            insolvencyData={insolvencyData?.result?.dnBInsolvencyFilings}
            judgmentsData={judgmentsData?.result?.dnBJudgmentFilings}
            liensData={liensData?.result?.dnBLienFilings}
            liquidationsData={liquidationsData?.result?.dnBLiquidationFilings}
            suitsData={suitsData?.result?.dnBSuitFilings}
            otherItemsData={otherItemsData?.result}
            showAllData={supplierId === undefined}
            supplierId={supplierId}
          />
          {supplierId === undefined && (
            <>
              <div id="bankruptcy">
                <BankruptcyComponent bankruptcyData={bankruptcyData?.result?.filings} />
              </div>
              <div id="claims">
                <ClaimsComponent claimsData={claimsData?.result?.dnBClaimFilings} />
              </div>
              <div id="exclusions">
                <ExclusionsComponent exclusionsData={exclusionsData?.result?.exclusions} />
              </div>
              <div id="insolvency">
                <InsolvencyComponent
                  insolvencyData={insolvencyData?.result?.dnBInsolvencyFilings}
                />
              </div>
              <div id="judgments">
                <JudgementsComponent judgmentsData={judgmentsData?.result?.dnBJudgmentFilings} />
              </div>
              <div id="liens">
                <LiensComponent liensData={liensData?.result?.dnBLienFilings} />
              </div>
              <div id="liquidation">
                <LiquidationsComponent
                  liquidationsData={liquidationsData?.result?.dnBLiquidationFilings}
                />
              </div>
              <div id="suits">
                <SuitsComponent suitsData={suitsData?.result?.dnBSuitFilings} />
              </div>
              <div id="otherItems">
                <OtherItemsComponent otherItemsData={otherItemsData?.result} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Filings;
