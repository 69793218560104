import React, { useEffect, useState } from 'react';
import { GroupItem, Item, SimpleItem } from 'devextreme-react/cjs/form';
import { Button, Chart, Form, LoadIndicator } from 'devextreme-react';
import { useSelector } from 'react-redux';

import { ArgumentAxis, Label, Legend, Series, Title, ValueAxis } from 'devextreme-react/cjs/chart';
import DnbAPI from '../../../../api/dnb/dnbAPI';

const Financials = () => {
  const [financialData, setFinancialData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const id = useSelector((state) => state.suppliers.id);

  const fetchData = async (refresh = false) => {
    if (!refresh) setIsLoading(true);

    try {
      setIsLoading(true);
      const data = await DnbAPI.getCompanyFinancialsById(id, refresh);
      setFinancialData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    fetchData(true);
  };

  if (error) return <div>No Data</div>;

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadIndicator />
        </div>
      ) : (
        <Form formData={financialData} colCount={4}>
          <GroupItem caption="Latest Fiscal Financials" colSpan={4} colCount={4}>
            <SimpleItem
              dataField="fromDate"
              editorType="dxDateBox"
              label={{ text: 'FROM DATE' }}
              editorOptions={{ readOnly: true }}
            />
            <SimpleItem
              dataField="toDate"
              editorType="dxDateBox"
              label={{ text: 'TO DATE' }}
              editorOptions={{ readOnly: true }}
            />
            <SimpleItem
              dataField="scope"
              label={{ text: 'SCOPE' }}
              editorOptions={{ readOnly: true }}
            />
            <GroupItem colCount={10} cssClass="custom-item">
              <SimpleItem
                dataField="dateUpdated"
                editorType="dxDateBox"
                label={{ text: 'LAST UPDATED' }}
                colSpan={8}
                editorOptions={{ readOnly: true }}
              />
              <Item colSpan={2}>
                <Button
                  icon="refresh"
                  type="normal"
                  onClick={handleRefresh}
                  hint="Refresh"
                  style={{ marginTop: '32px', marginLeft: '10px' }}
                />
              </Item>
            </GroupItem>
            <SimpleItem
              dataField="accountantOpinion"
              label={{ text: "ACCOUNTANT'S OPINION" }}
              editorType="dxTextArea"
              colSpan={2}
              editorOptions={{ readOnly: true }}
            />
            <SimpleItem
              dataField="dnBComment"
              label={{ text: 'D&B COMMENTS' }}
              editorType="dxTextArea"
              colSpan={2}
              editorOptions={{ readOnly: true }}
            />
            <SimpleItem
              dataField="totalCurrentAssets"
              label={{ text: 'TOTAL CURRENT ASSETS' }}
              editorOptions={{ readOnly: true, format: 'currency' }}
            />
            <SimpleItem
              dataField="totalCurrentLiabilities"
              label={{ text: 'TOTAL CURRENT LIABILITIES' }}
              editorOptions={{ readOnly: true, format: 'currency' }}
            />
            <SimpleItem
              dataField="workingCapital"
              label={{ text: 'WORKING CAPITAL' }}
              editorOptions={{ readOnly: true, format: 'currency' }}
            />
            <SimpleItem
              dataField="tangibleNetWorth"
              label={{ text: 'TANGIBLE NET WORTH' }}
              editorOptions={{ readOnly: true, format: 'currency' }}
            />
            <SimpleItem
              dataField="currentRatio"
              label={{ text: 'CURRENT RATIO' }}
              editorOptions={{ readOnly: true }}
            />
            <SimpleItem
              dataField="quickRatio"
              label={{ text: 'QUICK RATIO' }}
              editorOptions={{ readOnly: true }}
            />
            <SimpleItem
              dataField="currentLiabilitiesOverNetWorth"
              label={{ text: 'CURRENT LIAB. OVER NET WORTH' }}
              editorOptions={{ readOnly: true }}
            />
            <SimpleItem
              dataField="totalLiabilitiesOverNetWorth"
              label={{ text: 'TOTAL LIAB. OVER NET WORTH' }}
              editorOptions={{ readOnly: true }}
            />
          </GroupItem>
          <GroupItem caption="Historic Trends" colSpan={4}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ width: '50%', padding: '24px' }}>
                <Chart dataSource={financialData?.dnBHistoricFinancials}>
                  <ArgumentAxis argumentType="datetime" label={{ format: 'shortDate' }} />
                  <ValueAxis name="assets">
                    <Title text="in millions" />
                  </ValueAxis>
                  <Series
                    valueField="totalCurrentAssets"
                    argumentField="toDate"
                    name="Current Assets"
                    type="line"
                    color="#1f77b4"
                  />
                  <Series
                    valueField="totalCurrentLiabilities"
                    argumentField="toDate"
                    name="Current Liabilities"
                    type="line"
                    color="#ff7f0e"
                  />
                  <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                </Chart>
              </div>
              <div style={{ width: '50%', padding: '24px' }}>
                <Chart dataSource={financialData?.dnBHistoricFinancials}>
                  <ArgumentAxis argumentType="datetime" label={{ format: 'shortDate' }} />
                  <ValueAxis name="capital">
                    <Title text="in millions" />
                  </ValueAxis>
                  <Series
                    valueField="workingCapital"
                    argumentField="toDate"
                    name="Working Capital"
                    type="line"
                    color="#1f77b4"
                  />
                  <Series
                    valueField="tangibleNetWorth"
                    argumentField="toDate"
                    name="Tangible Net Worth"
                    type="line"
                    color="#ff7f0e"
                  />
                  <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                </Chart>
              </div>
              <div style={{ width: '50%', padding: '24px' }}>
                <Chart dataSource={financialData?.dnBHistoricFinancials}>
                  <ArgumentAxis argumentType="datetime" label={{ format: 'shortDate' }} />
                  <ValueAxis name="ratios">
                    <Title text="Current & Quick Ratios" />
                  </ValueAxis>
                  <Series
                    valueField="currentRatio"
                    argumentField="toDate"
                    name="Current Ratio"
                    type="line"
                    color="#1f77b4"
                  >
                    <Label visible={true} customizeText={(point) => point.valueText} />
                  </Series>
                  <Series
                    valueField="quickRatio"
                    argumentField="toDate"
                    name="Quick Ratio"
                    type="line"
                    color="#ff7f0e"
                  >
                    <Label visible={true} customizeText={(point) => point.valueText} />
                  </Series>
                  <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                </Chart>
              </div>
              <div style={{ width: '50%', padding: '24px' }}>
                <Chart dataSource={financialData?.dnBHistoricFinancials}>
                  <ArgumentAxis argumentType="datetime" label={{ format: 'shortDate' }} />
                  <ValueAxis name="liabilities">
                    <Title text="Liabilities vs Net Worth" />
                  </ValueAxis>
                  <Series
                    valueField="currentLiabilitiesOverNetWorth"
                    argumentField="toDate"
                    name="Curr. Liab. Over Net Worth"
                    type="line"
                    color="#1f77b4"
                  >
                    <Label visible={true} customizeText={(point) => point.valueText} />
                  </Series>
                  <Series
                    valueField="totalLiabilitiesOverNetWorth"
                    argumentField="toDate"
                    name="Total Liab. Over Net Worth"
                    type="line"
                    color="#ff7f0e"
                  >
                    <Label visible={true} customizeText={(point) => point.valueText} />
                  </Series>
                  <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                </Chart>
              </div>
            </div>
          </GroupItem>
        </Form>
      )}
    </>
  );
};

export default Financials;
