// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';
import { ResponseDto } from '../types/types';
const { JOB_SERVICE_URL } = config;

const NsnBatch = {
  /**
   * Deletes a part forecast
   * @param part
   */
  deleteNsnBatch: async function (nsnBatchId: number): Promise<ResponseDto<any>> {
    var url = `${JOB_SERVICE_URL}/NsnBatch/${nsnBatchId}`;
    const { data } = await httpClient.delete(url);
    return data as ResponseDto<any>;
  },
};

export default NsnBatch;
