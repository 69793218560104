import React, { useEffect, useState } from 'react';
import { Form, Item, GroupItem, SimpleItem } from 'devextreme-react/form';
import { Chart, Series, ArgumentAxis, ValueAxis, Legend, Title } from 'devextreme-react/chart';
import { Button, DataGrid, Tooltip } from 'devextreme-react';
import { Column } from 'devextreme-react/cjs/data-grid';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useSelector } from 'react-redux';

import { formatDate } from '../../../../utils/format-helpers';
import DnbAPI from '../../../../api/dnb/dnbAPI';

const Assessment = () => {
  const [riskAssessment, setRiskAssessment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const id = useSelector((state) => state.suppliers.id);

  const fetchData = async (refresh = false) => {
    if (!refresh) setIsLoading(true);

    try {
      const data = await DnbAPI.getAssessmentDataById(id, refresh);
      setRiskAssessment(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    fetchData(true);
  };

  if (error) return <div>No Data</div>;

  const cellRender = (data) => {
    let color = '';
    if (data.column.dataField === 'failureScoreNationalPercentile') {
      color = '#1f77b4';
    } else if (data.column.dataField === 'failureScoreRawScore') {
      color = '#ff7f0e';
    }
    return <div style={{ color }}>{data.text}</div>;
  };

  const dateCellRender = (data) => {
    return <div>{formatDate(data.value)}</div>;
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadIndicator />
        </div>
      ) : (
        <>
          <Form formData={riskAssessment} colCount={4}>
            <GroupItem caption="Overall D&B Risk Assessment" colSpan={4} colCount={6}>
              <Item
                dataField="overallDescription"
                label={{ text: 'OVERALL DESCRIPTION' }}
                colSpan={4}
                editorOptions={{ readOnly: true }}
              />
              <GroupItem colSpan={2} colCount={10} cssClass="custom-item">
                <SimpleItem
                  dataField="dateUpdated"
                  editorType="dxDateBox"
                  label={{ text: 'LAST UPDATED' }}
                  colSpan={8}
                  editorOptions={{ readOnly: true }}
                />
                <Item colSpan={2}>
                  <Button
                    icon="refresh"
                    type="normal"
                    onClick={handleRefresh}
                    hint="Refresh"
                    style={{ marginTop: '32px', marginLeft: '10px' }}
                  />
                </Item>
              </GroupItem>
              <Item
                dataField="overallCommentary"
                editorType="dxTextArea"
                label={{ text: 'OVERALL COMMENTARY' }}
                colSpan={6}
                editorOptions={{ readOnly: true }}
              />
            </GroupItem>

            <GroupItem caption="Viability Rating" colSpan={4} colCount={6}>
              <SimpleItem
                dataField="viabilityRiskLevel"
                label={{ text: 'RISK LEVEL' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="viabilityBadRate"
                label={{ text: 'BAD RATE' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="viabilityClassScore"
                label={{ text: 'CLASS SCORE' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="viabilityClassScoreIncidencePercentage"
                label={{ text: 'INCIDENT %' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="viabilityOverallBadRate"
                label={{ text: 'OVERALL BAD RATE' }}
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="viabilityRatingDate"
                label={{ text: 'RATING DATE' }}
                editorType="dxDateBox"
                colSpan={1}
                editorOptions={{ readOnly: true }}
              />
            </GroupItem>

            <GroupItem caption="Failure Score" colSpan={4} colCount={10}>
              <SimpleItem
                dataField="failureScoreClassScore"
                label={{ text: 'CLASS SCORE' }}
                colSpan={8}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="failureScoreRawScore"
                label={{ text: 'RAW SCORE' }}
                colSpan={2}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField="failureScoreModel"
                label={{ text: 'SCORING MODEL' }}
                colSpan={2}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem colSpan={2} />
              <SimpleItem
                dataField="failureScoreDate"
                label={{ text: 'SCORE DATE' }}
                editorType="dxDateBox"
                colSpan={2}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem colSpan={2} />
              <SimpleItem
                dataField="failureScoreNationalPercentile"
                label={{ text: 'NATIONAL PERCENTILE' }}
                colSpan={2}
                editorOptions={{ readOnly: true }}
              />
            </GroupItem>

            <Item
              dataField="failureScoreCommentary"
              label={{ text: 'FAILURE SCORE COMMENTARY' }}
              editorType="dxTextArea"
              colSpan={4}
              editorOptions={{ readOnly: true }}
            />
          </Form>
          <div style={{ padding: '48px' }}>
            <Chart dataSource={riskAssessment?.dnBHistoricFailureScores}>
              <ArgumentAxis
                valueMarginsEnabled={false}
                argumentType="datetime"
                label={{ format: 'shortDate' }}
              />
              <ValueAxis name="percentile" position="left">
                <Title text="National Percentile" />
              </ValueAxis>
              <ValueAxis name="score" position="right">
                <Title text="Raw Score" />
              </ValueAxis>
              <Series
                valueField="failureScoreNationalPercentile"
                argumentField="failureScoreDate"
                axis="percentile"
                name="National Percentile"
                type="line"
                color="#1f77b4"
              />
              <Series
                valueField="failureScoreRawScore"
                argumentField="failureScoreDate"
                axis="score"
                name="Raw Score"
                type="line"
                color="#ff7f0e"
              />
              <Legend verticalAlignment="bottom" horizontalAlignment="center" />
              <Tooltip enabled={true} />
            </Chart>
          </div>

          <div style={{ padding: '0px 48px 48px 48px' }}>
            <DataGrid
              dataSource={riskAssessment?.dnBHistoricFailureScores}
              showBorders={true}
              paging={{ enabled: true, pageSize: 10 }}
            >
              <Column dataField="failureScoreDate" caption="Date" cellRender={dateCellRender} />
              <Column
                dataField="failureScoreRawScore"
                caption="Raw Score"
                cellRender={cellRender}
              />
              <Column
                dataField="failureScoreNationalPercentile"
                caption="National Percentile"
                cellRender={cellRender}
              />
            </DataGrid>
          </div>
        </>
      )}
    </div>
  );
};

export default Assessment;
