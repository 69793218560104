// @ts-ignore
import SuppliersAPI from '../../../api/suppliers/suppliersAPI';
// @ts-ignore
import DnbAPI from '../../../api/dnb/dnbAPI';

import React, { useEffect, useState } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridRowParams,
  GridRowModel,
  GridRowModesModel,
  GridRowModes,
  GridRowId,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  SnackbarCloseReason,
  Checkbox,
  useTheme,
  MenuItem,
  Select,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import {
  KeywordModelDto,
  RelationTypeDto,
  RiskCaseViewModelDto,
  RoleWithRoleResourcesDto,
  SuppliersDatagridDto,
  SupplierWeightViewModelDto,
} from '../../../api/types/types';
import { SnackbarProps } from '../../../types/Snackbar';
import KeywordModelAPI from '../../../api/keywordModel/keywordModelApi';
import { useNavigate } from 'react-router';
import PopupRiskCases from '../../../components/risk-discovery/popup-risk-cases/PopupRiskCases';
import { getScoreColorBasedOnPrimaryHex } from '../dashboard/utils/colorScale';
import SupplierDetailPanel from './SupplierDetailPanel';
import GridBadge from './GridBadge';
import { doesHavePermission } from '../../../utils/permissions-helpers';
import { AppNames, AppResourceNames, PermissionsType } from '../../../api/types/custom-types';
import { useSelector } from 'react-redux';

const formatDate = (dateString: string | null) =>
  dateString ? new Date(dateString).toLocaleDateString('en-US') : '';

const CustomToolbar: React.FC<{
  selectedSuppliers: SuppliersDatagridDto[];
  archiveSuppliers: () => void;
  handleOpenKwmDialog: () => void;
}> = ({ selectedSuppliers, archiveSuppliers, handleOpenKwmDialog }) => {
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarProps>();
  const roles = useSelector((state: any) => state.auth.roles) as RoleWithRoleResourcesDto[];
  const isSuperAdmin = useSelector((state: any) => state.auth.isSuperAdmin) as boolean;
  const canArch = doesHavePermission(
    roles,
    AppNames.SCRM,
    AppResourceNames.Suppliers,
    isSuperAdmin,
    PermissionsType.Archive
  );

  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    setOpen(false);
    try {
      await Promise.all(
        selectedSuppliers.map((supplier) => SuppliersAPI.runPerigonFlowBySupplier(supplier.id))
      );

      setSnackbar({
        open: true,
        severity: 'success',
        message:
          'The models are now running in the background. Email notifications will be sent upon completion.',
      });
    } catch (error: any) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.response?.data?.message || 'An error occurred',
      });
    }
  };

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const refreshDnBData = async () => {
    try {
      const data = await Promise.all(
        selectedSuppliers
          .filter((supplier) => supplier.duns && supplier.duns.length > 0)
          .map((supplier) => DnbAPI.refreshCompanyInformationByDuns(supplier.duns))
      );

      setSnackbar({
        open: true,
        severity: 'success',
        message: 'D&B data has been successfully refreshed.',
      });
    } catch (error: any) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.response?.data?.message || 'An error occurred',
      });
    }
  };

  return (
    <>
      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={snackbar?.severity === 'error' ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to run all models for the selected Suppliers?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={handleConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Button color="primary" onClick={handleOpenKwmDialog} disabled={!selectedSuppliers.length}>
          Run Models
        </Button>
        <Button color="primary" onClick={refreshDnBData} disabled={!selectedSuppliers.length}>
          Refresh D&B Data
        </Button>

        {canArch === true ? (
          <Button onClick={archiveSuppliers} disabled={!selectedSuppliers.length}>
            Archive
          </Button>
        ) : null}
      </GridToolbarContainer>
    </>
  );
};

const SuppliersDatagrid: React.FC = () => {
  const [suppliers, setSuppliers] = useState<SuppliersDatagridDto[]>([]);
  const [weights, setWeights] = useState<SupplierWeightViewModelDto[]>([]);
  const [supplierRelationTypes, setSupplierRelationTypes] = useState<RelationTypeDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingKWMs, setLoadingKWMs] = useState<boolean>(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState<SuppliersDatagridDto[]>([]);
  const [openKwmDialog, setOpenKwmDialog] = useState(false);
  const [selectedKwms, setSelectedKwms] = useState<number[]>([]);
  const [availableKwms, setAvailableKwms] = useState<KeywordModelDto[]>([]);
  const [snackbar, setSnackbar] = useState<SnackbarProps>();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [popupRiskCasesVisible, setPopupRiskCasesVisible] = useState<boolean>(false);
  const [popupRiskCases, setPopupRiskCases] = useState<RiskCaseViewModelDto[]>([]);
  const navigate = useNavigate();
  const theme = useTheme();

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() => navigate(`/scrm/suppliers/${params.row.id}`)}
          sx={{
            textTransform: 'none',
            padding: 0,
            minWidth: 0,
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      width: 150,
      valueFormatter: (dateAdded: string) => formatDate(dateAdded),
    },
    {
      field: 'riskCases',
      headerName: 'Risk Cases',
      width: 100,
      type: 'number',
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() => setPopupRiskCases(params.value)}
          sx={{
            textTransform: 'none',
            padding: 0,
            minWidth: 0,
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          {params.value.length}
        </Button>
      ),
    },
    {
      field: 'healthScore',
      headerName: 'Health Score',
      width: 150,
      type: 'number',
      renderCell: (params) => (
        <GridBadge
          text={`${params.value}%`}
          color={getScoreColorBasedOnPrimaryHex(params.value, theme.palette.primary.main)}
        />
      ),
    },
    {
      field: 'relationTypeId',
      headerName: 'Relationship',
      width: 150,
      editable: true,
      type: 'singleSelect',
      valueOptions: supplierRelationTypes.map((relation) => ({
        value: relation.id,
        label: relation.description,
      })),
      valueFormatter: (relationTypeId: number) =>
        supplierRelationTypes.find((srt) => srt.id === relationTypeId)?.description,
    },
    {
      field: 'currentHitCount',
      headerName: 'Current Hits',
      width: 100,
      type: 'number',
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() => navigate(`/scrm/discovery/supplier/${params.row.id}`)}
          sx={{
            textTransform: 'none',
            padding: 0,
            minWidth: 0,
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: 'weightId',
      headerName: 'Weight',
      width: 170,
      editable: true,
      type: 'singleSelect',
      valueOptions: weights.map((weight) => ({
        value: weight.id,
        label: weight.label,
        color: weight.themeColor?.hex || theme.palette.primary.main,
      })),
      renderCell: (params) => {
        const weight = weights.find((w) => w.id === params.value);

        return (
          <GridBadge
            text={`${weight?.weight} - ${weight?.label}`}
            color={weight?.themeColor?.hex ?? theme.palette.primary.main}
          />
        );
      },
      renderEditCell: (params) => {
        const handleChange = (event: SelectChangeEvent) => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: event.target.value,
          });
        };

        return (
          <Box sx={{ width: '100%' }}>
            <Select
              value={params.value || ''}
              onChange={handleChange}
              fullWidth
              displayEmpty
              sx={{ height: '100%' }}
              renderValue={(selected) => {
                const selectedWeight = weights.find((w) => w.id === Number(selected));
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor:
                        selectedWeight?.themeColor?.hex || theme.palette.primary.main,
                      color: 'white',
                      padding: '5px',
                      borderRadius: '5px',
                    }}
                  >
                    {selectedWeight?.label || 'Select Weight'}
                  </Box>
                );
              }}
            >
              {weights.map((weight) => (
                <MenuItem
                  key={weight.id}
                  value={weight.id}
                  sx={{
                    backgroundColor: weight.themeColor?.hex || theme.palette.primary.main,
                    color: 'white',
                    margin: 0,
                    height: '100%',
                    padding: '5px',
                    '&.Mui-selected': {
                      backgroundColor: weight.themeColor?.hex || theme.palette.primary.main,
                      color: 'white',
                    },
                    '&:hover': {
                      backgroundColor: weight.themeColor?.hex || theme.palette.primary.main,
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: weight.themeColor?.hex || theme.palette.primary.main,
                    },
                  }}
                >
                  {weight.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        );
      },
    },
    {
      field: 'cageCode',
      headerName: 'Cage',
      width: 150,
      editable: true,
    },
    { field: 'duns', headerName: 'Duns', editable: true, width: 150 },
    { field: 'samStatus', headerName: 'Sam Status', width: 150 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Button color="primary" onClick={handleSaveClick(id)}>
              Save
            </Button>,
            <Button color="primary" onClick={handleCancelClick(id)}>
              Cancel
            </Button>,
          ];
        }

        return [
          <Button color="primary" onClick={handleEditClick(id)}>
            Edit
          </Button>,
        ];
      },
    },
  ];

  const fetchData = async () => {
    setLoading(true);

    try {
      const kwmPromise = KeywordModelAPI.getAllKeywordModel();
      const suppliersPromise = SuppliersAPI.getSuppliersDatagridInfo();
      const weightsPromise =
        weights.length === 0 ? SuppliersAPI.getAllSupplierWeights() : Promise.resolve(weights);
      const supplierRelationTypesPromise =
        supplierRelationTypes.length === 0
          ? SuppliersAPI.getAllSupplierRelations()
          : Promise.resolve(supplierRelationTypes);

      const [suppliersResponse, weightsResponse, supplierRelationTypesResponse, kwmResponse] =
        await Promise.all([
          suppliersPromise,
          weightsPromise,
          supplierRelationTypesPromise,
          kwmPromise,
        ]);

      setSuppliers(suppliersResponse);
      setAvailableKwms(kwmResponse);

      if (weights.length === 0) setWeights(weightsResponse);
      if (supplierRelationTypes.length === 0)
        setSupplierRelationTypes(supplierRelationTypesResponse);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };

    setLoading(true);
    await SuppliersAPI.updateSupplierDatagridInfo(updatedRow);
    fetchData();

    return updatedRow;
  };

  const archiveSuppliers = async () => {
    try {
      setLoading(true);

      await Promise.all(
        selectedSuppliers.map((supplier) =>
          SuppliersAPI.updateSupplierDatagridInfo({ ...supplier, isActive: false })
        )
      );

      fetchData();
    } catch (error) {}
  };

  const renderDetailPanel = (params: GridRowParams) => {
    const riskCases =
      params.row.riskCases?.filter(
        (riskCase: RiskCaseViewModelDto) => riskCase.generalInformation.isActive
      ) || [];

    return (
      <SupplierDetailPanel
        riskCases={riskCases}
        supplierName={params.row.name}
        supplierId={params.row.id}
        duns={params.row.duns}
      />
    );
  };

  const handleOpenKwmDialog = async () => {
    setSelectedKwms([]);
    setOpenKwmDialog(true);
  };

  const runPerigonFlowForKeywordModels = async () => {
    if (!selectedKwms.length) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Please select at least one KWM for each row.',
      });
      return;
    }
    try {
      setLoadingKWMs(true);
      await SuppliersAPI.runPerigonFlowBySupplierAndKeywordModel(
        selectedSuppliers.map((ss) => ss.id),
        selectedKwms
      );
      setSnackbar({
        open: true,
        severity: 'success',
        message: 'Selected models are running.',
      });
    } catch (error: any) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.response?.data?.message || 'An error occurred.',
      });
    } finally {
      setOpenKwmDialog(false);
      setLoadingKWMs(false);
    }
  };

  const handleRiskCasesClose = () => {
    setPopupRiskCases([]);
  };

  useEffect(() => {
    setPopupRiskCasesVisible(popupRiskCases.length ? true : false);
  }, [popupRiskCases]);

  return (
    <>
      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={snackbar?.severity === 'error' ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <Dialog open={openKwmDialog} onClose={() => setOpenKwmDialog(false)}>
        <DialogContent>
          {loadingKWMs ? (
            <Box textAlign={'center'} alignContent={'center'} width={'200px'} height={'200px'}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <DialogContentText>Select KWMs to run models:</DialogContentText>
              <Box sx={{ mt: 2 }}>
                {availableKwms.map((kwm) => (
                  <Box key={kwm.id} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Checkbox
                      sx={{ padding: 0, paddingRight: '5px' }}
                      checked={selectedKwms.includes(kwm.id)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSelectedKwms((prev) =>
                          isChecked ? [...prev, kwm.id] : prev.filter((id) => id !== kwm.id)
                        );
                      }}
                    />
                    <Typography sx={{ flex: 1 }}>{kwm.modelName}</Typography>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={() => setOpenKwmDialog(false)}>Cancel</Button>
          <Button onClick={runPerigonFlowForKeywordModels}>Proceed</Button>
        </DialogActions>
      </Dialog>

      <div style={{ height: 800, width: '100%' }}>
        <DataGridPro
          rows={suppliers}
          columns={columns}
          editMode="row"
          onRowSelectionModelChange={(newSelection) => {
            setSelectedSuppliers(
              suppliers.filter((supplier) => newSelection.includes(supplier.id))
            );
          }}
          slots={{
            toolbar: () => (
              <CustomToolbar
                selectedSuppliers={selectedSuppliers}
                archiveSuppliers={archiveSuppliers}
                handleOpenKwmDialog={handleOpenKwmDialog}
              />
            ),
            detailPanelExpandIcon: ChevronRight,
            detailPanelCollapseIcon: ExpandMore,
          }}
          checkboxSelection
          getDetailPanelContent={renderDetailPanel}
          loading={loading}
          processRowUpdate={processRowUpdate}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-detailPanelToggleCell': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            '& .MuiDataGrid-cell': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            },
          }}
        />
      </div>

      <PopupRiskCases
        popupRiskCasesVisible={popupRiskCasesVisible}
        handleRiskCasesClose={handleRiskCasesClose}
        riskCases={popupRiskCases}
      />
    </>
  );
};

export default SuppliersDatagrid;
