import Help from './help';
import Search from './search';
import Batch from './batch';
import Verify from './verify';
import SortCriteria from './sortCriteria';

const NsnApi = {
  ...Search,
  ...Help,
  ...Batch,
  ...Verify,
  ...SortCriteria,
};

export default NsnApi;
